
import { Row,  notification } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import {API_BASE_URL, TIME_DURATION} from "../../../../../constants";
import ModalLP from "./ModalLP";
import AddIcon from "@mui/icons-material/Add";
import {Button} from "@mui/material";


const FormLp = ({ dvId, dataAdd, setReload }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleFinish = (status, values) => {
        setIsModalOpen(status);
        setIsModalOpen(false);
        let data = {
            ...values,
            id: 0,
            nccThongTinChungId: dvId,
            kichThuocPhong: values.kichThuocPhong !== undefined ? values.kichThuocPhong : "",
            foc: values.foc !== undefined ? values.foc : "",
            poc: values.foc !== undefined ? values.poc : "",
            ghiChu: values.ghiChu !== undefined ? values.ghiChu : "",
        };
        console.log("data=====", data);
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/loaicabin/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response=====", response);
                if (response.status === 200 && response.data.code === "00") {
                    setReload(true);
                    let data = response.data.data;
                    let dataLst = [];
                    let dataLstSearch = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({});
                            dataLstSearch.push({});
                        }
                        dataAdd(dataLst, dataLstSearch);
                    }
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description:  response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });
    };

    return (
        <Row>
            {contextHolder}
            {/*<Col*/}
            {/*    xs={10} sm={10} md={10} lg={10} xl={8}*/}
            {/*></Col>*/}
            {/*<Col*/}
            {/*    xs={10} sm={10} md={10} lg={10} xl={8}*/}
            {/*></Col>*/}
            {/*<Col*/}
            {/*    xs={10} sm={10} md={10} lg={10} xl={8}*/}
            {/*>*/}
            {/*    <Space size="large">*/}
            {/*        /!*<Button onClick={showModal} icon={<PlusOutlined />} type="primary">*!/*/}
            {/*        /!*    {t("add")}*!/*/}
            {/*        /!*</Button>*!/*/}
            <Button sx={{marginTop:"5px"}} variant="outlined" onClick={showModal} startIcon={<AddIcon />} color="warning">
                {t("add")}
            </Button>
            <ModalLP isOpen={isModalOpen} handleCancel={handleCancel} handleFinish={handleFinish}/>
            {/*</Space>*/}
            {/*</Col>*/}
        </Row>
    );
};
export default FormLp;
