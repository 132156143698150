import { CloseCircleFilled } from "@ant-design/icons";
import BorderColorOutlined from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import { Checkbox, FormControl, FormControlLabel, Link, MenuItem, TextField, Box } from "@mui/material";
import Buttons from "@mui/material/Button";
import { Col, Divider, Modal, Row, Space, notification, Table, Tooltip } from "antd";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import khachSanService from "../../../../api/bao_gia/sale_rate/khach_san/khachSanService";
import dm_gia_ap_dung from "../../../../api/category/dm_gia_ap_dung";
import ModalThongTinNccKhachSan from "./modalNcc/ModalThongTinNccKhachSan";
import { API_BASE_URL, TIME_DURATION, maPhongDon, maPhuPhi } from "../../../../constants";
import authHeader from "../../../../api/auth_header";
import axios from "axios";
import "./ModalCapNhat.css";

const ModalCapNhatKS = ({
    isOpen,
    onReset,
    handleCancel,
    dataNhomNgLon,
    dataNhomTreEm,
    baoGiaHanhTrinhId,
    baoGiaTongQuanId,
    bgChietTinhSaleId,
    dmDichVuId,
    bgMapCTSaleHtId,
    soLuongTreEmBG,
    hanhDong,
    soNgay,
    ngay,
    bgHanhTrinhs,
    tuNgay,
    denNgay,
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [dmGia, setDmGia] = useState([]);
    const [isShowNccKS, setIsShowNccKS] = useState(false);
    const { t } = useTranslation();
    const [dmLoaiApDung, setDmLoaiApDung] = useState([]);
    const [dataSource, setDataSource] = useState({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        dieuKienFoc: 0,
        foc: 0,
        ngayApDungs: [],
    });
    const [dataSourceInit, setDataSourceInit] = useState({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        dieuKienFoc: 0,
        foc: 0,
        ngayApDungs: [],
    });
    const [dichVus, setDichVus] = useState([{ id: "NULL" }]);
    const [dichVusInit, setDichVusInit] = useState([{ id: "NULL" }]);
    const [dichVuTreEms, setDichVuTreEms] = useState([]);
    const [dichVuTreEmsInit, setDichVuTreEmsInit] = useState([]);
    const [action, setAction] = useState("");
    const [idxEdit, setIdxEdit] = useState(0);
    const [dmVat, setDmVat] = useState([]);
    const [dmDoTuoi, setDmDoTuoi] = useState([]);
    const [countDichVu, setCountDichVu] = useState(0);
    const [reset, setReset] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [maNcc, setMaNcc] = useState("");
    const [dmKieuPhong, setDmKieuPhong] = useState([]);
    const prevCountDichVu = useRef();
    useEffect(() => {
        getDmGiaApDung();
        getDmLoaiApDung();
        getDmVat();
        getDmDoTuoi();
        getDmKieuPhong();
    }, []);

    useEffect(() => {
        prevCountDichVu.current = countDichVu;
    }, [countDichVu]);

    useEffect(() => {
        if (hanhDong === "EDIT" && isOpen) {
            getKhachSanSale(bgMapCTSaleHtId);
        }
    }, [hanhDong, bgMapCTSaleHtId, isOpen]);

    useEffect(() => {
        if (hanhDong === "ADD") {
            setDichVus([{ id: "NULL" }]);
            setDichVusInit([{ id: "NULL" }]);
            setDataSource({
                tenNcc: "",
                dmGiaApDungId: 2,
                dmVatId: 1,
                dieuKienFoc: 0,
                foc: 0,
                moTa: "",
                ngayApDungs: [],
            });
            setDataSourceInit({
                tenNcc: "",
                dmGiaApDungId: 2,
                dmVatId: 1,
                dieuKienFoc: 0,
                foc: 0,
                moTa: "",
                ngayApDungs: [],
            });
            setDichVuTreEms([]);
            setDichVuTreEmsInit([]);
        }
    }, [isOpen]);

    const chiPhiTreEm = useMemo(() => {
        return dichVuTreEms
            .filter((it) => it.apDung)
            .reduce((sum, item) => {
                if (!item.id.includes("addNew") && !isNaN(item.thanhTien)) sum += parseFloat(item.thanhTien);
                return sum;
            }, 0);
    }, [dichVuTreEms]);

    const chiPhiNguoiLon = useMemo(() => {
        return dichVus.reduce((sum, item) => {
            if (item.id !== "NULL" && !isNaN(item.tong)) sum += parseFloat(item.tong);
            return sum;
        }, 0);
    }, [dichVus]);

    const foc = useMemo(() => {
        let lstdichVu = dichVus.filter((it) => it.id !== "NULL");
        return dataSource.dieuKienFoc
            ? Math.min(...lstdichVu.map((it) => it.giaNet)) *
                  (parseInt(
                      lstdichVu.reduce((sum, item) => sum + parseInt(item.soLuongPhongDoi), 0) / dataSource.dieuKienFoc
                  ) *
                      dataSource.foc)
            : 0;
    }, [dichVus, dataSource.dieuKienFoc, dataSource.foc]);

    const tongChiPhi = dataNhomNgLon.length > 0 ? chiPhiTreEm + chiPhiNguoiLon : chiPhiTreEm + chiPhiNguoiLon - foc;

    let formatter = new Intl.NumberFormat("en-US", {});

    const generateId = () => {
        return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
    };

    const getKhachSanSale = (bgMapCTSaleHtId) => {
        debugger;
        let data_api = {
            bgMapCTSaleHtId: bgMapCTSaleHtId,
        };
        khachSanService
            .getChietTinhSaleKS(data_api)
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let ds = {
                        nccThongTinChungId: data[0].nccThongTinChungId,
                        tenNcc: data[0].tenNhaCungCap,
                        ngayApDungs: data[0].ngayApDungs,
                        dmGiaApDungId: data[0].dmGiaApDungId,
                        dmLoaiApDung: data[0].dmLoaiApDungId,
                        dmTyGiaId: data[0].dmTyGiaId,
                        dieuKienFoc: data[0].dieuKienFoc,
                        foc: data[0].foc,
                        moTa: data[0].moTa,
                        dmVatId: data[0].dmVatId, // đang bị null
                    };
                    let isDisableSLPhongDoi = false;
                    let isDisableSLExtra = false;
                    let isDisableSLPhongDon = false;
                    let lstDichVu = data[0]?.dichVuNguoiLons.map((it, idx) => {
                        if (it.giaDichVuKhachSans[0].soLuong > 0 || it.giaDichVuKhachSans[1].soLuong > 0) {
                            isDisableSLPhongDoi = false;
                            isDisableSLExtra = false;
                            isDisableSLPhongDon = false;
                        } else if (it.giaDichVuKhachSans[2].soLuong > 0) {
                            isDisableSLPhongDoi = true;
                            isDisableSLExtra = true;
                            isDisableSLPhongDon = false;
                        } else {
                            isDisableSLPhongDoi = false;
                            isDisableSLExtra = true;
                            isDisableSLPhongDon = false;
                        }
                        return {
                            id: idx + 1,
                            tranxBgChietTinhKsId: it.tranxBgChietTinhKsId,
                            nccGiaPhongId: it.nccGiaPhongId,
                            nccHangPhongId: it.nccHangPhongId,
                            tranxGiaPhongId: it.tranxGiaPhongId,
                            kieuPhong: it.giaDichVuKhachSans[0].kieuPhong, // kieu phong null
                            maPhong: it.giaDichVuKhachSans[0].maPhong,
                            dmKieuPhongId: it.giaDichVuKhachSans[0].dmKieuPhongId,
                            tenDichVu: it.dichVu,
                            giaNet: it.giaDichVuKhachSans[0].giaDichVu,
                            soLuongPhongDoi: it.giaDichVuKhachSans[0].soLuong,
                            isDisableSLPhongDoi: isDisableSLPhongDoi,
                            giaExtra: it.giaDichVuKhachSans[1].giaDichVu,
                            soLuongExtra: it.giaDichVuKhachSans[1].soLuong,
                            isDisableSLExtra: isDisableSLExtra,
                            giaPhongDon: it.giaDichVuKhachSans[2].giaDichVu,
                            soLuongPhongDon: it.giaDichVuKhachSans[2].soLuong,
                            isDisableSLPhongDon: isDisableSLPhongDon,
                            bgNhomNgLonId: it.bgNhomNguoiLonId, // đang trả ra 0
                            tong:
                                it.giaDichVuKhachSans[0].giaDichVu * it.giaDichVuKhachSans[0].soLuong +
                                it.giaDichVuKhachSans[1].giaDichVu * it.giaDichVuKhachSans[1].soLuong +
                                it.giaDichVuKhachSans[0].giaDichVu * it.giaDichVuKhachSans[2].soLuong,
                        };
                    });
                    lstDichVu.push({ id: "NULL" });

                    let dichVuTEs =
                        data[0]?.dichVuNguoiLons.flatMap((dv) => {
                            let giaDV = dv.giaDichVuKhachSans[0]?.giaDichVu || 0;
                            let numDV = 0;
                            const dichVuTreEms =
                                data[1]?.dichVuTreEms?.filter(
                                    (te) => te.tranxBgChietTinhKsId === dv.tranxBgChietTinhKsId
                                ) || [];
                            let rowSpanDV = dichVuTreEms.reduce((num, item) => (num += item.details.length), 0);
                            let dichVuTEs =
                                dichVuTreEms.flatMap(
                                    (te) =>
                                        te.details.map((dt, idx) => {
                                            giaDV = dt.giaDichVu;
                                            return {
                                                id: generateId(),
                                                tenDichVu: te.tenDichVu,
                                                tenNhomTE: dt.tenNhomTreEm,
                                                giaNet: dt.giaDichVu,
                                                thanhTien: (dt.phanTram / 100) * dt.soLuong * dt.giaDichVu,
                                                bgNhomTreEmId: dt.bkNhomTreEmId,
                                                tranxGiaPhongId: dv.tranxGiaPhongId,
                                                nccHangPhongId: dv.nccHangPhongId,
                                                bgNhomNgLonId: dv.bgNhomNguoiLonId,
                                                rowSpanDV:
                                                    numDV++ === 0
                                                        ? dataNhomTreEm.length === 0
                                                            ? rowSpanDV + 1
                                                            : rowSpanDV
                                                        : 0,
                                                rowSpanTE: idx === 0 ? te.details.length : 0,
                                                ...dt,
                                            };
                                        }) || []
                                ) || [];
                            if (dataNhomTreEm.length === 0) {
                                dichVuTEs.push({
                                    id: "addNew" + generateId(),
                                    tenDichVu: dv.dichVu,
                                    giaNet: giaDV,
                                    nccHangPhongId: dv.nccHangPhongId,
                                    tranxGiaPhongId: dv.tranxGiaPhongId,
                                    rowSpanDV: dichVuTEs.length === 0 ? 1 : 0,
                                });
                            }

                            return dichVuTEs;
                        }) || [];

                    let checkAll = dichVuTEs.filter((te) => !te.id.includes("addNew")).every((dt) => dt.apDung);

                    setCheckAll(checkAll);
                    setCountDichVu(lstDichVu.length);
                    setDichVus(lstDichVu);
                    setDichVusInit(JSON.parse(JSON.stringify(lstDichVu)));
                    setDataSource(ds);
                    setDataSourceInit({ ...ds });
                    setDichVuTreEms(dichVuTEs);
                    setDichVuTreEmsInit(JSON.parse(JSON.stringify(dichVuTEs)));
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmKieuPhong = () => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmKieuPhongNhapLieu",
            headers: authHeader(),
        })
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data?.filter((it) => it.hieu_luc);
                    setDmKieuPhong(data);
                    console.log("@@@ dmKieuPhong: ", data);
                }
            })
            .catch((response) => {
                console.log("Error response getDmKieuPhong:" + response);
            });
    };

    const getDmGiaApDung = () => {
        dm_gia_ap_dung
            .getDmGiaApDung()
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    setDmGia(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmLoaiApDung = () => {
        dm_gia_ap_dung
            .getDmLoaiApDung()
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    setDmLoaiApDung(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmVat = () => {
        dm_gia_ap_dung
            .getDmVat()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmVat(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmDoTuoi = () => {
        dm_gia_ap_dung
            .getDmDoTuoi()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmDoTuoi(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const handleChangeValueDataSoure = (e) => {
        let name = e.target.name;
        if (e.target.value < 0 && (name === "foc" || name === "dieuKienFoc")) dataSource[name] = e.target.value;
        else dataSource[e.target.name] = e.target.value;
        if (name === "dmGiaApDungId") dataSource.ngayApDungs = [];
        setReset(!reset);
    };

    const handleChangeEleDichVu = (id, event) => {
        let index = dichVus.findIndex((item) => item.id === id);
        let name = event.target.name;
        dichVus[index][name] = event.target.value;
        if (event.target.value < 0) dichVus[index][name] = 0;
        dichVus[index].tong =
            dichVus[index].giaNet * dichVus[index].soLuongPhongDoi +
            dichVus[index].giaExtra * dichVus[index].soLuongExtra +
            dichVus[index].giaPhongDon * dichVus[index].soLuongPhongDon;
        if (dichVus[index].soLuongPhongDoi > 0 || dichVus[index].soLuongExtra > 0) {
            dichVus[index].isDisableSLPhongDoi = false;
            dichVus[index].isDisableSLExtra = false;
            dichVus[index].isDisableSLPhongDon = false;
        } else if (dichVus[index].soLuongPhongDon > 0) {
            dichVus[index].isDisableSLPhongDoi = true;
            dichVus[index].isDisableSLExtra = true;
            dichVus[index].isDisableSLPhongDon = false;
        } else {
            dichVus[index].isDisableSLPhongDoi = false;
            dichVus[index].isDisableSLExtra = true;
            dichVus[index].isDisableSLPhongDon = false;
        }
        setDichVus([...dichVus]);
    };

    const handleChangeEleDichVuTreEm = (id, event, type) => {
        let dichVus = [];
        let dichVuApDung = [];
        if (type === "ALL") {
            dichVuApDung = dichVuTreEms.filter((item) => !item.id.includes("addNew") && item.apDung);
            dichVus = dichVuTreEms.filter((item) => !item.id.includes("addNew"));
            if (dichVuApDung.length > 0 && dichVuApDung.length === dichVus.length) {
                dichVus = dichVuTreEms.map((it) => {
                    if (!it.id.includes("addNew")) it.apDung = false;
                    return it;
                });
                setCheckAll(false);
            } else {
                dichVus = dichVuTreEms.map((it) => {
                    if (!it.id.includes("addNew")) it.apDung = true;
                    return it;
                });
                setCheckAll(true);
            }
            setDichVuTreEms(dichVus);
        } else {
            let index = dichVuTreEms.findIndex((item) => item.id === id);
            if (event.target.name === "apDung") {
                dichVuTreEms[index][event.target.name] = event.target.checked;
            } else {
                dichVuTreEms[index][event.target.name] = event.target.value;
                if (event.target.name === "soLuong" || event.target.name === "phanTram") {
                    if (event.target.value < 0) {
                        dichVuTreEms[index][event.target.name] = 0;
                    }
                    dichVuTreEms[index].thanhTien =
                        dichVuTreEms[index].giaNet * dichVuTreEms[index].soLuong * (dichVuTreEms[index].phanTram / 100);
                }
            }
            dichVuApDung = dichVuTreEms.filter((item) => !item.id.includes("addNew") && item.apDung);
            dichVus = dichVuTreEms.filter((item) => !item.id.includes("addNew"));
            if (dichVuApDung.length > 0 && dichVuApDung.length === dichVus.length) setCheckAll(true);
            else setCheckAll(false);
            setDichVuTreEms([...dichVuTreEms]);
        }
    };

    const handleChangeDataServiceKS = (dataSelected) => {
        let lstDichVu = [];
        let dvUpdate = {};
        let soTien = 0;
        let foc = 0;
        let dieuKienFoc = 0;
        if (action === "UPDATE_NCC") {
            dataSource.tenNcc = dataSelected[0].tenNcc;
            dataSource.dmGiaApDungId = 1;
            dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
            dataSource.dmVatId = dataSelected[0].dmVatId ? dataSelected[0].dmVatId : 1;
            dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
            dataSource.moTa = "";
            lstDichVu = dataSelected?.map((item, idx) => {
                if (idx === 0) {
                    soTien = dataSelected[idx].fitNgayThuongNet;
                    foc = dataSelected[idx].foc;
                    dieuKienFoc = dataSelected[idx].dieuKienFoc;
                } else if (dataSelected[idx].fitNgayThuongNet < soTien) {
                    soTien = dataSelected[idx].fitNgayThuongNet;
                    foc = dataSelected[idx].foc;
                    dieuKienFoc = dataSelected[idx].foc;
                }
                item.id = idx + 1;
                item.tenDichVu = dataSelected[idx].hangPhong;
                item.giaNet = dataSelected[idx].fitNgayThuongNet;
                item.soLuongPhongDoi = 0;
                item.giaExtra = dataSelected[idx].extraNgayThuongNet;
                item.soLuongExtra = 0;
                item.giaPhongDon =
                    parseFloat(dataSelected[idx].fitNgayThuongNet) -
                    parseFloat(dataSelected[idx].fitNgayThuongNet) / parseInt(dataSelected[idx].soKhachToiDa);
                item.soLuongPhongDon = 0;
                item.tong = 0;
                item.bgNhomNgLonId = -1;
                item.isDisableSLPhongDoi = false;
                item.isDisableSLExtra = true;
                item.isDisableSLPhongDon = false;
                return item;
            });
            lstDichVu.push({ id: "NULL" });
        } else if (action === "ADD_SERVICE") {
            if (
                dataNhomNgLon.length === 0 &&
                dichVus.find(
                    (it) =>
                        it.nccHangPhongId === dataSelected[0].nccHangPhongId &&
                        it.tranxGiaPhongId === dataSelected[0].tranxGiaPhongId
                )
            ) {
                return showNoti("error", t("noti.errorSelectedService"));
            }
            if (dichVus.length === 1) {
                dataSource.tenNcc = dataSelected[0].tenNcc;
                dataSource.dmGiaApDungId = 1;
                dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
                dataSource.dmVatId = dataSelected[0].dmVatId ? dataSelected[0].dmVatId : 1;
                dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
                dataSource.moTa = "";
                dataSource.tenNcc = dataSelected[0].tenNcc;
                foc = dataSelected[0].foc;
                dieuKienFoc = dataSelected[0].dieuKienFoc;
            }
            lstDichVu = JSON.parse(JSON.stringify(dichVus));
            lstDichVu.splice(dichVus.length - 1, 1);
            lstDichVu.push({
                id: dichVus.length,
                nccGiaPhongId: dataSelected[0].nccGiaPhongId,
                nccHangPhongId: dataSelected[0].nccHangPhongId,
                tranxGiaPhongId: dataSelected[0].tranxGiaPhongId,
                maPhong: dataSelected[0].maPhong,
                kieuPhong: dataSelected[0].kieuPhong,
                dmKieuPhongId: dataSelected[0].dmKieuPhongId,
                tenDichVu: dataSelected[0].hangPhong,
                giaNet: dataSelected[0].fitNgayThuongNet,
                soLuongPhongDoi: 0,
                giaExtra: dataSelected[0].extraNgayThuongNet,
                soLuongExtra: 0,
                giaPhongDon:
                    parseFloat(dataSelected[0].fitNgayThuongNet) -
                    parseFloat(dataSelected[0].fitNgayThuongNet) / parseInt(dataSelected[0].soKhachToiDa),
                soLuongPhongDon: 0,
                bgNhomNgLonId: -1,
                tong: 0,
                isDisableSLPhongDoi: false,
                isDisableSLExtra: true,
                isDisableSLPhongDon: false,
            });
            lstDichVu.push({ id: "NULL" });
        } else {
            if (
                dataNhomNgLon.length === 0 &&
                dichVus.find(
                    (it) =>
                        it.nccHangPhongId === dataSelected[0].nccHangPhongId &&
                        it.tranxGiaPhongId === dataSelected[0].tranxGiaPhongId
                )
            ) {
                return showNoti("error", t("noti.errorSelectedService"));
            }
            let dichVusFix = dichVus.filter((d) => d.id !== "NULL");
            if (dichVusFix.length === 1) {
                dataSource.tenNcc = dataSelected[0].tenNcc;
                dataSource.dmGiaApDungId = 1;
                dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
                dataSource.dmVatId = dataSelected[0].dmVatId ? dataSelected[0].dmVatId : 1;
                dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
                dataSource.moTa = dataSelected[0].moTa;
            }
            lstDichVu = JSON.parse(JSON.stringify(dichVus));
            dvUpdate = lstDichVu[idxEdit];
            lstDichVu[idxEdit] = {
                ...dataSelected[0],
                id: idxEdit + 1,
                nccGiaPhongId: dataSelected[0].nccGiaPhongId,
                nccHangPhongId: dataSelected[0].nccHangPhongId,
                tranxGiaPhongId: dataSelected[0].tranxGiaPhongId,
                bgNhomNgLonId: 0,
                kieuPhong: dataSelected[0].kieuPhong,
                maPhong: dataSelected[0].maPhong,
                dmKieuPhongId: dataSelected[0].dmKieuPhongId,
                tenDichVu: dataSelected[0].hangPhong,
                giaNet: dataSelected[0].fitNgayThuongNet,
                soLuongPhongDoi: 0,
                giaExtra: dataSelected[0].extraNgayThuongNet,
                soLuongExtra: 0,
                giaPhongDon:
                    parseFloat(dataSelected[0].fitNgayThuongNet) -
                    parseFloat(dataSelected[0].fitNgayThuongNet) / parseInt(dataSelected[0].soKhachToiDa),
                soLuongPhongDon: 0,
                bgNhomNgLonId: -1,
                tong: 0,
                isDisableSLPhongDoi: false,
                isDisableSLExtra: true,
                isDisableSLPhongDon: false,
            };
        }
        dataSource.dieuKienFoc = dieuKienFoc;
        dataSource.foc = foc;

        let nhomTreEms = JSON.parse(JSON.stringify(dataNhomTreEm));
        let lenDataTE = nhomTreEms.reduce((num, item) => {
            item.details.forEach(() => {
                num++;
            });
            return num;
        }, 0);
        let dichVusByNgLon = lstDichVu;
        if (dataNhomNgLon.length > 0) dichVusByNgLon = lstDichVu.filter((it) => it.bgNhomNgLonId !== -1);
        let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
        let idx = 0;
        let idxCheckRowSpanTE = 1;
        if (action === "UPDATE_NCC") {
            lstDichVuTreEm = [];
            let idxCheckRowSpanDV = 1;
            for (let i = 0; i < dichVusByNgLon.length - 1; i++) {
                if (dataNhomTreEm.length === 0) {
                    if (
                        lstDichVuTreEm.length === 0 ||
                        !lstDichVuTreEm.find(
                            (it) =>
                                it.nccHangPhongId === dichVusByNgLon[i].nccHangPhongId &&
                                it.tranxGiaPhongId === dichVusByNgLon[i].tranxGiaPhongId
                        )
                    )
                        lstDichVuTreEm = [
                            ...lstDichVuTreEm,
                            {
                                id: "addNew" + generateId(),
                                tenDichVu: dichVusByNgLon[i].tenDichVu,
                                giaNet: dichVusByNgLon[i].fitNgayThuongNet,
                                nccHangPhongId: dichVusByNgLon[i].nccHangPhongId,
                                tranxGiaPhongId: dichVusByNgLon[i].tranxGiaPhongId,
                                rowSpanDV: 1,
                            },
                        ];
                } else if (
                    dataNhomNgLon.length === 0 &&
                    !lstDichVuTreEm.find(
                        (it) =>
                            it.nccHangPhongId === dichVusByNgLon[i].nccHangPhongId &&
                            it.tranxGiaPhongId === dichVusByNgLon[i].tranxGiaPhongId
                    )
                ) {
                    for (let j = 0; j < nhomTreEms.length; j++) {
                        let element = nhomTreEms[j];
                        let dichVuTEs = element.details?.map((item) => {
                            idx++;
                            return {
                                id: idx,
                                tenDichVu: dichVusByNgLon[i].tenDichVu,
                                tenNhomTE: element.tenNhom,
                                giaNet: dichVusByNgLon[i].giaNet,
                                soLuong: item.soLuong,
                                thanhTien: 0,
                                nccHangPhongId: dichVusByNgLon[i].nccHangPhongId,
                                tranxGiaPhongId: dichVusByNgLon[i].tranxGiaPhongId,
                                bgNhomTreEmId: element.id,
                                bgNhomNgLonId: dichVusByNgLon[i].bgNhomNgLonId,
                                dmDoTuoiId: item.dmDoTuoiId,
                                phanTram: 0,
                                apDung: false,
                                rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                                rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
                            };
                        });
                        idxCheckRowSpanTE += element.details.length;
                        lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEs];
                    }
                    idxCheckRowSpanDV += lenDataTE;
                }
            }
        } else if (action === "ADD_SERVICE") {
            if (dataNhomTreEm.length === 0) {
                if (
                    lstDichVuTreEm.length === 0 ||
                    !lstDichVuTreEm.find(
                        (it) =>
                            it.nccHangPhongId === dataSelected[0].nccHangPhongId &&
                            it.tranxGiaPhongId === dataSelected[0].tranxGiaPhongId
                    )
                )
                    lstDichVuTreEm = [
                        ...lstDichVuTreEm,
                        {
                            id: "addNew" + generateId(),
                            tenDichVu: dataSelected[0].hangPhong,
                            giaNet: dataSelected[0].fitNgayThuongNet,
                            nccHangPhongId: dataSelected[0].nccHangPhongId,
                            tranxGiaPhongId: dataSelected[0].tranxGiaPhongId,
                            rowSpanDV: 1,
                        },
                    ];
            } else if (
                dataNhomNgLon.length === 0 &&
                !lstDichVuTreEm.find(
                    (it) =>
                        it.nccHangPhongId === dataSelected[0].nccHangPhongId &&
                        it.tranxGiaPhongId === dataSelected[0].tranxGiaPhongId
                )
            ) {
                idx = lenDataTE * (prevCountDichVu.current - 1);
                idxCheckRowSpanTE = lenDataTE * (prevCountDichVu.current - 1) + 1;
                for (let index = 0; index < nhomTreEms.length; index++) {
                    let element = nhomTreEms[index];
                    let dichVuTEByGroup = element.details?.map((item) => {
                        idx++;
                        return {
                            id: idx,
                            tenDichVu: dataSelected[0].hangPhong,
                            tenNhomTE: element.tenNhom,
                            phanTram: 0,
                            giaNet: dataSelected[0].fitNgayThuongNet,
                            soLuong: item.soLuong,
                            thanhTien: 0,
                            nccHangPhongId: dataSelected[0].nccHangPhongId,
                            tranxGiaPhongId: dataSelected[0].tranxGiaPhongId,
                            bgNhomTreEmId: element.id,
                            bgNhomNgLonId: -1,
                            dmDoTuoiId: item.dmDoTuoiId,
                            phanTram: 0,
                            rowSpanDV: idx === lenDataTE * (prevCountDichVu.current - 1) + 1 ? lenDataTE : 0,
                            rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
                        };
                    });
                    idxCheckRowSpanTE += element.details.length;
                    lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEByGroup];
                }
            }
        } else {
            if (dataNhomTreEm.length === 0) {
                lstDichVuTreEm = lstDichVuTreEm.filter(
                    (it) =>
                        !(
                            it.nccHangPhongId === dvUpdate.nccHangPhongId &&
                            it.tranxGiaPhongId === dvUpdate.tranxGiaPhongId
                        )
                );
                lstDichVuTreEm = [
                    ...lstDichVuTreEm,
                    {
                        id: "addNew" + generateId(),
                        tenDichVu: lstDichVu[idxEdit].tenDichVu,
                        giaNet: lstDichVu[idxEdit].giaNet,
                        nccHangPhongId: lstDichVu[idxEdit].nccHangPhongId,
                        tranxGiaPhongId: lstDichVu[idxEdit].tranxGiaPhongId,
                        rowSpanDV: 1,
                    },
                ];
                // for (let i = 0; i < lstDichVu.length - 1; i++) {
                //   if (
                //     lstDichVuTreEm.length === 0 ||
                //     !lstDichVuTreEm.find(
                //       (it) =>
                //         it.nccHangPhongId === lstDichVu[i].nccHangPhongId &&
                //         it.tranxGiaPhongId === lstDichVu[i].tranxGiaPhongId
                //     )
                //   ) {
                //     lstDichVuTreEm = [
                //       ...lstDichVuTreEm,
                //       {
                //         id: "addNew",
                //         tenDichVu: lstDichVu[i].tenDichVu,
                //         giaNet: lstDichVu[i].giaNet,
                //         nccHangPhongId: lstDichVu[i].nccHangPhongId,
                //         tranxGiaPhongId: lstDichVu[i].tranxGiaPhongId,
                //         rowSpanDV: 1,
                //       },
                //     ];
                //   }
                // }
            } else if (dataNhomNgLon.length === 0) {
                lstDichVuTreEm = [];
                let idxCheckRowSpanDV = 1;
                for (let i = 0; i < lstDichVu.length - 1; i++) {
                    if (
                        !lstDichVuTreEm.find(
                            (it) =>
                                it.nccHangPhongId === lstDichVu[i].nccHangPhongId &&
                                it.tranxGiaPhongId === lstDichVu[i].tranxGiaPhongId
                        )
                    ) {
                        for (let j = 0; j < nhomTreEms.length; j++) {
                            let element = nhomTreEms[j];
                            let dichVuTEs = element.details?.map((item) => {
                                idx++;
                                return {
                                    id: idx,
                                    tenDichVu: lstDichVu[i].tenDichVu,
                                    tenNhomTE: element.tenNhom,
                                    giaNet: lstDichVu[i].giaNet,
                                    soLuong: item.soLuong,
                                    thanhTien: 0,
                                    nccHangPhongId: lstDichVu[i].nccHangPhongId,
                                    tranxGiaPhongId: lstDichVu[i].tranxGiaPhongId,
                                    bgNhomTreEmId: element.id,
                                    bgNhomNgLonId: -1,
                                    dmDoTuoiId: item.dmDoTuoiId,
                                    phanTram: 0,
                                    apDung: false,
                                    rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                                    rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
                                };
                            });
                            idxCheckRowSpanTE += element.details.length;
                            lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEs];
                        }
                        idxCheckRowSpanDV += lenDataTE;
                    }
                }
            } else {
                lstDichVuTreEm = dichVuTreEms.filter((item) => item.bgNhomNgLonId !== dichVus[idxEdit].bgNhomNgLonId);
            }
        }
        if (dataSelected.length > 0) setMaNcc(dataSelected[0].maNcc);
        setDichVuTreEms(lstDichVuTreEm);
        setDichVus(lstDichVu);
        setCountDichVu(lstDichVu.length);
        setDataSource(dataSource);
        console.log(dichVuTreEms);
        if (action === "ADD_SERVICE") showNoti("success", t("add") + " " + t("noti.success").toLowerCase());
        else showNoti("success", t("edit") + " " + t("noti.success").toLowerCase());
    };

    const handleUpdateNCC = () => {
        setMaNcc("");
        setIsShowNccKS(true);
        setAction("UPDATE_NCC");
    };

    const handleAddService = () => {
        // if (
        //   dataNhomNgLon.length > 0 &&
        //   dichVus.length - 1 === dataNhomNgLon.length
        // ) {
        //   showNoti("error", t("noti.errorMaxServices"));
        // } else {
        if (dichVus.length === 1) setMaNcc("");
        setIsShowNccKS(true);
        setAction("ADD_SERVICE");
        // }
    };

    const handleUpdateService = (record) => {
        let index = dichVus.findIndex((item) => item.id === record.id);
        setIsShowNccKS(true);
        setAction("UPDATE_SERVICE");
        setIdxEdit(index);
    };

    const handleDeleteServiceKS = (record) => {
        let index = dichVus.findIndex((item) => item.id === record.id);
        let lstDichVu = JSON.parse(JSON.stringify(dichVus));
        lstDichVu.splice(index, 1);
        let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
        if (dataNhomNgLon.length === 0) {
            if (
                !lstDichVu.find(
                    (it) => it.nccHangPhongId === record.nccHangPhongId && it.tranxGiaPhongId === record.tranxGiaPhongId
                )
            )
                lstDichVuTreEm = lstDichVuTreEm.filter(
                    (it) =>
                        !(it.nccHangPhongId === record.nccHangPhongId && it.tranxGiaPhongId === record.tranxGiaPhongId)
                );
        } else {
            lstDichVuTreEm = dichVuTreEms.filter((item) => item.bgNhomNgLonId !== dichVus[index].bgNhomNgLonId);
        }
        setDichVuTreEms(lstDichVuTreEm);
        setDichVus(lstDichVu);
        setCountDichVu(lstDichVu.length);
    };

    const handleChangeNhomNgLon = (id, event) => {
        let index = dichVus.findIndex((item) => item.id === id);
        let name = event.target.name;
        let value = event.target.value;
        // if (!dichVus.find((it) => it.bgNhomNgLonId === value)) {
        if (dataNhomTreEm.length > 0) {
            let tenNhomNgLon = dataNhomNgLon.find((it) => it.id === value)?.tenNhom;
            dichVus[index][name] = value;
            dichVus[index].tenNhomNgLon = tenNhomNgLon;
            let dichVusByNgLon = dichVus.filter((it) => it.bgNhomNgLonId !== -1);
            let idx = 0;
            let idxCheckRowSpanTE = 1;
            let lstDichVuTreEm = [];
            let idxCheckRowSpanDV = 1;
            for (let i = 0; i < dichVusByNgLon.length - 1; i++) {
                let nhomTreEms = dataNhomTreEm.filter(
                    (item) => item.nhomNguoiLonId === dichVusByNgLon[i].bgNhomNgLonId
                );
                let lenDataTE = nhomTreEms.reduce((num, item) => {
                    item.details.forEach(() => {
                        num++;
                    });
                    return num;
                }, 0);
                for (let j = 0; j < nhomTreEms.length; j++) {
                    let element = nhomTreEms[j];
                    let dichVuTEs = element.details?.map((item) => {
                        idx++;
                        return {
                            id: generateId(),
                            tenDichVu: dichVusByNgLon[i].tenDichVu,
                            tenNhomTE: element.tenNhom,
                            giaNet: dichVusByNgLon[i].giaNet,
                            soLuong: item.soLuong,
                            thanhTien: 0,
                            nccHangPhongId: dichVusByNgLon[i].nccHangPhongId,
                            tranxGiaPhongId: dichVusByNgLon[i].tranxGiaPhongId,
                            bgNhomTreEmId: element.id,
                            bgNhomNgLonId: dichVusByNgLon[i].bgNhomNgLonId,
                            dmDoTuoiId: item.dmDoTuoiId,
                            phanTram: 0,
                            apDung: false,
                            rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                            rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
                        };
                    });
                    idxCheckRowSpanTE += element.details.length;
                    lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEs];
                }
                idxCheckRowSpanDV += lenDataTE;
            }
            setDichVuTreEms(lstDichVuTreEm);
            setDichVus([...dichVus]);
        }
        // } else {
        //   showNoti("error", t("noti.errorNhomNgLonSelected"));
        // }
    };

    const handleAddServiceTreEm = (row) => {
        let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
        let index = lstDichVuTreEm.findIndex((it) => it.id === row.id);
        const newTreEm = {
            id: generateId(),
            tenDichVu: row.tenDichVu,
            giaNet: row.giaNet,
            nccHangPhongId: row.nccHangPhongId,
            tranxGiaPhongId: row.tranxGiaPhongId,
            thanhTien: 0,
            phanTram: 0,
            soLuong: 0,
            bgNhomTreEmId: 0,
            dmDoTuoiId: 1,
            apDung: false,
            rowSpanDV: row.rowSpanDV === 0 ? 0 : row.rowSpanDV,
            rowSpanTE: 0,
        };
        lstDichVuTreEm[index].rowSpanDV = 0;
        lstDichVuTreEm.splice(index, 0, newTreEm);
        let idxFirst = lstDichVuTreEm.findIndex(
            (item) => item.nccHangPhongId === row.nccHangPhongId && item.tranxGiaPhongId === row.tranxGiaPhongId
        );
        lstDichVuTreEm[idxFirst].rowSpanDV += 1;
        setDichVuTreEms(lstDichVuTreEm);
    };

    const handleDeleteServiceTreEm = (row) => {
        let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
        let index = lstDichVuTreEm.findIndex((it) => it.id === row.id);
        lstDichVuTreEm.splice(index, 1);
        let idxFirst = lstDichVuTreEm.findIndex(
            (item) => item.nccHangPhongId === row.nccHangPhongId && item.tranxGiaPhongId === row.tranxGiaPhongId
        );
        if (row.rowSpanDV === 0) lstDichVuTreEm[idxFirst].rowSpanDV -= 1;
        else {
            lstDichVuTreEm[idxFirst].rowSpanDV = row.rowSpanDV - 1;
        }
        setDichVuTreEms(lstDichVuTreEm);
    };

    const handleSave = (e) => {
        let rsCheck = handleValidateData();
        if (rsCheck.check) {
            let dataReq = getDataReq();
            console.log(dataReq);
            console.log(JSON.stringify(dataReq));
            khachSanService
                .saveChietTinhSaleKS(dataReq)
                .then((response) => {
                    let action = "add";
                    if (bgMapCTSaleHtId !== 0) action = "edit";
                    if (response.status === 200 && response.data.code === "00") {
                        showNoti("success", t(action) + " " + t("noti.success").toLowerCase());
                        setDataSourceInit({ ...dataSource });
                        setDichVusInit(JSON.parse(JSON.stringify(dichVus)));
                        setDichVuTreEmsInit(JSON.parse(JSON.stringify(dichVuTreEms)));
                        handleCancel();
                        onReset();
                    } else {
                        showNoti("error", response.data.message);
                    }
                })
                .catch(() => {
                    showNoti("error", t("system.error"));
                });
        } else {
            showNoti("error", rsCheck.message);
        }
    };

    const showNoti = (type, message) => {
        return api[type]({
            message: t("stour"),
            description: message,
            duration: TIME_DURATION,
        });
    };

    const getDataReq = () => {
        let ngayApDungs = [];
        if (ngay !== 0) {
            ngayApDungs =
                bgHanhTrinhs
                    ?.filter((item) => {
                        if (dataSource.dmGiaApDungId === 1) return item.ngay_order >= ngay;
                        else return item.ngay_order === ngay;
                    })
                    .map((item) => item.ngay_order) || [];
        }
        const dataReq = {
            ngay: ngay,
            baoGiaHanhTrinhId: baoGiaHanhTrinhId,
            bgCTSaleId: bgChietTinhSaleId,
            baoGiaTongQuanId: baoGiaTongQuanId,
            ngayApDungs: ngay === 0 ? dataSource.ngayApDungs : ngayApDungs,
            dmDichVuId: dmDichVuId,
            bgMapCTSaleHtId: bgMapCTSaleHtId ? bgMapCTSaleHtId : 0, //thêm mới, >0 chỉnh sửa
            nccThongTinChungId: dataSource.nccThongTinChungId,
            tenNcc: dataSource.tenNcc,
            dmGiaApDungId: dataSource.dmGiaApDungId,
            dmTyGiaId: dataSource.dmTyGiaId,
            dieuKienFoc: dataSource.dieuKienFoc,
            foc: dataSource.foc,
            moTa: dataSource.moTa,
            dmVatId: dataSource.dmVatId,
            dichVus: dichVus
                .filter((dt) => dt.id !== "NULL")
                .map((it) => {
                    return {
                        // dmVatId: dataSource.dmVatId,
                        nccHangPhongId: it.nccHangPhongId,
                        tenDichVu: it.tenDichVu,
                        nccGiaPhongId: it.nccGiaPhongId,
                        tranxGiaPhongId: it.tranxGiaPhongId,
                        giaDichVus: [
                            {
                                dmKieuPhongId: it.dmKieuPhongId, // phong doi
                                maPhong: it.maPhong,
                                soLuong: it.soLuongPhongDoi,
                            },
                            {
                                dmKieuPhongId: dmKieuPhong.find((it) => it.ma_phong === maPhuPhi)?.id, // phụ phí
                                maPhong: maPhuPhi,
                                soLuong: it.soLuongExtra,
                            },
                            {
                                dmKieuPhongId: dmKieuPhong.find((it) => it.ma_phong === maPhongDon)?.id, // phong don
                                maPhong: maPhongDon,
                                soLuong: it.soLuongPhongDon,
                            },
                        ],
                        // donGiaNhapTay: 0,
                        // soLuongNhapTay: 0,
                        bgNhomNguoiLonId: it.bgNhomNgLonId,
                        dichVuTreEms: handleGroupDichVuTreEm(it.nccHangPhongId, it.tranxGiaPhongId, it.bgNhomNgLonId),
                    };
                }),
        };
        return dataReq;
    };

    const handleGroupDichVuTreEm = (nccHangPhongId, tranxGiaPhongId, bgNhomNgLonId) => {
        let lstDichVuTreEm =
            dataNhomNgLon.length === 0
                ? dichVuTreEms.filter(
                      (it) =>
                          !it.id.includes("addNew") &&
                          it.nccHangPhongId === nccHangPhongId &&
                          it.tranxGiaPhongId === tranxGiaPhongId
                  )
                : dichVuTreEms.filter(
                      (it) =>
                          !it.id.includes("addNew") &&
                          it.nccHangPhongId === nccHangPhongId &&
                          it.tranxGiaPhongId === tranxGiaPhongId &&
                          it.bgNhomNgLonId === bgNhomNgLonId
                  );
        let groupDichVuTreEm = Object.groupBy(lstDichVuTreEm, ({ bgNhomTreEmId }) => bgNhomTreEmId);
        let keyGroupDichVuTreEm = Object.keys(groupDichVuTreEm);
        let dichVuTEs = keyGroupDichVuTreEm.map((key) => {
            return {
                bgNhomTreEmId: key,
                details: groupDichVuTreEm[key]?.map((it) => {
                    return {
                        dmDoTuoiId: it.dmDoTuoiId,
                        phanTram: it.phanTram,
                        donGiaNhapTay: 0,
                        soLuong: it.soLuong,
                        dmVatId: dataSource.dmVatId,
                        apDung: it.apDung,
                    };
                }),
            };
        });
        return dichVuTEs;
    };

    const handleValidateData = () => {
        let result = { check: true, message: "" };
        // let soLuongTE = dichVuTreEms.reduce((sum, item) => {
        //   if (!item.id.includes("addNew")) sum += parseInt(item.soLuong);
        //   return sum;
        // }, 0);
        // if (soLuongTE > soLuongTreEmBG) {
        //   result.check = false;
        //   result.message = t("noti.errorSoLuongTE");
        // } else
        if (ngay === 0 && dataSource.ngayApDungs.length === 0) {
            result.check = false;
            result.message = t("noti.errorNgayApDungs");
        } else if (!dichVus || dichVus.length <= 1) {
            result.check = false;
            result.message = t("noti.saveWarning");
        }
        // else if (dataNhomNgLon.length > 0 && bgNhomNgLonId === 0) {
        //   result.check = false;
        //   result.message = t("noti.errorNhomNgLon");
        // }
        return result;
    };

    const handleClear = () => {
        setMaNcc("");
        setDataSource({ ...dataSourceInit });
        setDichVus(JSON.parse(JSON.stringify(dichVusInit)));
        setDichVuTreEms(JSON.parse(JSON.stringify(dichVuTreEmsInit)));
    };

    const handleCloseModal = () => {
        handleCancel();
        handleClear();
    };

    const onIsShow = () => {
        setIsShowNccKS(false);
    };

    const handleChangNgayApDung = (e, ngayOrder) => {
        // let name = parseInt(e.target.name);
        let value = e.target.checked;
        if (dataSource.dmGiaApDungId === 2) {
            if (value) dataSource.ngayApDungs.push(ngayOrder);
            else dataSource.ngayApDungs = dataSource.ngayApDungs.filter((it) => it !== ngayOrder);
        } else {
            if (value) {
                for (let i = ngayOrder; i < bgHanhTrinhs.length + 1; i++) {
                    dataSource.ngayApDungs.push(i);
                }
            } else {
                dataSource.ngayApDungs = [];
            }
        }
        console.log(dataSource.ngayApDungs);
        setDataSource({ ...dataSource });
    };

    const columnDichVus = [
        {
            title: t("service"),
            dataIndex: "tenDichVu",
            key: "tenDichVu",
            width: "14%",
            align: "center",
            visible: true,
            render: (_, record) => {
                if (record.id === "NULL") {
                    return {
                        children: (
                            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                <Link onClick={() => handleAddService()} underline="none">
                                    {t("addService")}
                                </Link>
                            </Box>
                        ),
                        props: {
                            colSpan: 10,
                        },
                    };
                }
                return (
                    <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={record.tenDichVu}
                        InputProps={{
                            endAdornment: (
                                <>
                                    <BorderColorOutlined
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        onClick={() => handleUpdateService(record)}
                                    />
                                    <CloseCircleFilled
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        onClick={() => handleDeleteServiceKS(record)}
                                    />
                                </>
                            ),
                        }}
                    />
                );
            },
        },
        {
            title: t("hotel.priceClass.typeRoom"),
            dataIndex: "kieuPhong",
            align: "center",
            width: "9%",
            visible: true,
            render: (_, record) => {
                if (record.id === "NULL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return {
                    children: (
                        <TextField
                            variant="outlined"
                            value={record.kieuPhong}
                            size="small"
                            fullWidth
                            disabled={true}
                        ></TextField>
                    ),
                    props: {
                        rowSpan: 1,
                    },
                };
            },
        },
        {
            title: t("tour.phongDoi"),
            children: [
                {
                    title: t("hotel.priceClass.giaNet"),
                    dataIndex: "giaNet",
                    key: "giaNet",
                    width: "9%",
                    align: "center",
                    render: (text, record) => {
                        if (record.id === "NULL") {
                            return {
                                props: {
                                    colSpan: 0,
                                },
                            };
                        }
                        return {
                            children: formatter.format(text),
                            props: {
                                rowSpan: 1,
                            },
                        };
                        // return handleData(record.fitCuoiTuanNet);
                    },
                },
                {
                    title: t("hotel.roomClass.numberOfRoom"),
                    dataIndex: "soLuongPhongDoi",
                    key: "soLuongPhongDoi",
                    width: "9%",
                    align: "center",
                    render: (text, record) => {
                        if (record.id === "NULL") {
                            return {
                                props: {
                                    colSpan: 0,
                                },
                            };
                        }
                        return (
                            <TextField
                                value={text}
                                disabled={record.isDisableSLPhongDoi}
                                name="soLuongPhongDoi"
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={(e) => handleChangeEleDichVu(record.id, e)}
                                fullWidth
                            ></TextField>
                        );
                    },
                },
            ],
            width: "18%",
            visible: true,
            align: "center",
        },
        {
            title: t("phuPhi"),
            children: [
                {
                    title: t("giaExtra"),
                    dataIndex: "giaExtra",
                    key: "giaExtra",
                    width: "9%",
                    align: "center",
                    render: (text, record) => {
                        if (record.id === "NULL") {
                            return {
                                props: {
                                    colSpan: 0,
                                },
                            };
                        }
                        return {
                            children: formatter.format(text),
                            props: {
                                rowSpan: 1,
                            },
                        };
                        // return handleData(record.fitCuoiTuanNet);
                    },
                },
                {
                    title: t("soLuongExtra"),
                    dataIndex: "soLuongExtra",
                    key: "soLuongExtra",
                    width: "9%",
                    align: "center",
                    render: (text, record) => {
                        if (record.id === "NULL") {
                            return {
                                props: {
                                    colSpan: 0,
                                },
                            };
                        }
                        return (
                            <TextField
                                value={text}
                                disabled={record.isDisableSLExtra}
                                name="soLuongExtra"
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={(e) => handleChangeEleDichVu(record.id, e)}
                                fullWidth
                            ></TextField>
                        );
                    },
                },
            ],
            width: "18%",
            visible: true,
            align: "center",
        },
        {
            title: t("tour.phongDon"),
            children: [
                {
                    title: t("giaPhongDon"),
                    dataIndex: "giaPhongDon",
                    key: "giaPhongDon",
                    width: "9%",
                    align: "center",
                    render: (text, record) => {
                        if (record.id === "NULL") {
                            return {
                                props: {
                                    colSpan: 0,
                                },
                            };
                        }
                        return {
                            children: formatter.format(text),
                            props: {
                                rowSpan: 1,
                            },
                        };
                        // return handleData(record.fitCuoiTuanNet);
                    },
                },
                {
                    title: t("soLuongPhongDon"),
                    dataIndex: "soLuongPhongDon",
                    key: "soLuongPhongDon",
                    width: "9%",
                    align: "center",
                    render: (text, record) => {
                        if (record.id === "NULL") {
                            return {
                                props: {
                                    colSpan: 0,
                                },
                            };
                        }
                        return (
                            <TextField
                                value={text}
                                disabled={record.isDisableSLPhongDon}
                                name="soLuongPhongDon"
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={(e) => handleChangeEleDichVu(record.id, e)}
                                fullWidth
                            ></TextField>
                        );
                    },
                },
            ],
            width: "18%",
            visible: true,
            align: "center",
        },
        {
            title: t("tour.tongChiPhi"),
            dataIndex: "tong",
            key: "tong",
            width: "10%",
            align: "center",
            visible: true,
            render: (text, record) => {
                if (record.id === "NULL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return {
                    children: formatter.format(text),
                    props: {
                        rowSpan: 1,
                    },
                };
            },
        },
        {
            title: t("adultGroup"),
            dataIndex: "bgNhomNgLonId",
            key: "bgNhomNgLonId",
            width: "15%",
            align: "center",
            visible: dataNhomNgLon.length > 0,
            render: (id, record) => {
                if (record.id === "NULL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                } else {
                    return {
                        children: (
                            <TextField
                                value={id}
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="bgNhomNgLonId"
                                onChange={(e) => handleChangeNhomNgLon(record.id, e)}
                                select
                            >
                                {dataNhomNgLon.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.tenNhom}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ),
                        props: {
                            colSpan: 1,
                            // rowSpan: dichVus.length - 1,
                        },
                    };
                }
                // else {
                //   return {
                //     props: {
                //       rowSpan: 0,
                //     },
                //   };
                // }
            },
        },
    ];

    const columnsTE = [
        {
            title: t("service"),
            dataIndex: "tenDichVu",
            align: "center",
            width: "15%",
            visible: true,
            render: (text, row) => {
                return {
                    children: text,
                    props: {
                        rowSpan: row.rowSpanDV,
                    },
                };
            },
        },
        {
            title: t("childrenGroup"),
            dataIndex: "tenNhomTE",
            align: "center",
            width: "15%",
            visible: dataNhomTreEm.length > 0,
            render: (text, row) => {
                return {
                    children: (
                        <TextField variant="outlined" value={text} size="small" fullWidth disabled={true}></TextField>
                    ),
                    props: {
                        rowSpan: row.rowSpanTE,
                    },
                };
            },
        },
        {
            title: t("age") + " " + t("children"),
            dataIndex: "dmDoTuoiId",
            align: "center",
            width: "15%",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        children: (
                            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                <Link onClick={() => handleAddServiceTreEm(row)} underline="none">
                                    {t("addService")}
                                </Link>
                            </Box>
                        ),
                        props: {
                            colSpan: 6,
                        },
                    };
                return (
                    <>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={22}>
                                <TextField
                                    variant="outlined"
                                    value={text}
                                    size="small"
                                    name="dmDoTuoiId"
                                    fullWidth
                                    select
                                    disabled={dataNhomTreEm.length > 0}
                                    onChange={(e) => handleChangeEleDichVuTreEm(row.id, e)}
                                >
                                    {dmDoTuoi.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.do_tuoi}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={2}>
                                {dataNhomTreEm.length === 0 && (
                                    <CloseCircleFilled
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleDeleteServiceTreEm(row)}
                                        fontSize="small"
                                    />
                                )}
                            </Col>
                        </Row>
                    </>
                );
            },
        },
        {
            title: t("tour.percentage"),
            dataIndex: "phanTram",
            align: "center",
            width: "10%",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                // if (text)
                return {
                    children: (
                        <TextField
                            variant="outlined"
                            value={text}
                            size="small"
                            name="phanTram"
                            fullWidth
                            type="number"
                            onChange={(e) => handleChangeEleDichVuTreEm(row.id, e)}
                        />
                    ),
                    props: {},
                };
            },
        },
        {
            title: t("hotel.priceClass.giaNet"),
            dataIndex: "giaNet",
            align: "center",
            width: "12%",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                if (text)
                    return {
                        children: formatter.format(text),
                        props: {},
                    };
            },
        },
        {
            title: t("tour.vanChuyen.soLuong"),
            dataIndex: "soLuong",
            align: "center",
            width: "10%",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };

                return {
                    children: (
                        <TextField
                            value={text}
                            variant="outlined"
                            name="soLuong"
                            size="small"
                            type="number"
                            fullWidth
                            onChange={(e) => handleChangeEleDichVuTreEm(row.id, e)}
                        ></TextField>
                    ),
                    props: {},
                };
            },
        },
        {
            title: t("hotel.roomClass.intoMoney"),
            dataIndex: "thanhTien",
            width: "15%",
            align: "center",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                // if (text)
                return {
                    children: formatter.format(text),
                    props: {},
                };
            },
        },
        {
            title: () => {
                return (
                    <>
                        {t("action.apply")}{" "}
                        <Checkbox
                            checked={checkAll}
                            onChange={(e) => handleChangeEleDichVuTreEm(e, "", "ALL")}
                        ></Checkbox>
                    </>
                );
            },
            dataIndex: "apDung",
            align: "center",
            width: "10%",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                return {
                    children: (
                        <Checkbox
                            name="apDung"
                            checked={row.apDung}
                            onChange={(e) => handleChangeEleDichVuTreEm(row.id, e)}
                        />
                    ),
                    props: {},
                };
            },
        },
    ];

    return (
        <>
            {contextHolder}
            <Modal
                title={t("tour.khachSan.capNhatDichVu")}
                footer={null}
                open={isOpen}
                onCancel={handleCloseModal}
                className="custom-modal"
            >
                <Row style={{ marginTop: "20px" }}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label={t("supplier")}
                        value={dataSource.tenNcc}
                        required
                        InputProps={{
                            style: { height: "50px" },
                            endAdornment: (
                                <>
                                    <BorderColorOutlined
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        onClick={() => handleUpdateNCC()}
                                    />
                                    <CloseCircleFilled
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleClear()}
                                        fontSize="small"
                                    />
                                </>
                            ),
                        }}
                    ></TextField>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Table
                            dataSource={dichVus}
                            columns={columnDichVus.filter((item) => item.visible)}
                            bordered
                            pagination={false}
                            summary={(pageData) => {
                                // console.log(pageData);
                                let totalPhongDoi = 0;
                                let totalExtra = 0;
                                let totelPhongDon = 0;
                                let totalChiPhi = 0;

                                pageData.forEach(({ soLuongPhongDoi, soLuongExtra, soLuongPhongDon, tong, id }) => {
                                    if (id !== "NULL") {
                                        totalPhongDoi += parseInt(soLuongPhongDoi);
                                        totalExtra += parseInt(soLuongExtra);
                                        totelPhongDon += parseInt(soLuongPhongDon);
                                        totalChiPhi += parseFloat(tong);
                                    }
                                });
                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={2}>
                                                {t("tour.tongKet")}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={1} colSpan={2} align="center">
                                                <Tooltip title={t("totalRooms")}>{totalPhongDoi}</Tooltip>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={2} colSpan={2} align="center">
                                                <Tooltip title={t("totalSurcharges")}>{totalExtra}</Tooltip>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={3} colSpan={2} align="center">
                                                <Tooltip title={t("totalSingleRooms")}>{totelPhongDon}</Tooltip>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={4} colSpan={2} align="center">
                                                <Tooltip title={t("totalCost")}>
                                                    {formatter.format(totalChiPhi)}
                                                </Tooltip>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <TextField
                            id="dmGiaApDungId"
                            select
                            sx={{
                                marginBottom: 2,
                            }}
                            fullWidth
                            label={t("priceApply")}
                            value={dataSource.dmGiaApDungId}
                            name="dmGiaApDungId"
                            onChange={(e) => handleChangeValueDataSoure(e)}
                        >
                            {dmGia?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.gia_ap_dung}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <FormControl disabled={ngay !== 0} style={{ width: "100%" }}>
                            <Row>
                                {bgHanhTrinhs?.map((element) => {
                                    return (
                                        <FormControlLabel
                                            key={element.id}
                                            control={
                                                <Checkbox
                                                    name={element.id}
                                                    onChange={(e) => handleChangNgayApDung(e, element.ngay_order)}
                                                    checked={
                                                        ngay !== 0
                                                            ? (dataSource.dmGiaApDungId === 1 &&
                                                                  parseInt(element.ngay_order) >= ngay) ||
                                                              (dataSource.dmGiaApDungId === 2 &&
                                                                  parseInt(element.ngay_order) === ngay)
                                                            : dataSource.ngayApDungs?.includes(element.ngay_order)
                                                    }
                                                />
                                            }
                                            label={"Ngày " + element.ngay_order}
                                        />
                                    );
                                })}
                            </Row>
                        </FormControl>
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <TextField
                            select
                            sx={{
                                marginBottom: 2,
                            }}
                            fullWidth
                            label={t("typeOfApply")}
                            value={1}
                            disabled
                        >
                            {dmLoaiApDung?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.loai_ap_dung}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <TextField
                            select
                            sx={{
                                marginBottom: 2,
                            }}
                            fullWidth
                            label={t("action.apply") + " " + t("thuevat")}
                            value={dataSource.dmVatId}
                            disabled
                        >
                            {dmVat?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.vat_str}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <TextField
                            sx={{
                                marginBottom: 2,
                            }}
                            fullWidth
                            label={t("typeTour.description")}
                            value={dataSource.moTa}
                            name="moTa"
                            onChange={(e) => handleChangeValueDataSoure(e)}
                            //   disabled
                        ></TextField>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                    <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                        <TextField
                            fullWidth
                            label={t("hotel.roomClass.conditionFoc")}
                            name="dieuKienFoc"
                            value={dataSource.dieuKienFoc}
                            type="number"
                            onChange={(e) => handleChangeValueDataSoure(e)}
                            InputProps={{
                                style: { height: "50px" },
                                endAdornment: <>{t("room")}</>,
                            }}
                        ></TextField>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={1}></Col>
                    <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                        <TextField
                            fullWidth
                            label="FOC"
                            name="foc"
                            type="number"
                            value={dataSource.foc}
                            onChange={(e) => handleChangeValueDataSoure(e)}
                            InputProps={{
                                style: { height: "50px" },
                                endAdornment: <>FOC</>,
                            }}
                        ></TextField>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <h3>{t("hotel.table.title")}</h3>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Table
                            dataSource={dichVuTreEms}
                            columns={columnsTE.filter((item) => item.visible)}
                            pagination={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <h3>{t("tour.tongKet")}</h3>
                </Row>
                <Row>
                    <Col xs={16} sm={16} md={19} lg={16} xl={16}></Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ fontSize: "18px" }}>
                        {dataNhomNgLon.length > 0 ? (
                            dichVus
                                .filter((item) => item.bgNhomNgLonId)
                                .map((it) => {
                                    let tenNhomNgLon = dataNhomNgLon.find((dt) => dt.id === it.bgNhomNgLonId)?.tenNhom;
                                    return (
                                        <Row key={it.id}>
                                            <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                {t("tour.chiPhi") + " " + tenNhomNgLon + ":"}
                                            </Col>
                                            <Col span={3}></Col>
                                            <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                {formatter.format(it.tong)}
                                            </Col>
                                        </Row>
                                    );
                                })
                        ) : (
                            <>
                                <Row>
                                    <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {t("tour.tongChiPhi") + " " + t("adult")}
                                    </Col>

                                    <Col span={3}></Col>
                                    <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {formatter.format(chiPhiNguoiLon)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {t("tour.tongChiPhi") + " " + t("children")}
                                    </Col>

                                    <Col span={3}></Col>
                                    <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {formatter.format(chiPhiTreEm)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        FOC
                                    </Col>

                                    <Col span={3}></Col>
                                    <Col
                                        span={5}
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {formatter.format(foc)}
                                    </Col>
                                </Row>
                            </>
                        )}
                        {dataNhomNgLon.length > 0 &&
                            Object.keys(
                                Object.groupBy(
                                    dichVuTreEms.filter((it) => it.apDung),
                                    ({ tenNhomTE }) => tenNhomTE
                                )
                            )?.map((it, idx) => {
                                return (
                                    <Row key={idx}>
                                        <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {t("tour.chiPhi") + " " + it + ":"}
                                        </Col>
                                        <Col span={3}></Col>
                                        <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {formatter.format(
                                                Object.groupBy(
                                                    dichVuTreEms.filter((it) => it.apDung),
                                                    ({ tenNhomTE }) => tenNhomTE
                                                )[it]?.reduce((sum, item) => sum + item.thanhTien, 0)
                                            )}
                                        </Col>
                                    </Row>
                                );
                            })}
                        {dataNhomNgLon.length > 0 &&
                            dichVus
                                .filter((item) => item.tenNhomNgLon)
                                .map((it, idx) => {
                                    return (
                                        <Row key={idx}>
                                            <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                {t("tour.tongChiPhi") + " " + it.tenNhomNgLon + ":"}
                                            </Col>
                                            <Col span={3}></Col>
                                            <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                {formatter.format(
                                                    dichVuTreEms
                                                        .filter(
                                                            (dt) => dt.apDung && dt.bgNhomNgLonId === it.bgNhomNgLonId
                                                        )
                                                        .reduce((sum, item) => sum + parseInt(item.thanhTien), 0) +
                                                        it.tong
                                                )}
                                            </Col>
                                        </Row>
                                    );
                                })}
                        <Row>
                            <Col
                                span={11}
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    fontWeight: "bold",
                                }}
                            >
                                {t("tour.tong") + " " + t("hotel.roomClass.intoMoney")}
                            </Col>

                            <Col span={3}></Col>
                            <Col
                                span={5}
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    fontWeight: "bold",
                                }}
                            >
                                {formatter.format(tongChiPhi)}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <Row style={{ marginTop: 6 }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
                    <Col xs={24} sm={24} md={24} lg={18} xl={10}>
                        <Space>
                            <Buttons
                                variant="contained"
                                type="submit"
                                startIcon={<SaveIcon />}
                                onClick={(e) => handleSave(e)}
                            >
                                {t("action.ok")}
                            </Buttons>
                            <Buttons
                                style={{
                                    backgroundColor: "#898989",
                                    color: "white",
                                }}
                                key="reset"
                                startIcon={<RestartAltIcon />}
                                onClick={(e) => handleClear(e)}
                            >
                                {t("action.reset")}
                            </Buttons>
                            <Buttons
                                variant="outlined"
                                startIcon={<DeleteIcon />}
                                onClick={() => handleCloseModal()}
                                key="clear"
                            >
                                {t("action.cancel")}
                            </Buttons>
                        </Space>
                    </Col>
                </Row>
                <ModalThongTinNccKhachSan
                    isOpen={isShowNccKS}
                    onIsShow={onIsShow}
                    onSaves={handleChangeDataServiceKS}
                    action={action}
                    maNcc={maNcc}
                    lenNgLon={dataNhomNgLon.length}
                    tuNgay={tuNgay}
                    denNgay={denNgay}
                />
            </Modal>
        </>
    );
};
export default ModalCapNhatKS;
