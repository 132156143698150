import React, { useEffect, useState } from "react";
import { Table, Checkbox, Button, Popconfirm, Select, InputNumber } from "antd";
import { MenuItem, TextField } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

const TableServiceConfig = ({
    dataTyGia,
    dataService,
    setDataService,
    isTreEm,
    dmDoTuoi,
    dmDoTuoiId,
    onDeleteLoaiTien,
}) => {
    const { t } = useTranslation();
    const [groupData, setGroupData] = useState(isTreEm ? dataService[0]?.loaiTien || [] : dataService);
    const [doTuoiId, setDoTuoiId] = useState(dmDoTuoiId);

    const generateId = () => {
        return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
    };
    useEffect(() => {
        setGroupData(isTreEm ? dataService[0]?.loaiTien || [] : dataService);
    }, [groupData]);
    const handleValueChange = (groupKey, detailKey, childKey, newValue) => {
        const updatedData = groupData.map((group) => {
            if (group.id === groupKey) {
                const updatedRows = group.details.map((dt) => {
                    if (dt.key === detailKey) {
                        const updatedChildren = dt.children.map((child) =>
                            child.key === childKey ? { ...child, giaTri: newValue } : child
                        );
                        return { ...dt, children: updatedChildren };
                    }
                    return dt;
                });
                return { ...group, details: updatedRows };
            }
            return group;
        });
        if (isTreEm) {
            const newDataService = dataService.map((data) =>
                data.dmDoTuoiId === doTuoiId
                    ? {
                          ...data,
                          loaiTien: data.loaiTien.map((group) => {
                              if (group.id === groupKey) {
                                  const updatedRows = group.details.map((dt) => {
                                      if (dt.key === detailKey) {
                                          const updatedChildren = dt.children.map((child) =>
                                              child.key === childKey ? { ...child, giaTri: newValue } : child
                                          );
                                          return { ...dt, children: updatedChildren };
                                      }
                                      return dt;
                                  });
                                  return { ...group, details: updatedRows };
                              }
                              return group;
                          }),
                      }
                    : data
            );
            setDataService(newDataService);
        } else setDataService(updatedData);
        setGroupData(updatedData);
    };

    // Cập nhật giá trị Loại tiền cho cả nhóm
    const handleCurrencyChange = (id, event) => {
        let value = event.target.value;
        const updatedData = groupData.map((group) => (group.id === id ? { ...group, dmLoaiTienId: value } : group));
        if (isTreEm) {
            const newDataService = dataService.map((data) =>
                data.dmDoTuoiId === doTuoiId
                    ? {
                          ...data,
                          loaiTien: data.loaiTien.map((group) =>
                              group.id === id ? { ...group, dmLoaiTienId: value } : group
                          ),
                      }
                    : data
            );
            setDataService(newDataService);
        } else setDataService(updatedData);
        setGroupData(updatedData);
    };

    const handleApplyChange = (id, rowKey, checked) => {
        const updatedData = groupData.map((group) => {
            if (group.id === id) {
                const updatedRows = group.details.map((dt) => {
                    if (dt.key === rowKey) {
                        return {
                            ...dt,
                            apDung: checked,
                            children: dt.children.map((it) => ({ ...it, apDung: checked })),
                        };
                    }
                    return {
                        ...dt,
                        apDung: !checked,
                        children: dt.children.map((it) => ({ ...it, apDung: !checked })),
                    };
                });
                return { ...group, details: updatedRows };
            }
            return group;
        });
        if (isTreEm) {
            const newDataService = dataService.map((data) =>
                data.dmDoTuoiId === doTuoiId
                    ? {
                          ...data,
                          loaiTien: data.loaiTien.map((group) => {
                              if (group.id === id) {
                                  const updatedRows = group.details.map((dt) => {
                                      if (dt.key === rowKey) {
                                          return {
                                              ...dt,
                                              apDung: checked,
                                              children: dt.children.map((it) => ({ ...it, apDung: checked })),
                                          };
                                      }
                                      return {
                                          ...dt,
                                          apDung: !checked,
                                          children: dt.children.map((it) => ({ ...it, apDung: !checked })),
                                      };
                                  });
                                  return { ...group, details: updatedRows };
                              }
                              return group;
                          }),
                      }
                    : data
            );
            setDataService(newDataService);
        } else setDataService(updatedData);
        setGroupData(updatedData);
    };

    const handleDeleteGroup = (id, item) => {
        const updatedData = groupData.filter((group) => group.id !== id);
        if (isTreEm) {
            const newDataService = dataService.map((data) =>
                data.dmDoTuoiId === doTuoiId
                    ? { ...data, loaiTien: data.loaiTien.filter((group) => group.id !== id) }
                    : data
            );
            setDataService(newDataService);
        } else setDataService(updatedData);
        setGroupData(updatedData);
        if (item.groupId !== 0) {
            let bgCauHinhDoTuoiId = isTreEm
                ? dataService.find((data) => data.dmDoTuoiId === doTuoiId)?.bgCauHinhDoTuoiId || 0
                : 0;
            onDeleteLoaiTien(item.bgCauHinhLaiId, item.groupId, bgCauHinhDoTuoiId);
        }
    };

    const handleAddGroup = () => {
        let groupId = generateId();
        const newGroup = {
            id: groupId,
            bgCauHinhLaiId: groupData[0]?.bgCauHinhLaiId ? groupData[0]?.bgCauHinhLaiId : 0,
            bgCauHinhLoaiTienId: 0,
            dmLoaiTienId: 1,
            details: [
                {
                    key: "1",
                    type: "Percentage",
                    apDung: false,
                    children: [
                        {
                            key: "1-1",
                            groupId,
                            detailKey: "1",
                            tenDichVu: "Khách sạn",
                            dmDichVuId: 1,
                            kieuTinh: 0,
                            giaTri: 0,
                            apDung: false,
                        },
                        {
                            key: "1-2",
                            groupId,
                            detailKey: "1",
                            tenDichVu: "Nhà hàng",
                            dmDichVuId: 3,
                            kieuTinh: 0,
                            giaTri: 0,
                            apDung: false,
                        },
                        {
                            key: "1-3",
                            groupId,
                            detailKey: "1",
                            tenDichVu: "Oto",
                            dmDichVuId: 2,
                            kieuTinh: 0,
                            giaTri: 0,
                            apDung: false,
                        },
                    ],
                },
                {
                    key: "2",
                    type: "FixedAmount",
                    apDung: true,
                    children: [
                        {
                            key: "2-1",
                            groupId,
                            detailKey: "2",
                            tenDichVu: "Khách sạn",
                            dmDichVuId: 1,
                            kieuTinh: 1,
                            giaTri: 0,
                            apDung: true,
                        },
                        {
                            key: "2-2",
                            groupId,
                            detailKey: "2",
                            tenDichVu: "Nhà hàng",
                            dmDichVuId: 3,
                            kieuTinh: 1,
                            giaTri: 0,
                            apDung: true,
                        },
                        {
                            key: "2-3",
                            groupId,
                            detailKey: "2",
                            tenDichVu: "Oto",
                            dmDichVuId: 2,
                            kieuTinh: 1,
                            giaTri: 0,
                            apDung: true,
                        },
                    ],
                },
            ],
        };
        const newDataGroups = [...groupData, newGroup];
        if (isTreEm) {
            const newDataService = [...dataService];
            let idxUpdate = newDataService.findIndex((item) => item.dmDoTuoiId === doTuoiId);
            if (idxUpdate > -1) {
                newDataService[idxUpdate] = { bgCauHinhDoTuoiId: 0, dmDoTuoiId: doTuoiId, loaiTien: newDataGroups };
            } else {
                newDataService.push({ bgCauHinhDoTuoiId: 0, dmDoTuoiId: doTuoiId, loaiTien: newDataGroups });
            }
            setDataService(newDataService);
        } else setDataService(newDataGroups);
        setGroupData(newDataGroups);
    };

    const renderGroupTable = (group, index) => {
        const dataSource = group.details.flatMap((row, idx) => [
            {
                ...row,
                idx: idx,
                groupId: group.id,
                bgCauHinhLaiId: group.bgCauHinhLaiId,
                dmLoaiTienId: group.dmLoaiTienId,
                apDung: row.apDung,
                type: row.type,
                len: group.details.flatMap((row) => row.children.map((it) => ({ ...it }))).length,
            },
        ]);
        const columns = [
            {
                title: "Markup Type",
                dataIndex: "type",
                key: "type",
                render: (text, record) => {
                    if (record.children) {
                        return <strong>{text}</strong>;
                    }
                    return record.tenDichVu;
                },
                width: 150,
            },
            {
                title: "Markup Value",
                dataIndex: "giaTri",
                key: "giaTri",
                render: (text, record) =>
                    record.children ? null : (
                        <InputNumber
                            value={text}
                            onChange={(e) => handleValueChange(record.groupId, record.detailKey, record.key, e)}
                            style={{ width: "100%" }}
                            formatter={(value) =>
                                value
                                    ? `${parseFloat(value)
                                          .toFixed(2)
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                    : ""
                            }
                            parser={(value) => (value ? value.replace(/\$\s?|,/g, "") : "")}
                        />
                    ),
                width: 220,
            },
            {
                title: "Loại tiền",
                dataIndex: "dmLoaiTienId",
                key: "dmLoaiTienId",
                render: (_, record) => (
                    <TextField
                        select
                        size="small"
                        fullWidth
                        disabled={record.idx !== 0}
                        value={group.dmLoaiTienId}
                        onChange={(value) => handleCurrencyChange(record.groupId, value)}
                        // style={{ width: "100%" }}
                    >
                        {dataTyGia?.map((option) => (
                            <MenuItem key={option.maNgoaiTeId} value={option.maNgoaiTeId}>
                                {option.maNgoaiTe}
                            </MenuItem>
                        ))}
                    </TextField>
                ),
                width: 100,
            },
            {
                title: "Áp dụng",
                dataIndex: "apDung",
                key: "apDung",
                render: (apDung, record) => (
                    <Checkbox
                        checked={apDung}
                        disabled={!record.children}
                        onChange={(e) => handleApplyChange(record.groupId, record.key, e.target.checked)}
                    />
                ),
                width: 100,
            },
            {
                title: "Thao tác",
                dataIndex: "action",
                key: "action",
                render: (_, record) => {
                    if (record.idx === 0) {
                        return {
                            children: (
                                <Popconfirm
                                    title={t("deleteTheTask")}
                                    description={t("areYouConfirm")}
                                    okText={t("delete")}
                                    cancelText={t("huy")}
                                    onConfirm={() => handleDeleteGroup(record.groupId, record)}
                                >
                                    <Delete fontSize="small" />
                                </Popconfirm>
                            ),
                            props: { rowSpan: record.len + 2 },
                        };
                    } else {
                        return { props: { rowSpan: 0 } };
                    }
                },
                width: 100,
            },
        ];

        return (
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                showHeader={index === 0}
                bordered={index === 0}
                rowKey="key"
                expandable={{
                    expandRowByClick: false,
                }}
                style={{ marginBottom: "20px" }}
            />
        );
    };

    const handleChangeDoTuoi = (value) => {
        const groupDataNew = dataService?.find((item) => item.dmDoTuoiId === value)?.loaiTien || [];
        setDoTuoiId(value);
        setGroupData(groupDataNew);
    };

    return (
        <>
            {isTreEm ? (
                <Select
                    value={doTuoiId}
                    onChange={(e) => handleChangeDoTuoi(e)}
                    style={{ width: "30%", display: "block", marginBottom: "10px" }}
                >
                    {dmDoTuoi.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                            {item.do_tuoi}
                        </Select.Option>
                    ))}
                </Select>
            ) : null}
            {groupData.map((group, index) => renderGroupTable(group, index))}
            <Button type="dashed" onClick={handleAddGroup} style={{ marginTop: "10px" }}>
                + Thêm loại tiền
            </Button>
        </>
    );
};

export default TableServiceConfig;
