import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import {
  AppstoreOutlined,
  ContainerOutlined,
  UserOutlined,
  // MailOutlined,
  ShopOutlined,
  // DashboardOutlined,
} from "@ant-design/icons";
// import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import AddchartIcon from "@mui/icons-material/Addchart";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {Link, useLocation} from "react-router-dom";
import "../assets/css/containers/slidebar.css";
import { useTranslation } from "react-i18next";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {key_crypt, key_menu, ROLE_MANAGER} from "../constants";
// import ncc from "../components/supplier/Ncc";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CryptoJS from "crypto-js";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const MenuSide = ({ SendKeySide }) => {
  const { t } = useTranslation();
  const location = useLocation()
  const [selectedKey, setSelectedKey] = useState('1')
  const [views, setViews] = useState([
    getItem(

      <Link to="" rel="noopener noreferrer">
        SMART TOUR
      </Link>,
      1,
      <ContainerOutlined />
    ),
    {
      type: "divider",
    },
    getItem(

      <Link to="" rel="noopener noreferrer">
        {localStorage.getItem("USERNAME")}
      </Link>,
      "2",
      <UserOutlined />
    ),
    {
      type: "divider",
    },
  ]);
  const roleMenus = {
    [ROLE_MANAGER["DIRECTOR"]]: [
      getItem(t("category"), "3", <ShopOutlined />, [
        getItem(
          <Link to="/dm/ti-gia" rel="noopener noreferrer">
            {t("exchangeRate")}
          </Link>,
          "4"
        ),
        getItem(
          <Link to="/dm/loai-hinh-dl" rel="noopener noreferrer">
            {t("typeTourism")}
          </Link>,
          "5"
        ),
        getItem(
          <Link to="/dm/loai-tour" rel="noopener noreferrer">
            {t("typeTour")}
          </Link>,
          "6"
        ),
        getItem(
          <Link to="/dm/dia-diem" rel="noopener noreferrer">
            {t("location")}
          </Link>,
          "7"
        ),
      ]),
      getItem(
        <Link to="/ncc">{t("supplier")} </Link>,
        "8",
        <AppstoreOutlined />
      ),
      getItem(
          <Link to="/tour-san-pham">{t("tourSanPham")} </Link>,
          "10",
          <ShopOutlined />
      ),
      // getItem(t("service"), "9", <CollectionsBookmarkIcon />, [
      //   getItem(
      //     <Link to="/dich-vu/tour-san-pham" rel="noopener noreferrer">
      //       {t("tourSanPham")}
      //     </Link>,
      //     "10"
      //   ),
      //   getItem(
      //     <Link to="/dich-vu/tour-mo-ban" rel="noopener noreferrer">
      //       {t("tourMoBan")}
      //     </Link>,
      //     "11"
      //   ),
      // ]),
      getItem(
        <Link to="/bao-gia">{t("baoGia")} </Link>,
        "12",
        <AssignmentIcon />
      ),
      getItem(
        <Link to="/dat-dich-vu">{t("booking")} </Link>,
        "13",
        <AddchartIcon />
      ),
      getItem(
        <Link to="/dieu-hanh">{t("serviceManagement")} </Link>,
        "14",
        <ManageAccountsIcon />
      ),
      getItem(
        <Link to="/ql-nguoi-dung">{t("userManagement")} </Link>,
        "15",
        <SupervisorAccountIcon />
      ),

    ],
    [ROLE_MANAGER["ROLE_ADMIN"]]: [
      getItem(t("category"), "3", <ShopOutlined />, [
        getItem(
            <Link to="/dm/ti-gia" rel="noopener noreferrer">
              {t("exchangeRate")}
            </Link>,
            "4"
        ),

        getItem(
            <Link to="/dm/loai-hinh-dl" rel="noopener noreferrer">
              {t("typeTourism")}
            </Link>,
            "5"
        ),
        getItem(
            <Link to="/dm/loai-tour" rel="noopener noreferrer">
              {t("typeTour")}
            </Link>,
            "6"
        ),
        getItem(
            <Link to="/dm/dia-diem" rel="noopener noreferrer">
              {t("location")}
            </Link>,
            "7"
        ),
      ]),
      getItem(
          <Link to="/ncc">{t("supplier")} </Link>,
          "8",
          <AppstoreOutlined />
      ),
      getItem(
          <Link to="/tour-san-pham">{t("tourSanPham")} </Link>,
          "10",
          <ShopOutlined />
      ),
      // getItem(t("service"), "9", <CollectionsBookmarkIcon />, [
      //   getItem(
      //       <Link to="/dich-vu/tour-san-pham" rel="noopener noreferrer">
      //         {t("tourSanPham")}
      //       </Link>,
      //       "10"
      //   ),
      //   getItem(
      //       <Link to="/dich-vu/tour-mo-ban" rel="noopener noreferrer">
      //         {t("tourMoBan")}
      //       </Link>,
      //       "11"
      //   ),
      // ]),
      getItem(
          <Link to="/bao-gia">{t("baoGia")} </Link>,
          "12",
          <AssignmentIcon />
      ),
      getItem(
          <Link to="/dat-dich-vu">{t("booking")} </Link>,
          "13",
          <AddchartIcon />
      ),
      getItem(
          <Link to="/dieu-hanh">{t("serviceManagement")} </Link>,
          "14",
          <ManageAccountsIcon />
      ),
      getItem(
          <Link to="/ql-nguoi-dung">{t("userManagement")} </Link>,
          "15",
          <SupervisorAccountIcon />
      ),
      getItem(
          <Link to="/ke-toan">{t("keToan")} </Link>,
          "16",
          <AccountBalanceIcon />
      ),
    ],
    [ROLE_MANAGER["SYSTEM_MANAGER"]]: [
      // Define menu items specific to SYSTEM_MANAGER
      getItem(t("category"), "3", <ShopOutlined />, [
        getItem(
          <Link to="/dm/ti-gia" rel="noopener noreferrer">
            {t("exchangeRate")}
          </Link>,
          "4"
        ),
        getItem(
          <Link to="/dm/loai-hinh-dl" rel="noopener noreferrer">
            {t("typeTourism")}
          </Link>,
          "5"
        ),
        getItem(
          <Link to="/dm/loai-tour" rel="noopener noreferrer">
            {t("typeTour")}
          </Link>,
          "6"
        ),
        getItem(
          <Link to="/dm/dia-diem" rel="noopener noreferrer">
            {t("location")}
          </Link>,
          "7"
        ),
      ]),
    ],
    [ROLE_MANAGER["SALE"]]: [
      // Define menu items specific to SALE
      // getItem(t("service"), "9", <ShopOutlined />, [
      //   getItem(
      //     <Link to="/dich-vu/tour-san-pham" rel="noopener noreferrer">
      //       {t("tourSanPham")}
      //     </Link>,
      //     "10"
      //   ),
      //   getItem(
      //     <Link to="/dich-vu/tour-mo-ban" rel="noopener noreferrer">
      //       {t("tourMoBan")}
      //     </Link>,
      //     "11"
      //   ),
      // ]),
      getItem(
          <Link to="/tour-san-pham">{t("tourSanPham")} </Link>,
          "10",
          <ShopOutlined />
      ),
      getItem(
        <Link to="/bao-gia">{t("baoGia")} </Link>,
        "12",
        <AssignmentIcon />
      ),
      getItem(
        <Link to="/dat-dich-vu">{t("booking")} </Link>,
        "13",
        <AddchartIcon />
      ),
    ],
    [ROLE_MANAGER["EXCUTIVE_MANAGER"]]: [
      getItem(
        <Link to="/ncc">{t("supplier")} </Link>,
        "8",
        <AppstoreOutlined />
      ),
      getItem(
        <Link to="/dieu-hanh">{t("serviceManagement")} </Link>,
        "14",
        <ManageAccountsIcon />
      ),
    ],
    [ROLE_MANAGER["ACCOUNTANT"]]: [
      getItem(
          <Link to="/ke-toan">{t("keToan")} </Link>,
          "16",
          <AccountBalanceIcon />
      ),
    ],
    [ROLE_MANAGER["OPERATOR"]]: [
      // Define menu items specific to OPERATOR
      getItem(
        <Link to="/ncc">{t("supplier")} </Link>,
        "8",
        <AppstoreOutlined />
      ),
      getItem(
        <Link to="/dieu-hanh">{t("serviceManagement")} </Link>,
        "14",
        <ManageAccountsIcon />
      ),
      getItem(
          <Link to="/ke-toan">{t("keToan")} </Link>,
          "16",
          <AccountBalanceIcon />
      ),
    ],
  };

  useEffect(() => {
    let auth_de = localStorage.getItem("auth");
    if (auth_de !== undefined) {
      let auth = JSON.parse(CryptoJS.AES.decrypt(auth_de, key_crypt).toString(CryptoJS.enc.Utf8));
      let roles = auth.roles;
      const items = [...views];
      if (roles != null) {
        roles.forEach((role) => {
          const roleMenu = roleMenus[role.authority];
          if (roleMenu) {
            roleMenu.forEach((menuItem) => {
              if (!items.some((item) => item.key === menuItem.key)) {
                items.push(menuItem);
              }
            });
          }
        });
        setViews(items);
      }
    }
    if (location.pathname.length > 0) {
      let path = location.pathname.split("/");
      for (let i = 1; i < 2; i++) {
        setSelectedKey(key_menu[path[i]])
      }
    }

  }, [location]);

  const onClick = (e) => {
    SendKeySide(e.key, e.keyPath);
  };
  return (
    <Menu
      // selectedKeys={[selectedKey]}
      defaultSelectedKeys={[selectedKey]}
      defaultOpenKeys={[selectedKey]}
      mode="inline"
      theme="dark"
      items={views}
      onClick={onClick}
    />
  );
};

export default MenuSide;
