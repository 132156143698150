import { CloseCircleFilled } from "@ant-design/icons";
import BorderColorOutlined from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Checkbox, FormControl, FormControlLabel, Link, MenuItem, TextField, Button } from "@mui/material";
// import { DataGrid, enUS, viVN } from "@mui/x-data-grid";
import { Col, Divider, Modal, Row, Space, Table, Tooltip, notification } from "antd";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import dm_gia_ap_dung from "../../../../api/category/dm_gia_ap_dung";
import ModalThongTinNccNhaHang from "./modalNcc/ModalThongTinNccNhaHang";
import nhaHangService from "../../../../api/bao_gia/sale_rate/nha_hang/nhaHangService";
import { TIME_DURATION } from "../../../../constants";
import "./ModalCapNhat.css";
const ModalCapNhatNhaHang = ({
    isOpen,
    onReset,
    handleCancel,
    dataNhomNgLon,
    dataNhomTreEm,
    baoGiaHanhTrinhId,
    baoGiaTongQuanId,
    bgChietTinhSaleId,
    dmDichVuId,
    bgMapCTSaleHtId,
    soLuongTreEmBG,
    hanhDong,
    soNgay,
    ngay,
    bgHanhTrinhs,
    tuNgay,
    denNgay,
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [dmGia, setDmGia] = useState([]);
    const { t } = useTranslation();
    const [dmLoaiApDung, setDmLoaiApDung] = useState([]);
    const [dmVat, setDmVat] = useState([]);
    const [dmDoTuoi, setDmDoTuoi] = useState([]);
    const [dmBuaAn, setDmBuaAn] = useState([]);
    const [isShowNccNH, setIsShowNccNH] = useState(false);
    const [dataSource, setDataSource] = useState({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        moTa: "",
        ngayApDungs: [],
    });
    const [dataSourceInit, setDataSourceInit] = useState({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        moTa: "",
        ngayApDungs: [],
    });
    const [dichVus, setDichVus] = useState([{ id: "NULL" }]);
    const [dichVusInit, setDichVusInit] = useState([{ id: "NULL" }]);
    const [dichVuTreEms, setDichVuTreEms] = useState([]);
    const [dichVuTreEmsInit, setDichVuTreEmsInit] = useState([]);
    const [action, setAction] = useState("");
    const [idxEdit, setIdxEdit] = useState(0);
    const [countDichVu, setCountDichVu] = useState(0);
    const [reset, setReset] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    // const [listNgay, setListNgay] = useState([]);
    const [maNcc, setMaNcc] = useState("");
    const prevCountDichVu = useRef();
    // const soLuongTreEmBG = 5;
    // const dataNhomTreEm = [
    //   {
    //     id: 13,
    //     nhomNguoiLonId: 13,
    //     maNhom: "",
    //     tenNhom: "tre 1",
    //     details: [
    //       {
    //         soLuong: 1,
    //         dmDoTuoiId: 1,
    //       },
    //       {
    //         soLuong: 1,
    //         dmDoTuoiId: 2,
    //       },
    //     ],
    //   },
    // ];

    // const dataNhomNgLon = [
    //   {
    //     id: 13,
    //     maNhomTreEm: [],
    //     tenNhom: "ngl 1",
    //     foc: 3,
    //     soLuong: 3,
    //   },
    //   {
    //     id: 16,
    //     maNhomTreEm: [],
    //     tenNhom: "ngl 2",
    //     foc: 3,
    //     soLuong: 3,
    //   },
    // ];
    useEffect(() => {
        getDmGiaApDung();
        getDmLoaiApDung();
        getDmVat();
        getDmDoTuoi();
        getDmBuaAn();
    }, []);
    useEffect(() => {
        prevCountDichVu.current = countDichVu;
    }, [countDichVu]);

    useEffect(() => {
        if (hanhDong === "EDIT" && isOpen) {
            getChietTinhSaleNhaHang({ bgMapCTSaleHtId: bgMapCTSaleHtId });
        }
    }, [hanhDong, bgMapCTSaleHtId, isOpen]);

    useEffect(() => {
        if (hanhDong === "ADD") {
            setDichVus([{ id: "NULL" }]);
            setDichVusInit([{ id: "NULL" }]);
            setDataSource({
                tenNcc: "",
                dmGiaApDungId: 2,
                dmVatId: 1,
                moTa: "",
                ngayApDungs: [],
            });
            setDataSourceInit({
                tenNcc: "",
                dmGiaApDungId: 2,
                dmVatId: 1,
                moTa: "",
                ngayApDungs: [],
            });
            setDichVuTreEms([]);
            setDichVuTreEmsInit([]);
        }
    }, [isOpen]);

    const chiPhiTreEm = useMemo(() => {
        return dichVuTreEms
            .filter((it) => it.apDung)
            .reduce((sum, item) => {
                if (!item.id.includes("addNew") && !isNaN(item.thanhTien)) sum += parseFloat(item.thanhTien);
                return sum;
            }, 0);
    }, [dichVuTreEms]);

    const chiPhiNguoiLon = useMemo(() => {
        return dichVus.reduce((sum, item) => {
            if (item.id !== "NULL" && !isNaN(item.tong)) sum += parseFloat(item.tong);
            return sum;
        }, 0);
    }, [dichVus]);

    const tongChiPhi = chiPhiTreEm + chiPhiNguoiLon;

    let formatter = new Intl.NumberFormat("en-US", {});

    const generateId = () => {
        return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
    };

    const getChietTinhSaleNhaHang = (id) => {
        nhaHangService
            .getChietTinhSaleNhaHang(id)
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let ds = {
                        nccThongTinChungId: data[0].nccThongTinChungId,
                        tenNcc: data[0].tenNhaCungCap,
                        ngayApDungs: data[0].ngayApDungs,
                        dmGiaApDungId: data[0].dmGiaApDungId,
                        dmLoaiApDung: data[0].dmLoaiApDungId,
                        dmTyGiaId: data[0].dmTyGiaId,
                        dmVatId: data[0].dmVatId,
                        moTa: data[0].moTa,
                    };
                    let lstDichVu = data[0]?.dichVuNguoiLons.map((it, idx) => {
                        return {
                            id: idx + 1,
                            tranxBgChietTinhNhId: it.tranxBgChietTinhNhId,
                            nccDichVuNhaHangId: it.nccDichVuNhaHangId,
                            tranxNhaHangId: it.tranxNhaHangId,
                            bgNhomNgLonId: it.bgNhomNguoiLonId,
                            tenDichVu: it.dichVu,
                            dmTimeId: it.dmBuaAnId,
                            suat: it.dieuKienFoc,
                            foc: it.foc,
                            giaNet: it.donGia,
                            soLuong: it.soLuong,
                            tong: it.donGia * (it.soLuong - parseInt(it.soLuong / it.dieuKienFoc) * it.foc),
                        };
                    });
                    lstDichVu.push({ id: "NULL" });

                    let dichVuTEs =
                        data[0]?.dichVuNguoiLons?.flatMap((dv) => {
                            let giaDV = dv.donGia;
                            let numDV = 0;
                            const dichVuTreEms =
                                data[1]?.dichVuTreEms?.filter(
                                    (te) => te.tranxBgChietTinhNhId === dv.tranxBgChietTinhNhId
                                ) || [];
                            let rowSpanDV = dichVuTreEms.reduce((num, item) => (num += item.details.length), 0);
                            let dichVuTEs =
                                dichVuTreEms.flatMap(
                                    (te) =>
                                        te.details.map((dt, idx) => {
                                            giaDV = dt.giaDichVu;
                                            return {
                                                id: generateId(),
                                                tenDichVu: te.tenDichVu,
                                                tenNhomTE: dt.tenNhomTreEm,
                                                giaNet: dt.giaDichVu,
                                                thanhTien: (dt.phanTram / 100) * dt.soLuong * dt.giaDichVu,
                                                bgNhomTreEmId: dt.bgNhomTreEmId,
                                                tranxNhaHangId: dv.tranxNhaHangId,
                                                nccDichVuNhaHangId: dv.nccDichVuNhaHangId,
                                                bgNhomNgLonId: dv.bgNhomNguoiLonId,
                                                rowSpanDV:
                                                    numDV++ === 0
                                                        ? dataNhomTreEm.length === 0
                                                            ? rowSpanDV + 1
                                                            : rowSpanDV
                                                        : 0,
                                                rowSpanTE: idx === 0 ? te.details.length : 0,
                                                ...dt,
                                            };
                                        }) || []
                                ) || [];

                            if (dataNhomTreEm.length === 0) {
                                dichVuTEs.push({
                                    id: "addNew" + generateId(),
                                    tenDichVu: dv.dichVu,
                                    giaNet: giaDV,
                                    tranxNhaHangId: dv.tranxNhaHangId,
                                    nccDichVuNhaHangId: dv.nccDichVuNhaHangId,
                                    rowSpanDV: dichVuTEs.length === 0 ? 1 : 0,
                                });
                            }

                            return dichVuTEs;
                        }) || [];
                    let checkAll = dichVuTEs.filter((te) => !te.id.includes("addNew")).every((dt) => dt.apDung);

                    setCheckAll(checkAll);
                    setCountDichVu(lstDichVu.length);
                    setDichVus(lstDichVu);
                    setDichVusInit(JSON.parse(JSON.stringify(lstDichVu)));
                    setDataSource(ds);
                    setDataSourceInit({ ...ds });
                    setDichVuTreEms(dichVuTEs);
                    setDichVuTreEmsInit(JSON.parse(JSON.stringify(dichVuTEs)));
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmGiaApDung = () => {
        dm_gia_ap_dung
            .getDmGiaApDung()
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    console.log("priceApply", data);

                    setDmGia(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmVat = () => {
        dm_gia_ap_dung
            .getDmVat()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmVat(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmDoTuoi = () => {
        dm_gia_ap_dung
            .getDmDoTuoi()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmDoTuoi(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmBuaAn = () => {
        dm_gia_ap_dung
            .getDmBuaAn()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmBuaAn(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmLoaiApDung = () => {
        dm_gia_ap_dung
            .getDmLoaiApDung()
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    setDmLoaiApDung(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const handleUpdateService = (record) => {
        let index = dichVus.findIndex((item) => item.id === record.id);
        setIsShowNccNH(true);
        setAction("UPDATE_SERVICE");
        setIdxEdit(index);
    };

    const handleUpdateNCC = () => {
        setMaNcc("");
        setIsShowNccNH(true);
        setAction("UPDATE_NCC");
    };

    const handleAddService = () => {
        // if (
        //   dataNhomNgLon.length > 0 &&
        //   dichVus.length - 1 === dataNhomNgLon.length
        // ) {
        //   showNoti("error", t("noti.errorMaxServices"));
        // } else {
        if (dichVus.length === 1) setMaNcc("");
        setIsShowNccNH(true);
        setAction("ADD_SERVICE");
        // }
    };

    const onChangeEleDichVu = (id, event) => {
        let index = dichVus.findIndex((item) => item.id === id);
        let name = event.target.name;
        dichVus[index][name] = event.target.value;
        if (event.target.value < 0 && (name === "soLuong" || name === "suat" || name === "foc"))
            dichVus[index][name] = 0;
        if (event.target.name === "soLuong")
            dichVus[index].tong =
                dichVus[index].giaNet *
                (dichVus[index].soLuong - parseInt(dichVus[index].soLuong / dichVus[index].suat) * dichVus[index].foc);
        setDichVus([...dichVus]);
    };

    const onChangeEleDichVuTreEm = (id, event, type) => {
        let dichVus = [];
        let dichVuApDung = [];
        if (type === "ALL") {
            dichVuApDung = dichVuTreEms.filter((item) => !item.id.includes("addNew") && item.apDung);
            dichVus = dichVuTreEms.filter((item) => !item.id.includes("addNew"));
            if (dichVuApDung.length > 0 && dichVuApDung.length === dichVus.length) {
                dichVus = dichVuTreEms.map((it) => {
                    if (!it.id.includes("addNew")) it.apDung = false;
                    return it;
                });
                setCheckAll(false);
            } else {
                dichVus = dichVuTreEms.map((it) => {
                    if (!it.id.includes("addNew")) it.apDung = true;
                    return it;
                });
                setCheckAll(true);
            }
            setDichVuTreEms(dichVus);
        } else {
            let index = dichVuTreEms.findIndex((item) => item.id === id);
            if (event.target.name === "apDung") {
                dichVuTreEms[index][event.target.name] = event.target.checked;
            } else {
                dichVuTreEms[index][event.target.name] = event.target.value;
                if (event.target.name === "soLuong" || event.target.name === "phanTram") {
                    if (event.target.value < 0) {
                        dichVuTreEms[index][event.target.name] = 0;
                    }
                    dichVuTreEms[index].thanhTien =
                        dichVuTreEms[index].giaNet * dichVuTreEms[index].soLuong * (dichVuTreEms[index].phanTram / 100);
                }
            }
            dichVuApDung = dichVuTreEms.filter((item) => !item.id.includes("addNew") && item.apDung);
            dichVus = dichVuTreEms.filter((item) => !item.id.includes("addNew"));
            if (dichVuApDung.length > 0 && dichVuApDung.length === dichVus.length) setCheckAll(true);
            else setCheckAll(false);
            setDichVuTreEms([...dichVuTreEms]);
        }
    };

    const handleChangeValueDataSoure = (e) => {
        dataSource[e.target.name] = e.target.value;
        if (e.target.name === "dmGiaApDungId") dataSource.ngayApDungs = [];
        setReset(!reset);
    };

    const handleDeleteService = (record) => {
        let index = dichVus.findIndex((item) => item.id === record.id);
        let lstDichVu = JSON.parse(JSON.stringify(dichVus));
        lstDichVu.splice(index, 1);
        let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
        if (dataNhomNgLon.length === 0) {
            if (
                !lstDichVu.find(
                    (it) =>
                        it.nccDichVuNhaHangId === record.nccDichVuNhaHangId &&
                        it.tranxNhaHangId === record.tranxNhaHangId
                )
            )
                lstDichVuTreEm = lstDichVuTreEm.filter(
                    (it) =>
                        !(
                            it.nccDichVuNhaHangId === record.nccDichVuNhaHangId &&
                            it.tranxNhaHangId === record.tranxNhaHangId
                        )
                );
        } else {
            lstDichVuTreEm = dichVuTreEms.filter((item) => item.bgNhomNgLonId !== dichVus[index].bgNhomNgLonId);
        }
        setDichVuTreEms(lstDichVuTreEm);
        setDichVus(lstDichVu);
        setCountDichVu(lstDichVu.length);
    };

    const handleChangeNhomNgLon = (id, event) => {
        let index = dichVus.findIndex((item) => item.id === id);
        let name = event.target.name;
        let value = event.target.value;
        // if (!dichVus.find((it) => it.bgNhomNgLonId === value)) {
        if (dataNhomTreEm.length > 0) {
            let tenNhomNgLon = dataNhomNgLon.find((it) => it.id === value)?.tenNhom;
            dichVus[index][name] = value;
            dichVus[index].tenNhomNgLon = tenNhomNgLon;
            let dichVusByNgLon = dichVus.filter((it) => it.bgNhomNgLonId !== -1);
            let idx = 0;
            let idxCheckRowSpanTE = 1;
            let lstDichVuTreEm = [];
            let idxCheckRowSpanDV = 1;
            for (let i = 0; i < dichVusByNgLon.length - 1; i++) {
                let nhomTreEms = dataNhomTreEm.filter(
                    (item) => item.nhomNguoiLonId === dichVusByNgLon[i].bgNhomNgLonId
                );
                let lenDataTE = nhomTreEms.reduce((num, item) => {
                    item.details.forEach(() => {
                        num++;
                    });
                    return num;
                }, 0);
                for (let j = 0; j < nhomTreEms.length; j++) {
                    let element = nhomTreEms[j];
                    let dichVuTEs = element.details?.map((item) => {
                        idx++;
                        return {
                            id: generateId(),
                            tenDichVu: dichVusByNgLon[i].tenDichVu,
                            tenNhomTE: element.tenNhom,
                            phanTram: 0,
                            giaNet: dichVusByNgLon[i].giaNet,
                            soLuong: item.soLuong,
                            thanhTien: 0,
                            nccDichVuNhaHangId: dichVusByNgLon[i].nccDichVuNhaHangId,
                            tranxNhaHangId: dichVusByNgLon[i].tranxNhaHangId,
                            bgNhomTreEmId: element.id,
                            bgNhomNgLonId: dichVusByNgLon[i].bgNhomNgLonId,
                            dmDoTuoiId: item.dmDoTuoiId,
                            phanTram: 0,
                            apDung: false,
                            rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                            rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
                        };
                    });
                    idxCheckRowSpanTE += element.details.length;
                    lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEs];
                }
                idxCheckRowSpanDV += lenDataTE;
            }
            setDichVuTreEms(lstDichVuTreEm);
            setDichVus([...dichVus]);
        }
        // } else {
        //   showNoti("error", t("noti.errorNhomNgLonSelected"));
        // }
    };

    const handleAddServiceTreEm = (row) => {
        let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
        let index = lstDichVuTreEm.findIndex((it) => it.id === row.id);
        const newTreEm = {
            id: generateId(),
            tenDichVu: row.tenDichVu,
            giaNet: row.giaNet,
            nccDichVuNhaHangId: row.nccDichVuNhaHangId,
            tranxNhaHangId: row.tranxNhaHangId,
            thanhTien: 0,
            phanTram: 0,
            soLuong: 0,
            bgNhomTreEmId: 0,
            dmDoTuoiId: 1,
            apDung: false,
            rowSpanDV: row.rowSpanDV === 0 ? 0 : row.rowSpanDV,
            rowSpanTE: 0,
        };
        lstDichVuTreEm[index].rowSpanDV = 0;
        lstDichVuTreEm.splice(index, 0, newTreEm);
        let idxFirst = lstDichVuTreEm.findIndex(
            (item) => item.nccDichVuNhaHangId === row.nccDichVuNhaHangId && item.tranxNhaHangId === row.tranxNhaHangId
        );
        lstDichVuTreEm[idxFirst].rowSpanDV += 1;
        setDichVuTreEms(lstDichVuTreEm);
    };

    const handleDeleteServiceTreEm = (row) => {
        let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
        let index = lstDichVuTreEm.findIndex((it) => it.id === row.id);
        lstDichVuTreEm.splice(index, 1);
        let idxFirst = lstDichVuTreEm.findIndex(
            (item) => item.nccDichVuNhaHangId === row.nccDichVuNhaHangId && item.tranxNhaHangId === row.tranxNhaHangId
        );
        if (row.rowSpanDV === 0) lstDichVuTreEm[idxFirst].rowSpanDV -= 1;
        else {
            lstDichVuTreEm[idxFirst].rowSpanDV = row.rowSpanDV - 1;
        }
        setDichVuTreEms(lstDichVuTreEm);
    };

    const handleSave = (e) => {
        let rsCheck = handleValidateData();
        if (rsCheck.check) {
            let dataReq = getDataReq();
            console.log(dataReq);
            console.log(JSON.stringify(dataReq));
            nhaHangService
                .saveChietTinhSaleNhaHang(dataReq)
                .then((response) => {
                    let action = "add";
                    if (bgMapCTSaleHtId !== 0) action = "edit";
                    if (response.status === 200 && response.data.code === "00") {
                        showNoti("success", t(action) + " " + t("noti.success").toLowerCase());
                        setDataSourceInit({ ...dataSource });
                        setDichVusInit(JSON.parse(JSON.stringify(dichVus)));
                        setDichVuTreEmsInit(JSON.parse(JSON.stringify(dichVuTreEms)));
                        handleCancel();
                        onReset();
                    } else {
                        showNoti("error", response.data.message);
                    }
                })
                .catch(() => {
                    showNoti("error", t("system.error"));
                });
        } else {
            showNoti("error", rsCheck.message);
        }
    };

    const showNoti = (type, message) => {
        return api[type]({
            message: t("stour"),
            description: t(message),
            duration: TIME_DURATION,
        });
    };

    const getDataReq = () => {
        let ngayApDungs = [];
        if (ngay !== 0) {
            ngayApDungs =
                bgHanhTrinhs
                    ?.filter((item) => {
                        if (dataSource.dmGiaApDungId === 1) return item.ngay_order >= ngay;
                        else return item.ngay_order === ngay;
                    })
                    .map((item) => item.ngay_order) || [];
        }
        let dataReq = {
            ngay: ngay,
            baoGiaHanhTrinhId: baoGiaHanhTrinhId,
            baoGiaTongQuanId: baoGiaTongQuanId,
            ngayApDungs: ngay === 0 ? dataSource.ngayApDungs : ngayApDungs,
            bgCTSaleId: bgChietTinhSaleId,
            bgMapCTSaleHtId: bgMapCTSaleHtId ? bgMapCTSaleHtId : 0, //thêm mới, >0 chỉnh sửa
            nccThongTinChungId: dataSource.nccThongTinChungId,
            tenNcc: dataSource.tenNcc,
            dmDichVuId: dmDichVuId,
            dmGiaApDungId: dataSource.dmGiaApDungId,
            dmTyGiaId: dataSource.dmTyGiaId,
            dieuKienFoc: 0,
            foc: 0,
            moTa: dataSource.moTa,
            dmVatId: dataSource.dmVatId,
            dichVus: dichVus
                .filter((dt) => dt.id !== "NULL")
                .map((it) => {
                    return {
                        dmVatId: dataSource.dmVatId,
                        bgNhomNguoiLonId: it.bgNhomNgLonId,
                        soLuong: it.soLuong,
                        nccDichVuNhaHangId: it.nccDichVuNhaHangId,
                        tenDichVu: it.tenDichVu,
                        dmBuaAnId: it.dmTimeId,
                        tranxNhaHangId: it.tranxNhaHangId,
                        giaNhaHangNhapTay: 0,
                        dieuKienFoc: it.suat,
                        foc: it.foc,
                        dichVuTreEms: handleGroupDichVuTreEm(
                            it.nccDichVuNhaHangId,
                            it.tranxNhaHangId,
                            it.bgNhomNgLonId
                        ),
                    };
                }),
        };
        return dataReq;
    };

    const handleGroupDichVuTreEm = (nccDichVuNhaHangId, tranxNhaHangId, bgNhomNgLonId) => {
        let lstDichVuTreEm =
            dataNhomNgLon.length === 0
                ? dichVuTreEms.filter(
                      (it) =>
                          !it.id.includes("addNew") &&
                          it.nccDichVuNhaHangId === nccDichVuNhaHangId &&
                          it.tranxNhaHangId === tranxNhaHangId
                  )
                : dichVuTreEms.filter(
                      (it) =>
                          !it.id.includes("addNew") &&
                          it.nccDichVuNhaHangId === nccDichVuNhaHangId &&
                          it.tranxNhaHangId === tranxNhaHangId &&
                          it.bgNhomNgLonId === bgNhomNgLonId
                  );
        let groupDichVuTreEm = Object.groupBy(lstDichVuTreEm, ({ bgNhomTreEmId }) => bgNhomTreEmId);
        let keyGroupDichVuTreEm = Object.keys(groupDichVuTreEm);
        let dichVuTEs = keyGroupDichVuTreEm.map((key) => {
            return {
                bgNhomTreEmId: key,
                details: groupDichVuTreEm[key]?.map((it) => {
                    return {
                        dmVatId: dataSource.dmVatId,
                        dmDoTuoiId: it.dmDoTuoiId,
                        tranxNhaHangId: it.tranxNhaHangId,
                        phanTram: it.phanTram,
                        donGiaNhapTay: 0,
                        soLuong: it.soLuong,
                        apDung: it.apDung,
                    };
                }),
            };
        });
        return dichVuTEs;
        // console.log(groupDichVuTreEm);
        // console.log(dichVuTEs);
    };

    const handleValidateData = () => {
        let result = { check: true, message: "" };
        // let soLuongTE = dichVuTreEms.reduce((sum, item) => {
        //   if (!item.id.includes("addNew")) sum += parseInt(item.soLuong);
        //   return sum;
        // }, 0);
        // if (soLuongTE > soLuongTreEmBG) {
        //   result.check = false;
        //   result.message = t("noti.errorSoLuongTE");
        // } else
        if (ngay === 0 && dataSource.ngayApDungs.length === 0) {
            result.check = false;
            result.message = t("noti.errorNgayApDungs");
        } else if (!dichVus || dichVus.length <= 1) {
            result.check = false;
            result.message = t("noti.saveWarning");
        }
        // else if (dataNhomNgLon.length > 0 && bgNhomNguoiLonId === 0) {
        //   result.check = false;
        //   result.message = t("noti.errorNhomNgLon");
        // }
        return result;
    };

    const columnServices = [
        {
            title: t("service"),
            dataIndex: "tenDichVu",
            key: "tenDichVu",
            width: "15%",
            align: "center",
            visible: true,
            render: (_, record) => {
                if (record.id === "NULL") {
                    return {
                        children: (
                            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                <Link onClick={() => handleAddService()} underline="none">
                                    {t("addService")}
                                </Link>
                            </Box>
                        ),
                        props: {
                            colSpan: 8,
                        },
                    };
                }
                return (
                    <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={record.tenDichVu}
                        InputProps={{
                            endAdornment: (
                                <>
                                    <BorderColorOutlined
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        onClick={() => handleUpdateService(record)}
                                    />
                                    <CloseCircleFilled
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        onClick={() => handleDeleteService(record)}
                                    />
                                </>
                            ),
                        }}
                    />
                );
            },
        },
        {
            title: t("eatTime"),
            dataIndex: "dmTimeId",
            key: "dmTimeId",
            width: "10%",
            align: "center",
            visible: true,
            render: (_, record) => {
                if (record.id === "NULL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return (
                    <TextField
                        value={record.dmTimeId}
                        name="dmTimeId"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(e) => onChangeEleDichVu(record.id, e)}
                        select
                    >
                        {dmBuaAn.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.ten_bua_an}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            },
        },
        {
            title: t("hotel.roomClass.conditionFoc"),
            children: [
                {
                    title: t("set"),
                    dataIndex: "suat",
                    key: "suat",
                    width: "10%",
                    align: "center",
                    render: (_, record) => {
                        if (record.id === "NULL") {
                            return {
                                props: {
                                    colSpan: 0,
                                },
                            };
                        }
                        return (
                            <TextField
                                value={record.suat}
                                name="suat"
                                variant="outlined"
                                onChange={(e) => onChangeEleDichVu(record.id, e)}
                                size="small"
                                type="number"
                                fullWidth
                            ></TextField>
                        );
                        // return handleData(record.fitNgayThuongNet);
                    },
                },
                {
                    title: t("hotel.roomClass.foc"),
                    dataIndex: "foc",
                    key: "foc",
                    width: "10%",
                    align: "center",
                    render: (_, record) => {
                        if (record.id === "NULL") {
                            return {
                                props: {
                                    colSpan: 0,
                                },
                            };
                        }
                        return (
                            <TextField
                                value={record.foc}
                                variant="outlined"
                                name="foc"
                                size="small"
                                type="number"
                                onChange={(e) => onChangeEleDichVu(record.id, e)}
                                fullWidth
                            ></TextField>
                        );
                        // return handleData(record.extraNgayThuongNet);
                    },
                },
            ],
            width: "20%",
            align: "center",
            visible: true,
        },
        {
            title: t("tour.donGia"),
            children: [
                {
                    title: t("hotel.priceClass.giaNet"),
                    dataIndex: "giaNet",
                    key: "giaNet",
                    width: "12%",
                    align: "center",
                    render: (text, record) => {
                        if (record.id === "NULL") {
                            return {
                                props: {
                                    colSpan: 0,
                                },
                            };
                        }
                        return {
                            children: formatter.format(text),
                            props: {
                                rowSpan: 1,
                            },
                        };
                    },
                },
                {
                    title: t("tour.vanChuyen.soLuong"),
                    dataIndex: "soLuong",
                    key: "soLuong",
                    width: "12%",
                    align: "center",
                    render: (text, record) => {
                        if (record.id === "NULL") {
                            return {
                                props: {
                                    colSpan: 0,
                                },
                            };
                        }
                        return (
                            <TextField
                                value={text}
                                name="soLuong"
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={(e) => onChangeEleDichVu(record.id, e)}
                                fullWidth
                            ></TextField>
                        );
                        // return handleData(record.extraCuoiTuanNet);
                    },
                },
            ],
            // width: "25%",
            visible: true,
            align: "center",
        },
        {
            title: t("tour.tongChiPhi"),
            dataIndex: "tong",
            key: "tong",
            width: "12%",
            align: "center",
            visible: true,
            render: (text, record) => {
                if (record.id === "NULL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return {
                    children: formatter.format(text),
                    props: {
                        rowSpan: 1,
                    },
                };
            },
        },
        {
            title: t("adultGroup"),
            dataIndex: "bgNhomNgLonId",
            key: "bgNhomNgLonId",
            width: "15%",
            align: "center",
            visible: dataNhomNgLon.length > 0,
            render: (id, record) => {
                if (record.id === "NULL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                } else {
                    return {
                        children: (
                            <TextField
                                value={id}
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="bgNhomNgLonId"
                                onChange={(e) => handleChangeNhomNgLon(record.id, e)}
                                select
                            >
                                {dataNhomNgLon.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.tenNhom}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ),
                        props: {
                            colSpan: 1,
                            // rowSpan: dichVus.length - 1,
                        },
                    };
                }
                // else {
                //   return {
                //     props: {
                //       rowSpan: 0,
                //     },
                //   };
                // }
            },
        },
    ];

    const columnsTE = [
        {
            title: t("service"),
            dataIndex: "tenDichVu",
            align: "center",
            width: "15%",
            visible: true,
            render: (text, row) => {
                return {
                    children: text,
                    props: {
                        rowSpan: row.rowSpanDV,
                    },
                };
            },
        },
        {
            title: t("childrenGroup"),
            dataIndex: "tenNhomTE",
            align: "center",
            width: "15%",
            visible: dataNhomTreEm.length > 0,
            render: (text, row) => {
                return {
                    children: (
                        <TextField variant="outlined" value={text} size="small" fullWidth disabled={true}></TextField>
                    ),
                    props: {
                        rowSpan: row.rowSpanTE,
                    },
                };
            },
        },
        {
            title: t("age") + " " + t("children"),
            dataIndex: "dmDoTuoiId",
            align: "center",
            width: "15%",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        children: (
                            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                <Link onClick={() => handleAddServiceTreEm(row)} underline="none">
                                    {t("addService")}
                                </Link>
                            </Box>
                        ),
                        props: {
                            colSpan: 6,
                        },
                    };
                return (
                    <>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={22}>
                                <TextField
                                    variant="outlined"
                                    value={text}
                                    size="small"
                                    name="dmDoTuoiId"
                                    fullWidth
                                    select
                                    disabled={dataNhomTreEm.length > 0}
                                    onChange={(e) => onChangeEleDichVuTreEm(row.id, e)}
                                >
                                    {dmDoTuoi.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.do_tuoi}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={2}>
                                {dataNhomTreEm.length === 0 && (
                                    <CloseCircleFilled
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleDeleteServiceTreEm(row)}
                                        fontSize="small"
                                    />
                                )}
                            </Col>
                        </Row>
                    </>
                );
            },
        },
        {
            title: t("tour.percentage"),
            dataIndex: "phanTram",
            align: "center",
            width: "10%",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                // if (text)
                return {
                    children: (
                        <TextField
                            variant="outlined"
                            value={text}
                            size="small"
                            name="phanTram"
                            fullWidth
                            type="number"
                            onChange={(e) => onChangeEleDichVuTreEm(row.id, e)}
                        />
                    ),
                    props: {},
                };
            },
        },
        {
            title: t("hotel.priceClass.giaNet"),
            dataIndex: "giaNet",
            align: "center",
            width: "12%",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                if (text)
                    return {
                        children: formatter.format(text),
                        props: {},
                    };
            },
        },
        {
            title: t("tour.vanChuyen.soLuong"),
            dataIndex: "soLuong",
            align: "center",
            width: "10%",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };

                return {
                    children: (
                        <TextField
                            value={text}
                            variant="outlined"
                            name="soLuong"
                            size="small"
                            type="number"
                            fullWidth
                            onChange={(e) => onChangeEleDichVuTreEm(row.id, e)}
                        ></TextField>
                    ),
                    props: {},
                };
            },
        },
        {
            title: t("hotel.roomClass.intoMoney"),
            dataIndex: "thanhTien",
            width: "15%",
            align: "center",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                // if (text)
                return {
                    children: formatter.format(text),
                    props: {},
                };
            },
        },
        {
            title: () => {
                return (
                    <>
                        {t("action.apply")}{" "}
                        <Checkbox checked={checkAll} onChange={(e) => onChangeEleDichVuTreEm(e, "", "ALL")}></Checkbox>
                    </>
                );
            },
            dataIndex: "apDung",
            align: "center",
            width: "10%",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                return {
                    children: (
                        <Checkbox
                            name="apDung"
                            checked={row.apDung}
                            onChange={(e) => onChangeEleDichVuTreEm(row.id, e)}
                        />
                    ),
                    props: {},
                };
            },
        },
    ];
    const handleClear = () => {
        setMaNcc("");
        setDataSource({ ...dataSourceInit });
        setDichVus(JSON.parse(JSON.stringify(dichVusInit)));
        setDichVuTreEms(JSON.parse(JSON.stringify(dichVuTreEmsInit)));
    };

    const handleCloseModal = () => {
        handleCancel();
        handleClear();
    };

    const handleChangeDataServiceNhaHang = (dataSelected) => {
        // console.log(dataSelected);
        let lstDichVu = [];
        let dvUpdate = {};
        debugger;
        if (action === "UPDATE_NCC") {
            dataSource.tenNcc = dataSelected[0].tenNcc;
            dataSource.dmGiaApDungId = 1;
            dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
            dataSource.dmVatId = dataSelected[0].dmVatId ? dataSelected[0].dmVatId : 1;
            dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
            dataSource.moTa = dataSelected[0].moTa;
            lstDichVu = dataSelected?.map((item, idx) => {
                return {
                    id: idx + 1,
                    nccDichVuNhaHangId: item.nccDvNhaHangId,
                    tranxNhaHangId: item.tranxNhaHangId,
                    bgNhomNgLonId: -1,
                    tenDichVu: item.tenDichVu,
                    dmTimeId: 1,
                    suat: item.dieuKienFoc,
                    foc: item.foc,
                    giaNet: item.fitNgayThuongNet,
                    soLuong: 0,
                    tong: 0,
                };
            });
            lstDichVu.push({ id: "NULL" });
        } else if (action === "ADD_SERVICE") {
            if (
                dataNhomNgLon.length === 0 &&
                dichVus.find(
                    (it) =>
                        it.nccDichVuNhaHangId === dataSelected[0].nccDvNhaHangId &&
                        it.tranxNhaHangId === dataSelected[0].tranxNhaHangId
                )
            ) {
                return showNoti("error", t("noti.errorSelectedService"));
            }

            lstDichVu = JSON.parse(JSON.stringify(dichVus));
            lstDichVu.splice(dichVus.length - 1, 1);
            lstDichVu.push({
                key: dichVus.length,
                id: dichVus.length,
                nccDichVuNhaHangId: dataSelected[0].nccDvNhaHangId,
                tranxNhaHangId: dataSelected[0].tranxNhaHangId,
                bgNhomNgLonId: -1,
                tenDichVu: dataSelected[0].tenDichVu,
                dmTimeId: 1,
                suat: dataSelected[0].dieuKienFoc,
                foc: dataSelected[0].foc,
                giaNet: dataSelected[0].fitNgayThuongNet,
                soLuong: 0,
                tong: 0,
            });
            lstDichVu.push({ id: "NULL" });
        } else {
            if (
                dataNhomNgLon.length === 0 &&
                dichVus.find(
                    (it) =>
                        it.nccDichVuNhaHangId === dataSelected[0].nccDvNhaHangId &&
                        it.tranxNhaHangId === dataSelected[0].tranxNhaHangId
                )
            ) {
                return showNoti("error", t("noti.errorSelectedService"));
            }
            if (dichVus.length === 1) {
                dataSource.tenNcc = dataSelected[0].tenNcc;
                dataSource.dmGiaApDungId = 1;
                dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
                dataSource.dmVatId = dataSelected[0].dmVatId ? dataSelected[0].dmVatId : 1;
                dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
                dataSource.moTa = dataSelected[0].moTa;
            }
            lstDichVu = JSON.parse(JSON.stringify(dichVus));
            dvUpdate = lstDichVu[idxEdit];
            lstDichVu[idxEdit] = {
                id: idxEdit + 1,
                nccDichVuNhaHangId: dataSelected[0].nccDvNhaHangId,
                tranxNhaHangId: dataSelected[0].tranxNhaHangId,
                bgNhomNgLonId: -1,
                tenDichVu: dataSelected[0].tenDichVu,
                dmTimeId: 1,
                suat: dataSelected[0].dieuKienFoc,
                foc: dataSelected[0].foc,
                giaNet: dataSelected[0].fitNgayThuongNet,
                soLuong: 0,
                tong: 0,
            };
        }
        let dichVusFix = dichVus.filter((d) => d.id !== "NULL");
        if (dichVusFix.length === 1) {
            dataSource.tenNcc = dataSelected[0].tenNcc;
            dataSource.dmGiaApDungId = 1;
            dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
            dataSource.dmVatId = dataSelected[0].dmVatId ? dataSelected[0].dmVatId : 1;
            dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
            dataSource.moTa = dataSelected[0].moTa;
        }
        let nhomTreEms = JSON.parse(JSON.stringify(dataNhomTreEm));
        let lenDataTE = nhomTreEms.reduce((num, item) => {
            item.details.forEach(() => {
                num++;
            });
            return num;
        }, 0);
        let dichVusByNgLon = lstDichVu;
        if (dataNhomNgLon.length > 0) dichVusByNgLon = lstDichVu.filter((it) => it.bgNhomNgLonId !== -1);

        let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
        let idx = 0;
        let idxCheckRowSpanTE = 1;
        if (action === "UPDATE_NCC") {
            lstDichVuTreEm = [];
            let idxCheckRowSpanDV = 1;
            for (let i = 0; i < dichVusByNgLon.length - 1; i++) {
                if (dataNhomTreEm.length === 0) {
                    if (
                        lstDichVuTreEm.length === 0 ||
                        !lstDichVuTreEm.find(
                            (it) =>
                                it.nccDichVuNhaHangId === dichVusByNgLon[i].nccDichVuNhaHangId &&
                                it.tranxNhaHangId === dichVusByNgLon[i].tranxNhaHangId
                        )
                    )
                        lstDichVuTreEm = [
                            ...lstDichVuTreEm,
                            {
                                id: "addNew" + generateId(),
                                tenDichVu: dichVusByNgLon[i].tenDichVu,
                                giaNet: dichVusByNgLon[i].giaNet,
                                nccDichVuNhaHangId: dichVusByNgLon[i].nccDichVuNhaHangId,
                                tranxNhaHangId: dichVusByNgLon[i].tranxNhaHangId,
                            },
                        ];
                } else if (
                    dataNhomNgLon.length === 0 &&
                    !lstDichVuTreEm.find(
                        (it) =>
                            it.nccDichVuNhaHangId === dichVusByNgLon[i].nccDichVuNhaHangId &&
                            it.tranxNhaHangId === dichVusByNgLon[i].tranxNhaHangId
                    )
                ) {
                    for (let j = 0; j < nhomTreEms.length; j++) {
                        let element = nhomTreEms[j];
                        let dichVuTEs = element.details?.map((item) => {
                            idx++;
                            return {
                                id: idx,
                                tenDichVu: dichVusByNgLon[i].tenDichVu,
                                tenNhomTE: element.tenNhom,
                                phanTram: 0,
                                giaNet: dichVusByNgLon[i].giaNet,
                                soLuong: item.soLuong,
                                thanhTien: 0,
                                nccDichVuNhaHangId: dichVusByNgLon[i].nccDichVuNhaHangId,
                                tranxNhaHangId: dichVusByNgLon[i].tranxNhaHangId,
                                bgNhomTreEmId: element.id,
                                bgNhomNgLonId: dichVusByNgLon[i].bgNhomNgLonId,
                                dmDoTuoiId: item.dmDoTuoiId,
                                phanTram: 0,
                                apDung: false,
                                rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                                rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
                            };
                        });
                        idxCheckRowSpanTE += element.details.length;
                        lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEs];
                    }
                    idxCheckRowSpanDV += lenDataTE;
                }
            }
        } else if (action === "ADD_SERVICE") {
            if (dataNhomTreEm.length === 0) {
                if (
                    lstDichVuTreEm.length === 0 ||
                    !lstDichVuTreEm.find(
                        (it) =>
                            it.nccDichVuNhaHangId === dataSelected[0].nccDvNhaHangId &&
                            it.tranxNhaHangId === dataSelected[0].tranxNhaHangId
                    )
                )
                    lstDichVuTreEm = [
                        ...lstDichVuTreEm,
                        {
                            id: "addNew" + generateId(),
                            tenDichVu: dataSelected[0].tenDichVu,
                            giaNet: dataSelected[0].fitNgayThuongNet,
                            nccDichVuNhaHangId: dataSelected[0].nccDvNhaHangId,
                            tranxNhaHangId: dataSelected[0].tranxNhaHangId,
                            rowSpanDV: 1,
                        },
                    ];
            } else if (
                dataNhomNgLon.length === 0 &&
                !lstDichVuTreEm.find(
                    (it) =>
                        it.nccDichVuNhaHangId === dataSelected[0].nccDvNhaHangId &&
                        it.tranxNhaHangId === dataSelected[0].tranxNhaHangId
                )
            ) {
                idx = lenDataTE * (prevCountDichVu.current - 1);
                idxCheckRowSpanTE = lenDataTE * (prevCountDichVu.current - 1) + 1;
                for (let index = 0; index < nhomTreEms.length; index++) {
                    let element = nhomTreEms[index];
                    let dichVuTEByGroup = element.details?.map((item) => {
                        idx++;
                        return {
                            id: idx,
                            tenDichVu: dataSelected[0].tenDichVu,
                            tenNhomTE: element.tenNhom,
                            phanTram: 0,
                            giaNet: dataSelected[0].fitNgayThuongNet,
                            soLuong: item.soLuong,
                            thanhTien: 0,
                            nccDichVuNhaHangId: dataSelected[0].nccDvNhaHangId,
                            tranxNhaHangId: dataSelected[0].tranxNhaHangId,
                            bgNhomTreEmId: element.id,
                            bgNhomNgLonId: -1,
                            dmDoTuoiId: item.dmDoTuoiId,
                            phanTram: 0,
                            apDung: false,
                            rowSpanDV: idx === lenDataTE * (prevCountDichVu.current - 1) + 1 ? lenDataTE : 0,
                            rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
                        };
                    });
                    idxCheckRowSpanTE += element.details.length;
                    lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEByGroup];
                }
            }
        } else {
            if (dataNhomTreEm.length === 0) {
                lstDichVuTreEm = lstDichVuTreEm.filter(
                    (it) =>
                        !(
                            it.nccDichVuNhaHangId === dvUpdate.nccDichVuNhaHangId &&
                            it.tranxNhaHangId === dvUpdate.tranxNhaHangId
                        )
                );
                lstDichVuTreEm = [
                    ...lstDichVuTreEm,
                    {
                        id: "addNew" + generateId(),
                        tenDichVu: lstDichVu[idxEdit].tenDichVu,
                        giaNet: lstDichVu[idxEdit].giaNet,
                        nccDichVuNhaHangId: lstDichVu[idxEdit].nccDichVuNhaHangId,
                        tranxNhaHangId: lstDichVu[idxEdit].tranxNhaHangId,
                        rowSpanDV: 1,
                    },
                ];
            } else if (dataNhomNgLon.length === 0) {
                lstDichVuTreEm = [];
                let idxCheckRowSpanDV = 1;
                for (let i = 0; i < lstDichVu.length - 1; i++) {
                    if (
                        !lstDichVuTreEm.find(
                            (it) =>
                                it.nccDichVuNhaHangId === lstDichVu[i].nccDichVuNhaHangId &&
                                it.tranxNhaHangId === lstDichVu[i].tranxNhaHangId
                        )
                    ) {
                        for (let index = 0; index < nhomTreEms.length; index++) {
                            let element = nhomTreEms[index];
                            let dichVuTEs = element.details?.map((item) => {
                                idx++;
                                return {
                                    id: idx,
                                    tenDichVu: lstDichVu[i].tenDichVu,
                                    tenNhomTE: element.tenNhom,
                                    phanTram: 0,
                                    giaNet: lstDichVu[i].giaNet,
                                    soLuong: item.soLuong,
                                    thanhTien: 0,
                                    nccDichVuNhaHangId: lstDichVu[i].nccDichVuNhaHangId,
                                    tranxNhaHangId: lstDichVu[i].tranxNhaHangId,
                                    bgNhomTreEmId: element.id,
                                    bgNhomNgLonId: -1,
                                    dmDoTuoiId: item.dmDoTuoiId,
                                    phanTram: 0,
                                    apDung: false,
                                    rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                                    rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
                                };
                            });
                            idxCheckRowSpanTE += element.details.length;
                            lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEs];
                        }
                        idxCheckRowSpanDV += lenDataTE;
                    }
                }
            } else {
                lstDichVuTreEm = dichVuTreEms.filter((item) => item.bgNhomNgLonId !== dichVus[idxEdit].bgNhomNgLonId);
            }
        }
        // if (dataSelected.length > 0) setMaNcc(dataSelected[0].maNcc);
        setDichVuTreEms(lstDichVuTreEm);
        setDichVus(lstDichVu);
        setCountDichVu(lstDichVu.length);
        setDataSource({ ...dataSource });
        if (action === "ADD_SERVICE") showNoti("success", t("add") + " " + t("noti.success").toLowerCase());
        else showNoti("success", t("edit") + " " + t("noti.success").toLowerCase());
    };

    const handleChangNgayApDung = (e, ngayOrder) => {
        // let name = parseInt(e.target.name);
        let value = e.target.checked;
        if (dataSource.dmGiaApDungId === 2) {
            if (value) dataSource.ngayApDungs.push(ngayOrder);
            else dataSource.ngayApDungs = dataSource.ngayApDungs.filter((it) => it !== ngayOrder);
        } else {
            if (value) {
                for (let i = ngayOrder; i < bgHanhTrinhs.length + 1; i++) {
                    dataSource.ngayApDungs.push(i);
                }
            } else {
                dataSource.ngayApDungs = [];
            }
        }
        console.log(dataSource.ngayApDungs);
        setDataSource({ ...dataSource });
    };

    return (
        <>
            <Modal
                title={t("tour.nhaHang.capNhatDichVu")}
                footer={null}
                open={isOpen}
                onCancel={() => handleCloseModal()}
                className="custom-modal"
            >
                {contextHolder}
                <Divider style={{ background: "#898989" }} />
                <Box component="form" noValidate>
                    <Row>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t("supplier")}
                            required
                            value={dataSource.tenNcc}
                            InputProps={{
                                style: { height: "50px" },
                                endAdornment: (
                                    <>
                                        <BorderColorOutlined
                                            style={{ cursor: "pointer" }}
                                            fontSize="small"
                                            onClick={() => handleUpdateNCC()}
                                        />
                                        <CloseCircleFilled
                                            style={{ cursor: "pointer" }}
                                            fontSize="small"
                                            onClick={() => handleClear()}
                                        />
                                    </>
                                ),
                            }}
                        ></TextField>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                        <Col span={24}>
                            <Table
                                dataSource={dichVus}
                                columns={columnServices.filter((item) => item.visible)}
                                bordered
                                pagination={false}
                                summary={(pageData) => {
                                    // console.log(pageData);
                                    let totalDonGia = 0;
                                    let totalChiPhi = 0;

                                    pageData.forEach(({ soLuong, tong, id }) => {
                                        if (id !== "NULL") {
                                            totalDonGia += parseInt(soLuong);
                                            totalChiPhi += parseInt(tong);
                                        }
                                    });
                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0} colSpan={4}>
                                                    {t("tour.tongKet")}
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={1} colSpan={2} align="center">
                                                    <Tooltip title={t("totalSet")}>{totalDonGia}</Tooltip>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={2} colSpan={2} align="center">
                                                    <Tooltip title={t("totalCost")}>
                                                        {formatter.format(totalChiPhi)}
                                                    </Tooltip>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </>
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <TextField
                                id="dmGiaApDungId"
                                select
                                InputProps={{
                                    style: { height: "50px" },
                                }}
                                fullWidth
                                label={t("priceApply")}
                                value={dataSource.dmGiaApDungId}
                                name="dmGiaApDungId"
                                onChange={(e) => handleChangeValueDataSoure(e)}
                            >
                                {dmGia?.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.gia_ap_dung}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <FormControl disabled={ngay !== 0} style={{ width: "100%" }}>
                                <Row>
                                    {bgHanhTrinhs?.map((element, idx) => {
                                        return (
                                            <FormControlLabel
                                                key={element.id}
                                                control={
                                                    <Checkbox
                                                        name={element.id}
                                                        onChange={(e) => handleChangNgayApDung(e, element.ngay_order)}
                                                        checked={
                                                            ngay !== 0
                                                                ? (dataSource.dmGiaApDungId === 1 &&
                                                                      parseInt(element.ngay_order) >= ngay) ||
                                                                  (dataSource.dmGiaApDungId === 2 &&
                                                                      parseInt(element.ngay_order) === ngay)
                                                                : dataSource.ngayApDungs?.includes(element.ngay_order)
                                                        }
                                                    />
                                                }
                                                label={"Ngày " + element.ngay_order}
                                            />
                                        );
                                    })}
                                </Row>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <TextField
                                select
                                fullWidth
                                label={t("typeOfApply")}
                                value={1}
                                disabled
                                InputProps={{
                                    style: { height: "50px" },
                                }}
                            >
                                {dmLoaiApDung?.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.loai_ap_dung}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <TextField
                                select
                                fullWidth
                                label={t("action.apply") + " " + t("thuevat")}
                                value={dataSource.dmVatId}
                                disabled
                                InputProps={{
                                    style: { minHeight: "50px" },
                                }}
                            >
                                {dmVat?.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.vat_str}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                        <Col span={11}>
                            <TextField
                                fullWidth
                                label={t("typeTour.description")}
                                value={dataSource.moTa}
                                name="moTa"
                                onChange={(e) => handleChangeValueDataSoure(e)}
                                // multiline
                                maxRows={3}
                            ></TextField>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <h3>{t("hotel.table.title")}</h3>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Table
                                dataSource={dichVuTreEms}
                                // rowSelection={{}}
                                columns={columnsTE.filter((item) => item.visible)}
                                pagination={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <h3>{t("tour.tongKet")}</h3>
                    </Row>
                    <Row>
                        <Col xs={16} sm={16} md={19} lg={16} xl={16}></Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ fontSize: "18px" }}>
                            {dataNhomNgLon.length > 0 ? (
                                dichVus
                                    .filter((item) => item.bgNhomNgLonId)
                                    .map((it) => {
                                        let tenNhomNgLon = dataNhomNgLon.find((dt) => dt.id === it.bgNhomNgLonId)
                                            ?.tenNhom;
                                        return (
                                            <Row key={it.id}>
                                                <Col
                                                    span={11}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                    }}
                                                >
                                                    {t("tour.chiPhi") + " " + tenNhomNgLon + ":"}
                                                </Col>
                                                <Col span={3}></Col>
                                                <Col
                                                    span={5}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                    }}
                                                >
                                                    {formatter.format(it.tong)}
                                                </Col>
                                            </Row>
                                        );
                                    })
                            ) : (
                                <>
                                    <Row>
                                        <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {t("tour.tongChiPhi") + " " + t("adult")}
                                        </Col>

                                        <Col span={3}></Col>
                                        <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {formatter.format(chiPhiNguoiLon)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {t("tour.tongChiPhi") + " " + t("children")}
                                        </Col>

                                        <Col span={3}></Col>
                                        <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {formatter.format(chiPhiTreEm)}
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {dataNhomNgLon.length > 0 &&
                                Object.keys(
                                    Object.groupBy(
                                        dichVuTreEms.filter((it) => it.apDung),
                                        ({ tenNhomTE }) => tenNhomTE
                                    )
                                )?.map((it, idx) => {
                                    return (
                                        <Row key={idx}>
                                            <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                {t("tour.chiPhi") + " " + it + ":"}
                                            </Col>
                                            <Col span={3}></Col>
                                            <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                {formatter.format(
                                                    Object.groupBy(
                                                        dichVuTreEms.filter((it) => it.apDung),
                                                        ({ tenNhomTE }) => tenNhomTE
                                                    )[it]?.reduce((sum, item) => sum + item.thanhTien, 0)
                                                )}
                                            </Col>
                                        </Row>
                                    );
                                })}
                            {dataNhomNgLon.length > 0 &&
                                dichVus
                                    .filter((item) => item.tenNhomNgLon)
                                    .map((it) => {
                                        return (
                                            <Row key={it.id}>
                                                <Col
                                                    span={11}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                    }}
                                                >
                                                    {t("tour.tongChiPhi") + " " + it.tenNhomNgLon + ":"}
                                                </Col>
                                                <Col span={3}></Col>
                                                <Col
                                                    span={5}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                    }}
                                                >
                                                    {formatter.format(
                                                        dichVuTreEms
                                                            .filter(
                                                                (dt) =>
                                                                    dt.apDung && dt.bgNhomNgLonId === it.bgNhomNgLonId
                                                            )
                                                            .reduce((sum, item) => sum + parseInt(item.thanhTien), 0) +
                                                            it.tong
                                                    )}
                                                </Col>
                                            </Row>
                                        );
                                    })}
                            <Row>
                                <Col
                                    xs={11}
                                    sm={11}
                                    md={11}
                                    lg={11}
                                    xl={11}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {t("tour.tong") + " " + t("hotel.roomClass.intoMoney")}
                                </Col>

                                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                                <Col
                                    xs={5}
                                    sm={5}
                                    md={5}
                                    lg={5}
                                    xl={5}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {formatter.format(tongChiPhi)}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row style={{ marginTop: 6 }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
                        <Col xs={24} sm={24} md={24} lg={18} xl={10}>
                            <Space>
                                <Button
                                    variant="contained"
                                    key="save"
                                    startIcon={<SaveIcon />}
                                    onClick={(e) => handleSave(e)}
                                >
                                    {t("action.ok")}
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: "#898989",
                                        color: "white",
                                    }}
                                    key="reset"
                                    startIcon={<RestartAltIcon />}
                                    onClick={(e) => handleClear(e)}
                                >
                                    {t("action.reset")}
                                </Button>
                                <Button
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => handleCloseModal()}
                                    key="clear"
                                >
                                    {t("action.cancel")}
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Box>
                <ModalThongTinNccNhaHang
                    isOpen={isShowNccNH}
                    action={action}
                    onIsShow={setIsShowNccNH}
                    onSaves={handleChangeDataServiceNhaHang}
                    maNcc={maNcc}
                    lenNgLon={dataNhomNgLon.length}
                    tuNgay={tuNgay}
                    denNgay={denNgay}
                />
            </Modal>
        </>
    );
};
export default ModalCapNhatNhaHang;
