import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { Link, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { Col, Row, Space, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChietTinhService from "../../../../api/tour/chiettinh.service";
import ModalQuanLyKhoangCach from "./modal/ModalQuanLyKhoangCach";
import ModalThoiGianApDung from "./modal/ModalThoiGianApDung";
import ModalThongTinTreEm from "./modal/ModalThongTinTreEm";
import ModalQuanLyChuongTrinh from "./modal/ModalQuanLyChuongTrinh";
import ModalCauHinhLaiTheoTour from "./modal/ModalCauHinhLaiTheoTour";
import { TIME_DURATION, TOUR_KHOANG_KHACH, TOUR_TG_AP_DUNG } from "../../../../constants";
import dm_ty_gia from "../../../../api/category/dm_ty_gia";

const FormChietTinh = ({
    dataForm,
    handleTiGia,
    handleFilter,
    tourThoiGianApDungId,
    setTourThoiGianApDungId,
    tourQuanLyKhoangKhachId,
    setTourQuanLyKhoangKhachId,
    handleUpdate,
    tourThongTinChungId,
}) => {
    const { t } = useTranslation();
    const [thoiGianApDung, setThoiGianApDung] = useState(tourThoiGianApDungId);
    const [soKhach, setSoKhach] = useState(tourQuanLyKhoangKhachId);
    const [tourChietTinhId, setTourChietTinhId] = useState(0);
    const [tyGiaGoc, setTyGiaGoc] = useState(null);
    const [tyGia, setTyGia] = useState(null);
    const [tyGiaQuyDoi, setTyGiaQuyDoi] = useState(null);
    const [tyGiaQuyDoiId, setTyGiaQuyDoiId] = useState(null);
    const [dataThoiGianAD, setDataThoiGianAD] = useState([]);
    const [dataSoKhach, setDataSoKhach] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [dataTyGiaQuyDoi, setDataTyGiaQuyDoi] = useState([]);
    const [isOpenKK, setIsOpenKK] = useState(false);
    const [isOpenThongTinTE, setIsOpenThongTinTE] = useState(false);
    const [isOpenThongTinAD, setIsOpenThongTinAD] = useState(false);
    const [, setIsOpenTest] = useState(false);
    const [isOpenQLCT, setIsOpenQLCT] = useState(false);
    const [openLaiTheoTour, setOpenLaiTheoTour] = useState(false);
    const [txtThoiGianApDung, setTxtThoiGianApDung] = useState([]);
    useEffect(() => {
        if (!isEmpty(dataForm)) {
            setDataThoiGianAD(dataForm.thoiGianApDung);
            setDataTyGiaQuyDoi(dataForm.tyGiaQuyDoi);
            setTyGiaQuyDoi(dataForm.tyGiaQuyDoi?.maNgoaiTe);
            setTyGiaQuyDoiId(dataForm.tyGiaQuyDoiId);
            setTyGiaGoc(dataForm.tyGiaGoc?.ma_ngoai_te);
            setTyGia(dataForm.tyGia);
            if (!soKhach) {
                let dataSoKhach = [
                    {
                        id: 0,
                        dmLoaiKhachId: 1,
                        soKhachToiThieu: "All",
                    },
                ];
                if (dataForm.danhSachKhoangKhach) dataSoKhach = [...dataSoKhach, ...dataForm.danhSachKhoangKhach];
                setDataSoKhach(dataSoKhach);
            }
            debugger;
            setTourChietTinhId(dataForm.id);
        } else {
        }
    }, [dataForm, dataForm.danhSachKhoangKhach, dataForm.thoiGianApDung]);
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }

        return true;
    };
    const getTyGiaQuyDoi = (ma_ngoai_te_id, ma_quy_doi_id) => {
        let dataReq = {
            ma_ngoai_te_id,
            ma_quy_doi_id,
        };
        dm_ty_gia
            .getTyGiaQuyDoi(dataReq)
            .then((res) => {
                if (res.status === 200 && res.data.code === "00") {
                    setTyGia(res.data.ty_gia_quy_doi);
                }
            })
            .catch((err) => console.log("getTyGiaQuyDoi Error ..." + err));
    };
    const onSaveKK = (data, status) => {
        ChietTinhService.saveQuanLyKhoangKhach(data)
            .then(function (response) {
                console.log("Response: ====", response.data);
                if (response.status === 200 && response.data.code === "00") {
                    setIsOpenKK(status);
                    handleUpdate(true);
                    api["success"]({
                        message: t("stour"),
                        description: t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("edit") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
                if (response.status === 401) {
                }
            });
    };
    const onCancelKK = (status) => {
        setIsOpenKK(status);
    };
    const onSaveThongTinTE = (data, status) => {
        ChietTinhService.onSaveThongTinTE(data)
            .then(function (response) {
                console.log("Response: ====", response.data);
                if (response.status === 200 && response.data.code === "00") {
                    setIsOpenThongTinTE(status);
                    handleUpdate(true);
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
                if (response.status === 401) {
                }
            });
    };
    const onCancelThongTinTE = (status) => {
        setIsOpenThongTinTE(status);
    };
    const onSaveThongTinAD = (data, status) => {
        ChietTinhService.saveThoiGianApDung(data)
            .then(function (response) {
                console.log("Response: ====", response.data);
                if (response.status === 200 && response.data.code === "00") {
                    setIsOpenThongTinAD(status);
                    handleUpdate(true);
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
                if (response.status === 401) {
                }
            });
    };

    const onCancelThongTinAD = (status) => {
        setIsOpenThongTinAD(status);
    };
    const cauHinhKhoangKhach = () => {
        setIsOpenKK(true);
    };
    const cauHinhTreEm = () => {
        setIsOpenThongTinTE(true);
    };
    const cauHinhThoiGianAD = () => {
        setIsOpenThongTinAD(true);
    };
    const cauHinhQLCT = () => {
        setIsOpenQLCT(true);
    };
    const testModal = () => {
        setIsOpenTest(true);
    };
    const onCancelTQLCT = (status) => {
        setIsOpenQLCT(status);
    };
    const onChangeTiGiaQD = (e) => {
        debugger;
        let tyGia = dataTyGiaQuyDoi?.find((it) => it.maNgoaiTeId === e.target.value)?.tyGiaQuyDoi || 0;
        setTyGiaQuyDoi(tyGia);
        setTyGia(tyGia);
        setTyGiaQuyDoiId(e.target.value);
        handleTiGia(tyGia);
        updateTiGia(e.target.value, tyGia);
    };
    const onChangeTiGia = (e) => {
        debugger;
        setTyGia(e.target.value);
        handleTiGia(e.target.value);
        updateTiGia(tyGiaQuyDoiId, e.target.value);
    };
    const updateTiGia = (tyGiaQuyDoiId, tyGia) => {
        if (tyGiaQuyDoiId) {
            let data = {
                tourChietTinhId: tourChietTinhId,
                tyGiaQuyDoiId: tyGiaQuyDoiId,
                tyGia: tyGia,
            };
            ChietTinhService.updateTyGia(data)
                .then(function (response) {
                    console.log("Response: ====", response.data);
                    if (response.status === 200 && response.data.code === "00") {
                        api["success"]({
                            message: t("stour"),
                            description: t("update") + " " + t("noti.success").toLowerCase(),
                            duration: TIME_DURATION,
                        });
                    } else {
                        api["error"]({
                            message: t("stour"),
                            description: response.data.message,
                            duration: TIME_DURATION,
                        });
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                    api["error"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.error").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                    if (response.status === 401) {
                    }
                });
        }
    };
    const showCauHinhLaiTheoTour = () => {
        setOpenLaiTheoTour(true);
    };
    const onCancelCauHinhLaiTheoTour = (status) => {
        setOpenLaiTheoTour(false);
    };
    const onChangeThoiGianAD = (e) => {
        setThoiGianApDung(e.target.value);
        let thoiGian = dataThoiGianAD?.find((ed) => ed.id === e.target.value);
        setTxtThoiGianApDung([thoiGian.tuNgay, thoiGian.denNgay]);
        setTourThoiGianApDungId(e.target.value);
        localStorage.setItem(TOUR_TG_AP_DUNG, e.target.value);
        handleFilter("TG", e.target.value);
    };
    const onChangeSoKhach = (e) => {
        setSoKhach(e.target.value);
        setTourQuanLyKhoangKhachId(e.target.value);
        localStorage.setItem(TOUR_KHOANG_KHACH, e.target.value);
        handleFilter("SK", e.target.value);
    };
    return (
        <Row>
            {contextHolder}
            <ModalQuanLyKhoangCach
                isOpen={isOpenKK}
                onSave={onSaveKK}
                onCancel={onCancelKK}
                tourChietTinhId={tourChietTinhId}
                dataSoKhach={dataSoKhach}
            />
            <ModalThongTinTreEm
                isOpen={isOpenThongTinTE}
                onSave={onSaveThongTinTE}
                onCancel={onCancelThongTinTE}
                tourChietTinhId={tourChietTinhId}
            />
            <ModalThoiGianApDung
                isOpen={isOpenThongTinAD}
                onSave={onSaveThongTinAD}
                onCancel={onCancelThongTinAD}
                tourChietTinhId={tourChietTinhId}
            />
            <ModalQuanLyChuongTrinh
                isOpen={isOpenQLCT}
                onCancel={onCancelTQLCT}
                tourChietTinhId={tourChietTinhId}
                tourThongTinChungId={tourThongTinChungId}
            />
            <ModalCauHinhLaiTheoTour
                isOpen={openLaiTheoTour}
                onCancel={onCancelCauHinhLaiTheoTour}
                tourChietTinhId={tourChietTinhId}
                dataThoiGianAD={dataThoiGianAD}
            />
            {/*<ModalChietTinhCommon isOpen={isOpenTest} onSave={onSaveThongTinAD} onCancel={onCancelThongTinAD}/>*/}
            <Col xs={6} sm={6} md={6} lg={6} xl={3}>
                <TextField
                    label={t("hotel.priceRoom.applicationTime")}
                    select
                    fullWidth
                    name="thoiGianApDung"
                    onChange={(e) => onChangeThoiGianAD(e)}
                    value={thoiGianApDung}
                >
                    {dataThoiGianAD.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.tuNgay + " - " + option.denNgay}
                        </MenuItem>
                    ))}
                </TextField>
            </Col>

            <Col xs={6} sm={6} md={6} lg={6} xl={2} style={{ marginLeft: "5px" }}>
                <TextField
                    label={t("tour.soKhach")}
                    select
                    fullWidth
                    name="soKhach"
                    onChange={(e) => onChangeSoKhach(e)}
                    value={soKhach}
                >
                    {dataSoKhach?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.dmLoaiKhachId === 2
                                ? option.soKhachToiThieu + " - " + option.soKhachToiDa
                                : option.soKhachToiThieu}
                        </MenuItem>
                    ))}
                </TextField>
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={2}>
                <TextField
                    fullWidth
                    disabled={true}
                    name="tyGiaGoc"
                    onChange={(e) => setTyGiaGoc(e.target.value)}
                    value={tyGiaGoc}
                ></TextField>
            </Col>
            <ArrowForwardIcon sx={{ marginTop: "15px" }} />
            <Col xs={6} sm={6} md={6} lg={6} xl={2}>
                <TextField
                    select
                    fullWidth
                    name="tyGiaQuyDoi"
                    onChange={(e) => onChangeTiGiaQD(e)}
                    value={tyGiaQuyDoiId}
                >
                    {dataTyGiaQuyDoi?.map((option) => (
                        <MenuItem key={option.maNgoaiTeId} value={option.maNgoaiTeId}>
                            {option.maNgoaiTe}
                        </MenuItem>
                    ))}
                </TextField>
            </Col>
            <span style={{ marginTop: "16px", marginLeft: "5px" }}>{t("category.exchangeRate")}</span>
            <Col xs={6} sm={6} md={6} lg={6} xl={2} style={{ marginLeft: "5px" }}>
                <TextField
                    fullWidth
                    name="tyGia"
                    onChange={(e) => onChangeTiGia(e)}
                    value={tyGia}
                    disabled={dataTyGiaQuyDoi?.every((item) => item.maNgoaiTeId !== tyGiaQuyDoiId) ? true : false}
                ></TextField>
            </Col>
            <Link sx={{ marginTop: "16px", marginLeft: "5px" }} onClick={showCauHinhLaiTheoTour}>
                {t("tour.cauHinhLai")}
            </Link>
            <Col xs={9} sm={9} md={9} lg={9} xl={2}></Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <Space>
                    <Tooltip title={t("tour.cauHinhHanhTrinh")}>
                        <IconButton>
                            <PinDropIcon fontSize="large" onClick={(e) => cauHinhQLCT(e)} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t("tour.cauHinhKhoangKhanh")}>
                        <IconButton>
                            <ManageAccountsIcon fontSize="large" onClick={(e) => cauHinhKhoangKhach(e)} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t("tour.cauHinhThoiGianAD")}>
                        <IconButton>
                            <ManageHistoryIcon fontSize="large" onClick={(e) => cauHinhThoiGianAD(e)} />
                        </IconButton>
                    </Tooltip>{" "}
                    <Tooltip title={t("tour.cauHinhGiaTreEm")}>
                        <IconButton>
                            <EscalatorWarningIcon fontSize="large" onClick={(e) => cauHinhTreEm(e)} />
                        </IconButton>
                    </Tooltip>{" "}
                    <Tooltip title={t("tour.taiXuong")}>
                        <IconButton onClick={(e) => testModal(e)}>
                            <DownloadIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>{" "}
                    <Tooltip title={t("delete")}>
                        <IconButton>
                            <DeleteIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Space>
            </Col>
        </Row>
    );
};

export default FormChietTinh;
