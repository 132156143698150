import { MinusCircleOutlined } from "@ant-design/icons";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { Affix, Card, Col, DatePicker, Divider, Form, Input, Modal, Row, Space, Switch, Typography } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import { API_BASE_URL, data_getDM } from "../../../../../constants";
import TableModalGP from "./TableModalGP";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Delete from "@mui/icons-material/Delete";
import Save from "@mui/icons-material/Save";

const { RangePicker } = DatePicker;
const { Text } = Typography;
const { TextArea } = Input;

const ModalPrAction = ({ isOpen, handleCancel, handleFinish, record, action, dvId }) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [kieuPhong, setKieuPhong] = useState([]);
    const [hangPhong, setHangPhong] = useState([]);
    const [tiGia, setTiGia] = useState([]);
    const [dataSourceTable, setDataSourceTable] = useState([]);
    const [nccThongTinChungId, setNccThongTinChungId] = useState(dvId);
    const [idTable, setIdTable] = useState([]);
    const [hangPhongId, setHangPhongId] = useState();
    const [maPhong, setMaPhong] = useState(0);
    // const [tenPhong, setTenPhong] = useState();
    const [dmTyGiaId, setDmTyGiaId] = useState(1);
    const [ghiChu, setGhiChu] = useState("");

    const [timeErr, setTimetErr] = useState(false);
    const [hangPhongIdErr, setHangPhongIdtErr] = useState(false);
    const [maPhongErr, setMaPhongErr] = useState(false);
    const [kieuPhongIdErr, setKieuPhongIdErr] = useState(false);
    const [tienId, setTienId] = useState();
    const [kieuPhongId, setKieuPhongId] = useState();
    const [dataSource, setDataSource] = useState();
    useEffect(() => {
        if (record) {
            let apiData = {
                id: record.isDouble ? record.ids : record.id,
            };
            axios({
                method: "post",
                url: API_BASE_URL + "ncc/giaphong/getDataById",
                data: apiData,
                headers: authHeader(),
            })
                .then(function (response) {
                    if (response.status === 200) {
                        let data = response.data.data;
                        let dataArr = [];
                        console.log("Response: ==== getDataById" + data.nccHangPhongId);
                        setHangPhongId(data.nccHangPhongId);
                        setTienId(data.maNgoaiTe);
                        setKieuPhongId(data.dmKieuPhongId);
                        var items = [];
                        if (data.tranxTimeApDungRequests.length > 0) {
                            let dataFit = data.tranxTimeApDungRequests;
                            var dataSource = [];
                            for (let k = 0; k < dataFit.length; k++) {
                                var tuNgay = dataFit[k].tuNgay.split("/");
                                var denNgay = dataFit[k].denNgay.split("/");
                                items.push({
                                    id: dataFit[k].id,
                                    nccGiaPhongId: dataFit[k].nccGiaPhongId,
                                    rangeTime: [
                                        dayjs(new Date(tuNgay[2], tuNgay[1] - 1, tuNgay[0])),
                                        dayjs(new Date(denNgay[2], denNgay[1] - 1, denNgay[0])),
                                    ],
                                    trangThaiBooking: dataFit[k].trangThaiBooking,
                                });
                                dataArr.push({
                                    id: dataFit[k].id,
                                    nccGiaPhongId: dataFit[k].nccGiaPhongId,
                                    rangeTime: [
                                        dayjs(new Date(tuNgay[2], tuNgay[1] - 1, tuNgay[0])),
                                        dayjs(new Date(denNgay[2], denNgay[1] - 1, denNgay[0])),
                                    ],
                                    trangThaiBooking: dataFit[k].trangThaiBooking,
                                    children: [
                                        {
                                            id: 0,
                                            rowKey: Math.random() * (100 - 1),
                                            typePrice: "Fit",
                                            fitNgayThuongNet: dataFit[k].fitNgayThuongNet,
                                            fitNgayThuongGiaBan: dataFit[k].fitNgayThuongGiaBan,
                                            fitCuoiTuanNet: dataFit[k].fitCuoiTuanNet,
                                            fitCuoiTuanGiaBan: dataFit[k].fitCuoiTuanGiaBan,
                                            uuid: 1,
                                        },
                                        {
                                            id: 1,
                                            rowKey: Math.random() * (100 - 1),
                                            typePrice: "Extra",
                                            fitNgayThuongNet: dataFit[k].extraNgayThuongNet,
                                            fitNgayThuongGiaBan: dataFit[k].extraNgayThuongGiaBan,
                                            fitCuoiTuanNet: dataFit[k].extraCuoiTuanNet,
                                            fitCuoiTuanGiaBan: dataFit[k].extraCuoiTuanGiaBan,
                                            uuid: 2,
                                        },
                                    ],
                                });
                                dataSource.push({
                                    fit: {
                                        fitNgayThuongNet: dataFit[k].fitNgayThuongNet,
                                        fitNgayThuongGiaBan: dataFit[k].fitNgayThuongGiaBan,
                                        fitCuoiTuanNet: dataFit[k].fitCuoiTuanNet,
                                        fitCuoiTuanGiaBan: dataFit[k].fitCuoiTuanGiaBan,
                                    },
                                    extra: {
                                        fitNgayThuongNet: dataFit[k].extraNgayThuongNet,
                                        fitNgayThuongGiaBan: dataFit[k].extraNgayThuongGiaBan,
                                        fitCuoiTuanNet: dataFit[k].extraCuoiTuanNet,
                                        fitCuoiTuanGiaBan: dataFit[k].extraCuoiTuanGiaBan,
                                    },
                                    key: k,
                                });
                            }
                            form.setFieldsValue({
                                id: data.id,
                                nccThongTinChungId: data.nccThongTinChungId,
                                dmKieuPhongId: data.dmKieuPhongId,
                                kieuPhong: data.dmKieuPhongId,
                                dmTyGiaId: data.dmTyGiaId,
                                maPhong: data.maPhong,
                                ghiChu: data.ghiChu,
                                hangPhong: data.nccHangPhongId,
                                items: items,
                            });
                            setDataSource(dataSource);
                            setDataSourceTable(dataArr);
                            setIdTable(data.id);
                            setNccThongTinChungId(data.nccThongTinChungId);
                            setKieuPhongId(data.dmKieuPhongId);
                            setHangPhongId(data.nccHangPhongId);
                            setMaPhong(data.maPhong);
                            setDmTyGiaId(data.dmTyGiaId);
                            setGhiChu(data.ghiChu);
                        } else {
                            setDataSourceTable(dataArr);
                            setIdTable(data.id);
                            setNccThongTinChungId(data.nccThongTinChungId);
                            setKieuPhongId(data.dmKieuPhongId);
                            setHangPhongId(data.nccHangPhongId);
                            setMaPhong(data.maPhong);
                            setDmTyGiaId(data.dmTyGiaId);
                            setGhiChu(data.ghiChu);
                            form.setFieldsValue({
                                id: data.id,
                                nccThongTinChungId: data.nccThongTinChungId,
                                dmKieuPhongId: data.dmKieuPhongId,
                                kieuPhong: data.dmKieuPhongId,
                                dmTyGiaId: data.dmTyGiaId,
                                maPhong: data.maPhong,
                                ghiChu: data.ghiChu,
                                hangPhong: data.nccHangPhongId,
                                items: items,
                            });
                        }
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                    if (response.status === 401) {
                    }
                });
        }
    }, [form, record.id]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmTyGia",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].loai_tien,
                            });
                        }
                        setTiGia(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);

    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmKieuPhong",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataQMP = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQMP.push({
                                    value: data[i].id,
                                    label: data[i].kieu_phong,
                                });
                            }
                        }
                        setKieuPhong(dataQMP);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        let data = {
            nccThongTinChungId: dvId,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/hangphong/getData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response hangphong: ====", response.data.data);
                    let dataHP = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataHP.push({
                                value: data[i].id,
                                label: data[i].hangPhong,
                            });
                        }
                        console.log(dataHP);

                        setHangPhong(dataHP);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const onFinish = (values) => {
        setKieuPhongIdErr(false);
        setHangPhongIdtErr(false);
        setMaPhongErr(false);
        var error = false;
        if (!maPhong) {
            setMaPhongErr(true);
            error = true;
        }
        if (!hangPhongId) {
            setHangPhongIdtErr(true);
            error = true;
        }
        if (!kieuPhongId) {
            setKieuPhongIdErr(true);
            error = true;
        }
        var timeErr = [];
        var tranxTimeApDungRequests = [];
        if (values.items) {
            for (let i = 0; i < values.items.length; i++) {
                const element = values.items[i];
                if (!element.rangeTime) {
                    timeErr.push(true);
                    error = true;
                } else {
                    let index = dataSource.length;
                    tranxTimeApDungRequests.push({
                        tuNgay: element.rangeTime[0].format("DD/MM/YYYY"),
                        denNgay: element.rangeTime[1].format("DD/MM/YYYY"),
                        fitNgayThuongNet: dataSource[0].fit.fitNgayThuongNet,
                        fitNgayThuongGiaBan: dataSource[0].fit.fitNgayThuongGiaBan,
                        fitCuoiTuanNet: dataSource[0].fit.fitCuoiTuanNet,
                        fitCuoiTuanGiaBan: dataSource[0].fit.fitCuoiTuanGiaBan,
                        extraNgayThuongNet:
                            index === 1 ? dataSource[0].extra.fitNgayThuongNet : dataSource[1].extra.fitNgayThuongNet,
                        extraNgayThuongGiaBan:
                            index === 1
                                ? dataSource[0].extra.fitNgayThuongGiaBan
                                : dataSource[1].extra.fitNgayThuongGiaBan,
                        extraCuoiTuanNet:
                            index === 1 ? dataSource[0].extra.fitCuoiTuanNet : dataSource[1].extra.fitCuoiTuanNet,
                        extraCuoiTuanGiaBan:
                            index === 1 ? dataSource[0].extra.fitCuoiTuanGiaBan : dataSource[1].extra.fitCuoiTuanGiaBan,
                        trangThaiBooking: element.trangThaiBooking ? element.trangThaiBooking : false,
                    });
                }
            }
        }
        setTimetErr(timeErr);
        let hangPhongName = hangPhong.find((hp) => hp.value === hangPhongId);
        if (error) return;
        let data = {
            id: idTable,
            nccThongTinChungId: nccThongTinChungId,
            nccHangPhongId: hangPhongId,
            hangPhong: hangPhongName,
            dmKieuPhongId: kieuPhongId,
            kieuPhong: kieuPhong[kieuPhongId].label,
            dmTyGiaId: dmTyGiaId ? dmTyGiaId : 1,
            maPhong: maPhong,
            loaiTien: tiGia[dmTyGiaId] ? tiGia[dmTyGiaId].label : "",
            ghiChu: ghiChu ? ghiChu : "",
            tranxTimeApDungRequests: tranxTimeApDungRequests,
        };
        console.log(JSON.stringify(data));
        handleFinish(open, data);
    };
    const handleCancels = () => {
        form.resetFields();
        handleCancel();
    };

    const dataRender = () => {
        let html;
        // if (record !== null || record !== '') {
        //     form.setFieldsValue({});
        // }
        html = (
            <>
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>

                <Row className="">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.List name="items">
                            {(fields, { add, remove }) => (
                                <div
                                    style={{
                                        display: "flex",
                                        rowGap: 16,
                                        flexDirection: "column",
                                    }}
                                >
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Card
                                            size="large"
                                            key={key}
                                            // extra={
                                            //     <CloseOutlined
                                            //         onClick={() => {
                                            //             remove(field.name);
                                            //         }}
                                            //     />
                                            // }
                                        >
                                            <Row>
                                                <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "rangeTime"]}
                                                        label={t("hotel.priceRoom.applicationTime") + " *"}
                                                        {...formItemLayoutDate}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    t("validate") +
                                                                    " " +
                                                                    t("hotel.priceRoom.applicationTime").toLowerCase(),
                                                            },
                                                        ]}
                                                    >
                                                        <RangePicker
                                                            style={{ width: "100%" }}
                                                            placeholder={[t("toDate"), t("fromDate")]}
                                                            disabledDate={(current) => {
                                                                let customDate = moment().format("DD-MM-YYYY");
                                                                return (
                                                                    current &&
                                                                    current < moment(customDate, "DD-MM-YYYY")
                                                                );
                                                            }}
                                                            defaultValue={
                                                                dataSourceTable.length > 0 && dataSourceTable[key]
                                                                    ? dataSourceTable[key].rangeTime
                                                                    : null
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={5} sm={5} md={5} lg={5} xl={5}></Col>
                                                <Col xs={5} sm={5} md={5} lg={5} xl={2}>
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        onClick={() => remove(name)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row style={{ marginTop: "15px" }}>
                                                <TableModalGP
                                                    style={{ width: "100%" }}
                                                    keyNum={key}
                                                    setDataEdit={setDataSource}
                                                    dataEdit={dataSource}
                                                    dataS={
                                                        dataSourceTable[key]?.children
                                                            ? dataSourceTable[key].children
                                                            : null
                                                    }
                                                />
                                            </Row>
                                            <Row style={{ marginTop: "15px" }}>
                                                <Col xs={10} sm={10} md={10} lg={10} xl={16}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "trangThaiBooking"]}
                                                        label={t("status") + " " + "booking"}
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message:
                                                                    t("validate") +
                                                                    " " +
                                                                    t("hotel.priceRoom.applicationTime").toLowerCase(),
                                                            },
                                                        ]}
                                                        valuePropName="checked"
                                                    >
                                                        <Switch
                                                            defaultChecked={
                                                                dataSourceTable.length > 0 && dataSourceTable[key]
                                                                    ? dataSourceTable[key].trangThaiBooking
                                                                    : null
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    ))}
                                    <Row>
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                            <Button onClick={() => add()} block>
                                                {t("add") + " " + t("time").toLowerCase()}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <TextArea
                            value={ghiChu}
                            onChange={(e) => setGhiChu(e.target.value)}
                            placeholder={t("notes")}
                            autoSize={{
                                minRows: 3,
                                maxRows: 5,
                            }}
                        />
                    </Col>
                </Row>
            </>
        );
        return html;
    };
    // const isEmpty = (record) => {
    //     for (const prop in record) {
    //         if (Object.hasOwn(record, prop)) {
    //             return false;
    //         }
    //     }
    //
    //     return true;
    // };

    const formItemLayoutDate = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };

    const handleClear = () => {
        form.resetFields();
    };
    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        setKieuPhongIdErr(false);
        setHangPhongIdtErr(false);
        setMaPhongErr(false);
        var error = false;
        if (!maPhong) {
            setMaPhongErr(true);
            error = true;
        }
        if (!hangPhongId) {
            setHangPhongIdtErr(true);
            error = true;
        }
        if (!kieuPhongId) {
            setKieuPhongIdErr(true);
            error = true;
        }
        console.log("onFinishFailed:", values);
    };
    return (
        <div>
            <Modal
                title={action ? t("edit") + " " + t("informationPriceRoom").toLowerCase() : t("informationPriceRoom")}
                open={isOpen}
                onCancel={handleCancels}
                footer={null}
                width={1200}
                // bodyStyle={{background: "#F5F5F5"}}
            >
                <Divider />
                <Box component="form" noValidate>
                    <Row>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TextField
                                    value={maPhong ? maPhong : ""}
                                    fullWidth
                                    onChange={(e) => setMaPhong(e.target.value)}
                                    helperText={
                                        maPhongErr
                                            ? t("validate") + " " + t("hotel.priceClass.codeRoom").toLowerCase()
                                            : ""
                                    }
                                    error={maPhongErr}
                                    label={t("hotel.priceClass.codeRoom")}
                                ></TextField>
                            </Col>

                            <Row></Row>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        sx={{}}
                                        value={hangPhongId ? hangPhongId : ""}
                                        fullWidth
                                        onChange={(e) => setHangPhongId(e.target.value)}
                                        label={t("hotel.roomClass")}
                                        error={hangPhongIdErr}
                                        select
                                        helperText={
                                            hangPhongIdErr
                                                ? t("validate") + " " + t("hotel.roomClass").toLowerCase()
                                                : ""
                                        }
                                    >
                                        {hangPhong.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        sx={{
                                            marginTop: 2,
                                        }}
                                        value={kieuPhongId ? kieuPhongId : ""}
                                        fullWidth
                                        onChange={(e) => setKieuPhongId(e.target.value)}
                                        label={t("hotel.priceClass.typeRoom")}
                                        error={kieuPhongIdErr}
                                        select
                                        helperText={
                                            hangPhongIdErr
                                                ? t("validate") + " " + t("hotel.roomClass.guestMax").toLowerCase()
                                                : ""
                                        }
                                    >
                                        {kieuPhong.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        sx={{
                                            marginTop: 2,
                                        }}
                                        value={dmTyGiaId ? dmTyGiaId : ""}
                                        fullWidth
                                        onChange={(e) => setDmTyGiaId(e.target.value)}
                                        label={t("exchangeRate.currency")}
                                        select
                                    >
                                        {tiGia.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Box>
                <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    colon={false}
                    // initialValues={{
                    //     items: [{}],
                    // }}
                >
                    {dataRender()}
                    {action ? (
                        <Form.Item
                            wrapperCol={{
                                span: 12,
                                offset: 12,
                            }}
                        >
                            <Space>
                                <Affix offsetBottom={50}>
                                    <div
                                        style={{
                                            textAlign: "center",
                                            color: "#fff",
                                            height: 60,
                                            paddingInline: 50,
                                            lineHeight: "60px",
                                            backgroundColor: "rgba(150, 150, 150, 0.2)",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <Space>
                                            <Button variant="contained" type="submit" startIcon={<Save />}>
                                                {t("action.ok")}
                                            </Button>
                                            <Button
                                                sx={{
                                                    backgroundColor: "#898989",
                                                    color: "white",
                                                }}
                                                key="reset"
                                                startIcon={<RestartAlt />}
                                                onClick={(e) => handleClear(e)}
                                            >
                                                {t("action.reset")}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                startIcon={<Delete />}
                                                onClick={handleCancels}
                                                key="clear"
                                            >
                                                {t("action.cancel")}
                                            </Button>
                                        </Space>
                                    </div>
                                </Affix>
                            </Space>
                        </Form.Item>
                    ) : (
                        ""
                    )}
                </Form>
            </Modal>
        </div>
    );
};
export default ModalPrAction;
