import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Box from "@mui/material/Box";
import {  GridToolbarQuickFilter } from "@mui/x-data-grid";
import {  Col, Popconfirm, Row, Space, Switch, Typography, notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import {API_BASE_URL, TIME_DURATION} from "../../../../../constants";
import FormPR from "./FormPR";
import ModalPrAction from "./ModalPrAction";
import TableMui from "../../../../layout/TableMui";

const PriceRoom = ({vName, dvId, isView}) => {
    const { t, i18n } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [action, setAction] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const isEditing = (record) => record?.key === editingKey;
    const [api, contextHolder] = notification.useNotification();
    const [isReload, setIsReload] = useState(false);
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
    };
    useEffect(() => {
        if (isReload) getData();
    }, [isReload]);
    useEffect(() => {
        getData();
    }, []);
    const getData =() => {
        let data = {
            nccThongTinChungId: dvId,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/giacabin/getData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====", response);
                    const data = response.data.data;
                    const dataLst = [];
                    // const dataLstSearch = [];

                    data.forEach((item, index) => {
                        const { tranxTimeApDungRequests, ...rest } = item;

                        if (tranxTimeApDungRequests.length > 0) {
                            tranxTimeApDungRequests.forEach((detail) => {
                                dataLst.push({
                                    stt: dataLst.length + 1,
                                    rowKey: 1 + Math.random() * (100 - 1),
                                    ...rest,
                                    tranxTimeApDungRequests: tranxTimeApDungRequests.length,
                                    nccLoaiCabinId: detail.nccLoaiCabinId,
                                    idGp: detail.id,
                                    thoiGianApDung: `${detail.tuNgay} - ${detail.denNgay}`,
                                    ...detail,
                                    ids: rest.id,
                                    isDouble: true,
                                });
                            });
                        } else {
                            dataLst.push({
                                stt: dataLst.length + 1,
                                rowKey: index,
                                isDouble: false,
                                ...rest,
                            });
                        }
                    });
                    setIsReload(false);
                    // setDataSearch(dataLstSearch);
                    setDataSource(dataLst);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/login";
                }
            });
    };
    const handleBorderChange = (record) => {
        let values = {
            id: record.idGp,
            trangThaiBooking: record.trangThaiBooking !== true,
            hieuLuc: record.hieuLuc,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/giacabin/updateStatusTranxTime",
            data: values,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                    setIsReload(true)
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                })
            );

        // setDataSource(newData);
    };
    const viewRecord = (record) => {
        setEditingRecord(record);
        setAction(false);
        setIsShow(true);
    };
    const handleDelete = (record) => {
        const newData = dataSource.filter((item) => item.key !== record.key);
        let apiData = {
            id: record.id,
            hieuLuc: record.hieuLuc == true ? false : true,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/giacabin/updateStatus",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                    setIsReload(true)
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                })
            );

        setDataSource(newData);
    };
    const handleCancel = () => {
        setIsShow(false);
    };
    const handleFinish = (status, values) => {
        setIsShow(status);
        setAction(status);
        console.log(JSON.stringify(values));
        let data = {
            ...values,
            id: values.id, // thêm mới để 0, update truyền id đúng vào
        };
        console.log("data edit=====", data);
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/giacabin/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    setIsReload(true);
                    api["success"]({
                        message: t("stour"),
                        description: t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                }else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("edit") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });
    };
    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            width: 60,
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: t("res.code"),
            field: "maDichVu",
            key: "maDichVu",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("res.serviceProvided"),
            field: "tenDichVu",
            key: "tenDichVu",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("roomType"),
            field: "loaiCabin",
            key: "loaiCabin",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.priceRoom.applicationTime"),
            field: "thoiGianApDung",
            key: "thoiGianApDung",
            width: 200,
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "fitNgayThuongNet",
            key: "fitNgayThuongNet",
            align: "center",
            flex: 1,
            headerAlign: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "fitNgayThuongGiaBan",
            key: "fitNgayThuongGiaBan",
            align: "center",
            flex: 1,
            headerAlign: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "extraNgayThuongNet",
            key: "extraNgayThuongNet",
            align: "center",
            flex: 1,
            headerAlign: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "extraNgayThuongGiaBan",
            key: "extraNgayThuongGiaBan",
            align: "center",
            flex: 1,
            headerAlign: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "fitCuoiTuanNet",
            key: "fitCuoiTuanNet",
            align: "center",
            flex: 1,
            headerAlign: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "fitCuoiTuanGiaBan",
            key: "fitCuoiTuanGiaBan",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "extraCuoiTuanNet",
            key: "extraCuoiTuanNet",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "extraCuoiTuanGiaBan",
            key: "extraCuoiTuanGiaBan",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("exchangeRate.currency"),
            field: "loaiTien",
            key: "loaiTien",
            align: "center",
        },
        {
            headerName: t("status"),
            field: "trangThaiBooking",
            key: "trangThaiBooking",
            align: "center",
            renderCell: ({ value, row }) => {
                return <Switch defaultChecked={value} onChange={() => handleBorderChange(row)} />;
            },
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space>
                        {/*<Typography.Link>*/}
                        {/*    <EyeOutlined onClick={() => viewRecord(record.row)} />*/}
                        {/*</Typography.Link>*/}
                        <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record.row)}>
                            <EditOutlined style={{}} />
                        </Typography.Link>
                        <Typography.Link>
                            <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDelete(record.row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                );
            },
            align: "center",
        },
    ];
    const columnGroupingModel = [
        {
            groupId: t("hotel.priceClass.weekdayPrice"),
            children: [
                {
                    groupId: "Fit",
                    children: [{ field: "fitNgayThuongNet" }, { field: "fitNgayThuongGiaBan" }],
                },
                {
                    groupId: "Extra",
                    children: [{ field: "extraNgayThuongNet" }, { field: "extraNgayThuongGiaBan" }],
                },
            ],
        },
        {
            groupId: t("hotel.priceClass.weekendPrice"),
            children: [
                {
                    groupId: "Fit",
                    children: [{ field: "fitCuoiTuanNet" }, { field: "fitCuoiTuanGiaBan" }],
                },
                {
                    groupId: "Extra",
                    children: [{ field: "extraCuoiTuanNet" }, { field: "extraCuoiTuanGiaBan" }],
                },
            ],
        },
    ];
    // const onSearch = (value) => {
    //     let data = []
    //     for (let i in dataSearch) {
    //         if (dataSearch[i].value.toString().toLowerCase().includes(value.toLowerCase())) {
    //             let item = dataSearch[i].value.split(" - ")
    //             data.push({
    //                 stt: i,
    //                 id: item[0],
    //                 hoVaTen: item[1],
    //                 phongBan: item[2],
    //                 chucVu: item[3],
    //                 sdt: item[4],
    //                 email: item[5]
    //             })
    //         }
    //     }
    //     setDataSource(data)
    // }
    const QuickSearchToolbar = () => {
        return (
            <Box
                sx={{
                    p: 0.5,
                    pb: 0,
                }}
            >
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={5}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={4}></Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                        <FormPR dataAdd={dataSource} dvId={dvId} setIsReload={setIsReload}/>
                    </Col>
                </Row>
            </Box>
        );
    };
    return (
        <div>
            {contextHolder}
            <TableMui dataSource={dataSource} columns={columns} edit={edit} QuickSearchToolbar={QuickSearchToolbar} columnGroupingModel={columnGroupingModel}  experimentalFeatures={{ columnGrouping: true }} />
            <ModalPrAction
                isOpen={isShow}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
                record={editingRecord}
                action={action}
                dvId={dvId}
            />
        </div>
    );
};
export default PriceRoom;
