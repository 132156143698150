
import {Box} from "@mui/material";
import { Col, Divider, Form,  Modal, Row} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import ButtonSubmit from "../../../../layout/ButtonSubmit";
import InputMui from "../../../../layout/InputMui";


const ModalLP = ({isOpen, handleCancel, handleFinish}) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const [id, setId] = useState("");
    const [loaiCabin, setLoaiCabin] = useState("");
    const [soLuong, setSoLuong] = useState("");
    const [soKhach, setSoKhach] = useState("");
    const [kichThuoc, setKichThuoc] = useState("");
    const [foc, setFoc] = useState("");
    const [poc, setPoc] = useState("");
    const [ghiChu, setGhiChu] = useState("");
    const [hieuLuc, setHieuLuc] = useState("");
    const [loaiCabinError, setLoaiCabinError] = useState(false);
    const [soLuongError, setSoLuongError] = useState(false);
    const [soKhachError, setSoKhachError] = useState(false);
    const [focError, setFocError] = useState(false);
    const {t} = useTranslation();
    const onFinish = (e) => {
        e.preventDefault();
        setLoaiCabinError(false);
        setSoLuongError(false);
        setSoKhachError(false);
        setSoKhachError(false);
        setFocError(false);
        var error = false;
        if (!loaiCabin) {
            setLoaiCabinError(true);
            error = true;
        }
        if (!soLuong) {
            setSoLuongError(true);
            error = true;
        }
        if (!soKhach) {
            setSoKhachError(true);
            error = true;
        }
        if (!foc) {
            setFocError(true);
            error = true;
        }
        if (error) return;
        var values = {
            id: 0,
            loaiCabin: loaiCabin,
            soLuong: soLuong,
            soKhach: soKhach,
            kichThuoc: kichThuoc,
            foc: foc,
            poc: poc,
            ghiChu: ghiChu,
            hieuLuc: hieuLuc,
        };
        form.resetFields();
        setOpen(false);
        handleFinish(open, values);
    };
    const handleCancels = () => {
        form.resetFields();
        handleCancel();
    };
    const dataRender = () => {
        let html;
        // if (record !== null || record !== '') {
        //     form.setFieldsValue({});
        // }
        html = (
            <>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>

                        <InputMui
                            label={t("roomType")}
                            onChange={(e) => setLoaiCabin(e.target.value)}
                            value={loaiCabin}
                            error={loaiCabinError}
                            helperText={loaiCabinError ? t("validate") + " " + t("roomType").toLowerCase() : ""}
                            required
                            fullWidth
                        ></InputMui>
                    </Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={12}>

                        <InputMui
                            label={t("hotel.roomClass.numberOfRoom")}
                            onChange={(e) => setSoLuong(e.target.value)}
                            value={soLuong}
                            error={soLuongError}
                            helperText={
                                soLuongError
                                    ? t("validate") + " " + t("hotel.roomClass.numberOfRoom").toLowerCase()
                                    : ""
                            }
                            required
                            fullWidth
                        ></InputMui>
                    </Col>

                </Row>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Row>
                            <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                <InputMui
                                    label={t("hotel.roomClass.guestMax")}
                                    onChange={(e) => setSoKhach(e.target.value)}
                                    value={soKhach}
                                    error={soKhachError}
                                    helperText={
                                        soKhachError
                                            ? t("validate") + " " + t("hotel.roomClass.guestMax").toLowerCase()
                                            : ""
                                    }
                                    required
                                    fullWidth
                                ></InputMui>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <InputMui disabled value={"/" + t("room")}></InputMui>
                            </Col>
                        </Row>
                        <Row></Row>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={12}>

                        <InputMui
                            label={t("hotel.roomClass.sizeRoomM2")}
                            onChange={(e) => setKichThuoc(e.target.value)}
                            value={kichThuoc}
                            fullWidth
                        ></InputMui>

                    </Col>
                </Row>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <InputMui
                            label={t("hotel.roomClass.conditionFoc")}
                            onChange={(e) => setFoc(e.target.value)}
                            value={foc}
                            error={focError}
                            helperText={
                                focError ? t("validate") + " " + t("hotel.roomClass.conditionFoc").toLowerCase() : ""
                            }
                            required
                            fullWidth
                        ></InputMui>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <InputMui
                            label={t("hotel.roomClass.foc")}
                            onChange={(e) => setPoc(e.target.value)}
                            value={poc}
                            fullWidth
                        ></InputMui>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <InputMui
                            label={t("notes")}
                            onChange={(e) => setGhiChu(e.target.value)}
                            value={ghiChu}
                            fullWidth
                        ></InputMui>
                    </Col>
                </Row>
            </>
        );
        return html;
    };
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }

        return true;
    };
    const handleClear = () => {
        form.resetFields();
    };
    return (
        <div>
            <Modal
                title={
                    !isEmpty
                        ? t("edit") + " " + t("informationRoomType").toLowerCase()
                        : t("add") + " " + t("informationRoomType").toLowerCase()
                }
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1000}
            >
                <Divider/>
                <Box component="form" noValidate>
                    {dataRender()}
                    <ButtonSubmit onFinish={onFinish} handleClear={handleClear} handleCancels={handleCancels} />
                </Box>
            </Modal>
        </div>
    );
};
export default ModalLP;
