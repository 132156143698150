import { MinusCircleOutlined } from "@ant-design/icons";
import { Card, Col, DatePicker, Divider, Form, Modal, Row, Space, Switch } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import { API_BASE_URL, data_getDM } from "../../../../../constants";
import TableModalGP from "./TableModalGP";

import {Button, MenuItem, TextField} from "@mui/material";

import ButtonSubmit from "../../../../layout/ButtonSubmit";

const { RangePicker } = DatePicker;

const ModalPR = ({ isOpen, handleCancel, handleFinish, dvId }) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [hangPhong, setHangPhong] = useState([]);
    const [tiGia, setTiGia] = useState([]);
    const [dataSource, setDataSource] = useState();
    const [id, setId] = useState(0);
    // const [nccThongTinChungId, setNccThongTinChungId] = useState(0);
    const [maDichVu, setMaDichVu] = useState("");
    const [nccLoaiCabinId, setNccLoaiCabinId] = useState(0);
    const [tenDichVu, setTenDichVu] = useState("");
    const [dmTyGiaId, setDmTyGiaId] = useState(1);
    const [ghiChu, setGhiChu] = useState("");
    const [loaiCabin, setLoaiCabin] = useState(0);

    const [errorId, setErrorId] = useState(false);
    const [errorNccLoaiCabinId, setErrorNccLoaiCabinId] = useState(false);
    const [errorTenDichVu, setErrorTenDichVu] = useState(false);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmTyGia",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].loai_tien,
                            });
                        }
                        setTiGia(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);

    useEffect(() => {
        let data = {
            nccThongTinChungId: dvId,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/loaicabin/getData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    // console.log("Response hangphong: ====" + response.data.data);
                    let dataHP = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataHP.push({
                                value: data[i].id,
                                label: data[i].loaiCabin,
                            });
                        }
                        setHangPhong(dataHP);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const onFinish = (values) => {
        setErrorId(false);
        setErrorNccLoaiCabinId(false);
        setErrorTenDichVu(false);
        var error = false;
        if (!maDichVu) {
            setErrorId(true);
            error = true;
        }
        if (!nccLoaiCabinId) {
            setErrorNccLoaiCabinId(true);
            error = true;
        }
        if (!tenDichVu) {
            setErrorTenDichVu(true);
            error = true;
        }
        if (error) return;
        var tranxTimeApDungRequests = [];
        if (values.items) {
            debugger;
            for (let i = 0; i < values.items.length; i++) {
                const element = values.items[i];
                if (!element.rangeTime) {
                    error = true;
                } else {
                    if (dataSource) {
                        // var index = dataSource.findIndex((item) => item.key === i);
                        tranxTimeApDungRequests.push({
                            tuNgay: element.rangeTime[0].format("DD/MM/YYYY"),
                            denNgay: element.rangeTime[1].format("DD/MM/YYYY"),
                            fitNgayThuongNet: dataSource[0].fit.fitNgayThuongNet,
                            fitNgayThuongGiaBan: dataSource[0].fit.fitNgayThuongGiaBan,
                            fitCuoiTuanNet: dataSource[0].fit.fitCuoiTuanNet,
                            fitCuoiTuanGiaBan: dataSource[0].fit.fitCuoiTuanGiaBan,
                            extraNgayThuongNet: dataSource[1].extra.fitNgayThuongNet,
                            extraNgayThuongGiaBan: dataSource[1].extra.fitNgayThuongGiaBan,
                            extraCuoiTuanNet: dataSource[1].extra.fitCuoiTuanNet,
                            extraCuoiTuanGiaBan: dataSource[1].extra.fitCuoiTuanGiaBan,
                            trangThaiBooking: element.trangThaiBooking || element.trangThaiBooking === undefined,
                        });
                    } else
                        tranxTimeApDungRequests.push({
                            tuNgay: element.rangeTime[0].format("DD/MM/YYYY"),
                            denNgay: element.rangeTime[1].format("DD/MM/YYYY"),
                            trangThaiBooking: element.trangThaiBooking || element.trangThaiBooking === undefined,
                        });
                }
            }
        }
        let data = {
            id: 0,
            nccThongTinChungId: dvId,
            nccLoaiCabinId: nccLoaiCabinId,
            loaiCabin: hangPhong[nccLoaiCabinId - 1].label,
            maDichVu: maDichVu,
            dmTyGiaId: dmTyGiaId ? dmTyGiaId : 1,
            tenDichVu: tenDichVu,
            ghiChu: ghiChu ? ghiChu : "",
            tranxTimeApDungRequests: tranxTimeApDungRequests,
        };

        form.resetFields();
        setOpen(false);
        handleFinish(open, data);
    };
    const handleCancels = () => {
        form.resetFields();
        handleCancel();
    };
    const clearData = () => {
        setDmTyGiaId(0);
        setMaDichVu("");
        setNccLoaiCabinId("");
        setTenDichVu("");
        setGhiChu("");
    };
    const dataRender = () => {
        let html;
        html = (
            <Space direction="vertical" size="small" style={{ display: "flex" }}>
                <Form.Item name="id" hidden></Form.Item>
                <Form.Item name="nccThongTinChungId" hidden></Form.Item>
                <div className="">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.List name="items">
                                {(fields, { add, remove }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            rowGap: 16,
                                            flexDirection: "column",
                                        }}
                                    >
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Card size="large" key={key}>
                                                <Row>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "id"]}
                                                        hidden={true}
                                                    ></Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "nccGiaCabinId"]}
                                                        hidden={true}
                                                    ></Form.Item>
                                                    <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                                        <Form.Item
                                                            label={t("hotel.priceRoom.applicationTime")}
                                                            {...formItemLayoutDate}
                                                            {...restField}
                                                            name={[name, "rangeTime"]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <RangePicker
                                                                style={{ width: "100%" }}
                                                                placeholder={[t("toDate"), t("fromDate")]}
                                                                disabledDate={(current) => {
                                                                    let customDate = moment().format("DD/MM/YYYY");
                                                                    return (
                                                                        current &&
                                                                        current < moment(customDate, "DD/MM/YYYY")
                                                                    );
                                                                }}
                                                                format="DD/MM/YYYY"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={5} sm={5} md={5} lg={5} xl={5}></Col>
                                                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                                        <MinusCircleOutlined
                                                            className="dynamic-delete-button"
                                                            onClick={() => remove(name)}
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <TableModalGP
                                                        style={{ width: "100%" }}
                                                        keyNum={key}
                                                        setDataEdit={setDataSource}
                                                        dataEdit={dataSource}
                                                    />
                                                </Row>
                                                <Row>
                                                    <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, "trangThaiBooking"]}
                                                            label={t("status") + " " + "booking"}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                            valuePropName="checked"
                                                        >
                                                            <Switch defaultChecked={true}/>
                                                        </Form.Item>

                                                    </Col>
                                                </Row>

                                            </Card>
                                        ))}
                                        <Row>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <Button type="primary" onClick={() => add()} block>
                                                    {t("add") + " " + t("time").toLowerCase()}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Form.List>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <TextField
                                sx={{
                                    marginBottom: 2,
                                    marginTop: 2,
                                }}
                                value={ghiChu}
                                fullWidth
                                onChange={(e) => setGhiChu(e.target.value)}
                                multiline
                                rows={5}
                                label={t("notes")}
                            ></TextField>
                        </Col>
                    </Row>
                </div>
            </Space>
        );
        return html;
    };

    const dataRenderMui = () => {
        return (
            <>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={10}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    onChange={(e) => setMaDichVu(e.target.value)}
                                    value={maDichVu}
                                    error={errorId}
                                    helperText={errorId ? t("validate") + " " + t("res.code").toLowerCase() : ""}
                                    required
                                    fullWidth
                                    label={t("res.code")}
                                ></TextField>
                            </Col>
                        </Row>
                        <Row></Row>
                    </Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={10}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    value={nccLoaiCabinId}
                                    error={errorNccLoaiCabinId}
                                    helperText={
                                        errorNccLoaiCabinId ? t("validate") + " " + t("roomType").toLowerCase() : ""
                                    }
                                    required
                                    fullWidth
                                    onChange={(e) => setNccLoaiCabinId(e.target.value)}
                                    select
                                    label={t("roomType")}
                                >
                                    {hangPhong.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={10}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    value={tenDichVu}
                                    error={errorTenDichVu}
                                    onChange={(e) => setTenDichVu(e.target.value)}
                                    helperText={
                                        errorTenDichVu
                                            ? t("validate") + " " + t("res.serviceProvided").toLowerCase()
                                            : ""
                                    }
                                    required
                                    fullWidth
                                    label={t("res.serviceProvided")}
                                ></TextField>
                            </Col>
                        </Row>
                        <Row></Row>
                    </Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={10}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    value={dmTyGiaId}
                                    required
                                    fullWidth
                                    select
                                    onChange={(e) => setDmTyGiaId(e.target.value)}
                                    label={t("exchangeRate.currency")}
                                >
                                    {tiGia.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    };
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }

        return true;
    };

    const formItemLayoutDate = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };
    const handleClear = () => {
        clearData();
    };

    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        setErrorId(false);
        setErrorNccLoaiCabinId(false);
        setErrorTenDichVu(false);
        if (!maDichVu) {
            setErrorId(true);
        }
        if (!nccLoaiCabinId) {
            setErrorNccLoaiCabinId(true);
        }
        if (!tenDichVu) {
            setErrorTenDichVu(true);
        }
    };
    return (
        <div>
            <Modal
                title={
                    !isEmpty
                        ? t("edit") + " " + t("informationRoomClass").toLowerCase()
                        : t("add") + " " + t("informationRoomClass").toLowerCase()
                }
                open={isOpen}
                // onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1200}
            >
                <Divider />
                {dataRenderMui()}
                <Form
                    form={form}
                    onFinishFailed={onFinishFailed}
                    onFinish={onFinish}
                    colon={false}
                    noValidate
                    {...formItemLayout}
                    initialValues={{
                        dmTyGiaId: 1,
                        items: [{}],
                    }}
                >
                    {dataRender()}
                    <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 10,
                        }}
                    >
                        <ButtonSubmit onFinish={onFinish} handleClear={handleClear} handleCancels={handleCancels} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default ModalPR;
