import React from "react";
import TextField from "@mui/material/TextField";

const InputMui = ({
    label,
    name,
    value,
    onChange,
    errorText,
    required = false,
    fullWidth = true,
    inputProps = {},
    ...rest
}) => {
    return (
        <TextField
            sx={{
                marginBottom: 2,
                "& .MuiInputLabel-root": {
                    fontSize: "14px",
                },
            }}
            size="small"
            name={name}
            fullWidth={fullWidth}
            required={required}
            label={label}
            value={value}
            onChange={onChange}
            error={!!errorText}
            helperText={errorText}
            inputProps={{
                style: { fontSize: "13px" },
                ...inputProps,
            }}
            {...rest} // Truyền các props còn lại
        />
    );
};

export default InputMui;
