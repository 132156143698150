import { PlusOutlined } from "@ant-design/icons";
import { MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Col, Form, Input, Modal, Row, Space, Upload, message, Select, Tooltip } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import { API_BASE_URL, maxFile } from "../../../../../constants";
import { IconButton, InputAdornment } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "../../../../../assets/css/AntPlaceholder.css";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const InfoCard = ({ dataQuocGia, dmLoaiTheHdvId, restField, onCopy, copied, quocGiaId, quocGiaName }) => {
    const [dataTinhTP, setDataTinhTp] = useState([]);
    const [dataNgonNgu, setDataNgonNgu] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [noiCap, setNoiCap] = useState("");
    const [previewOpen, setPreviewOpen] = useState(false);
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };
    const { t } = useTranslation();
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmNgoaiNgu",
            headers: authHeader(),
        })
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data.data.filter(item => item.hieu_luc).map(item => ({
                        value: item.id,
                        label: item.ngoai_ngu,
                    }));
                    setDataNgonNgu(data);
                }
            })
            .catch((error) => {
                console.error("Error fetching languages:", error);
            });
    }, []);
    useEffect(() => {
        handleChangeQG(quocGiaName, quocGiaId);
    }, [quocGiaId]);
    const handleChangeQG = (quocGiaName, quocGiaId) => {
        debugger;
        setNoiCap(quocGiaName);
        // setQuocGiaId(item.props.value)
        let data = {
            dm_quoc_gia_id: quocGiaId,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachTinhThanhPho",
            data: data,
            headers: authHeader(),
        }).then(function (response) {
            if (response.status === 200) {
                let dataTp = [];
                let data = response.data.data;
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc === true) {
                            dataTp.push({
                                value: data[i].id,
                                label: data[i].tinh_thanhpho,
                            });
                        }
                    }
                    setDataTinhTp(dataTp);
                }
            }
        });
    };

    const onChangeTinhTP = (value, item) => {
        // setTinhTP(item.props.value);
        setNoiCap(noiCap + " " + item.props.children);
    }

    const handleChangeImg = ({ fileList: newFileList }) => {
        //console.log("handleChangeImg ====", newFileList);

        if (newFileList.length > 0) {
            for (let i in newFileList) {
                newFileList[i].status = "done";
            }
        }
        setFileList(newFileList);
    };
    const beforeUploadImg = (file) => {
        const isPNG =
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/giff";
        if (!isPNG) {
            message.error(`${file.name} ` + " " + t("errorUploadImg"));
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
            message.error("Image must smaller than 3MB!");
        }

        return isPNG || Upload.LIST_IGNORE;
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const checkThe = () => {
        const renderSelect = (rules = [{ required: true, message: "" }], nameKey = "dmNgoaiNguId") => (
            <Form.Item {...restField} name={[restField.name, nameKey]} rules={rules}
            >
                <Select
                    mode="multiple"
                    placeholder={t("language") + " *"}
                >
                    {dataNgonNgu.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        );

        const renderTextField = (rules = [], label = t("touristAttraction")) => (
            <Form.Item {...restField} name={[restField.name, "diemDuLich"]} rules={rules}>
                <TextField
                    sx={{
                        marginBottom: 2,
                        "& .MuiInputLabel-root": {
                            fontSize: "14px",
                        },
                    }}
                    inputProps={{
                        style: { fontSize: "13px" }
                    }}
                    size="small"
                    required
                    fullWidth
                    label={label}
                />
            </Form.Item>
        );

        switch (dmLoaiTheHdvId) {
            case 1:
                return renderSelect();
            case 2:
                return renderSelect([
                    {
                        required: true,
                        message: `${t("validate")} ${t("language").toLowerCase()}`,
                    },
                ]);
            case 3:
                return renderTextField([
                    {
                        required: true,
                        message: `${t("validate")} ${t("location").toLowerCase()}`,
                    },
                ]);
            default:
                return null;
        }
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                {dmLoaiTheHdvId === 1 ? "Upload Image" : "Upload Card"}
            </div>
        </div>
    );

    return (
        <Space direction="vertical" size="small" style={{ display: "flex" }}>
            <Form.Item {...restField} name={[restField.name, "dataNoiCap"]} hidden>
                <Input value={noiCap} />
            </Form.Item>
            <Row>
                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                            <Form.Item  {...restField} name={[restField.name, "soThe"]}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            t("validate") +
                                            " " +
                                            t("card.code").toLowerCase(),
                                    },
                                ]}>
                                <TextField
                                    label={t("card.code")}
                                    fullWidth
                                    size="small"
                                    sx={{
                                        marginBottom: 2,
                                        "& .MuiInputLabel-root": {
                                            fontSize: "14px",
                                        },
                                    }}
                                    inputProps={{
                                        style: { fontSize: "13px" }
                                    }}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title={copied ? "Copied!" : "Copy"}>
                                                    <IconButton onClick={() => onCopy("soThe")} edge="end">
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}
                            >
                                <Form.Item {...restField} name={[restField.name, "ngayHetHan"]}>
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        defaultValue={null}
                                        label={t("expirationDate")}
                                        slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                        sx={{
                                            marginBottom: 2,
                                            "& .MuiInputLabel-root": {
                                                fontSize: "14px",
                                            },
                                            "& .MuiInputBase-input": {
                                                fontSize: "13px",
                                            },
                                            "& .MuiInputBase-input::placeholder": {
                                                fontSize: "13px",
                                            },
                                            "& .Mui-error": {
                                                color: "rgba(0, 0, 0, 0.6)",
                                            },
                                            "& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "rgba(0, 0, 0, 0.23)",
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </LocalizationProvider>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                            <Form.Item  {...restField} name={[restField.name, "quocGiaId"]}>
                                <TextField
                                    sx={{
                                        borderRadius: "4px",
                                        "& .MuiInputLabel-root": {
                                            fontSize: "14px",
                                        },
                                        "& .MuiInputBase-input": {
                                            fontSize: "13px",
                                        },
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            // sx: { height: "300px" },
                                            PaperProps: {
                                                sx: {
                                                    "& .MuiMenuItem-root": {
                                                        fontSize: "13px",
                                                    },
                                                    height: "300px"
                                                },
                                            },
                                        },
                                    }}
                                    size="small"
                                    label={t("location.country")}
                                    select
                                    fullWidth
                                    name="quocGiaId"
                                    onChange={(e) => handleChangeQG(e.target.name, e.target.value)}
                                >
                                    {dataQuocGia.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Form.Item>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                            <Form.Item  {...restField} name={[restField.name, "tinhId"]}>
                                <TextField
                                    sx={{
                                        borderRadius: "4px",
                                        "& .MuiInputLabel-root": {
                                            fontSize: "14px",
                                        },
                                        "& .MuiInputBase-input": {
                                            fontSize: "13px",
                                        },
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            // sx: { height: "300px" },
                                            PaperProps: {
                                                sx: {
                                                    "& .MuiMenuItem-root": {
                                                        fontSize: "13px",
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                    size="small"
                                    label={t("location.city")}
                                    select
                                    fullWidth
                                    onChange={onChangeTinhTP}
                                >
                                    {dataTinhTP.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "15px" }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                            {checkThe()}
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={12} md={24} lg={24} xl={11}>
                            <Form.Item  {...restField} name={[restField.name, "loaiTheHdv"]}>
                                <TextField
                                    fullWidth
                                    disabled
                                    label={t("cardType")}
                                    size="small"
                                    sx={{
                                        marginBottom: 2,
                                        "& .MuiInputLabel-root": {
                                            fontSize: "14px",
                                        },
                                    }}
                                    inputProps={{
                                        style: { fontSize: "13px" }
                                    }}

                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                            <Form.Item  {...restField} name={[restField.name, "kinhNghiem"]}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    label={t("experience")}
                                    size="small"
                                    sx={{
                                        marginBottom: 2,
                                        "& .MuiInputLabel-root": {
                                            fontSize: "14px",
                                        },
                                    }}
                                    inputProps={{
                                        style: { fontSize: "13px" }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                            <Form.Item  {...restField} name={[restField.name, "linkTraCuu"]}>
                                <TextField
                                    fullWidth
                                    label={t("lookupLink")}
                                    size="small"
                                    sx={{
                                        marginBottom: 2,
                                        "& .MuiInputLabel-root": {
                                            fontSize: "14px",
                                        },
                                    }}
                                    inputProps={{
                                        style: { fontSize: "13px" }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title={copied ? "Copied!" : "Copy"}>
                                                    <IconButton onClick={() => onCopy("linkTraCuu")} edge="end">
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Row>
                        <Form.Item {...restField} name={[restField.name, "images"]} valuePropName="fileList" getValueFromEvent={(e) => {
                            return e?.fileList;
                        }}>
                            <Upload
                                beforeUpload={beforeUploadImg}
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChangeImg}
                            >
                                {fileList.length >= maxFile ? null : uploadButton}
                            </Upload>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                <img
                                    alt="image"
                                    style={{
                                        width: "100%",
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                        </Form.Item>
                    </Row>
                </Col>
            </Row>
        </Space>
    );
};
export default InfoCard;
