import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import TourService from "../../../../../api/tour/tour.service";
import { useNavigate } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TIME_DURATION } from "../../../../../constants";

ClassicEditor.defaultConfig = {
    toolbar: {
        items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
        ],
    },
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "en",
};

export default function ThongTinTour_new({ dvName, dvId, addId, isView }) {
    const { t, i18n } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);
    const [open6, setOpen6] = React.useState(false);
    const [gioiThieuTour, setGioiThieuTour] = React.useState("");
    const [baoGom, setBaoGom] = React.useState("");
    const [khongBaoGom, setKhongBaoGom] = React.useState("");
    const [chinhSachHuy, setChinhSachHuy] = React.useState("");
    const [mangTheo, setMangTheo] = React.useState("");
    const [chinhSachHoanTien, setChinhSachHoanTien] = React.useState("");
    const [chinhSachXacNhan, setChinhSachXacNhan] = React.useState("");
    useEffect(() => {
        let data = {
            tourThongTinChungId: dvId,
        };
        TourService.getThongTinTour(data)
            .then(function (response) {
                console.log("Response: ====", response.data);
                if (response.status === 200) {
                    let data = response.data.data;
                    setGioiThieuTour(data.gioiThieuTour);
                    setBaoGom(data.baoGom);
                    setKhongBaoGom(data.khongBaoGom);
                    setChinhSachHuy(data.chinhSachHuy);
                    setMangTheo(data.mangTheo);
                    setChinhSachHoanTien(data.chinhSachHoanTien);
                    setChinhSachXacNhan(data.chinhSachXacNhan);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);
    const direct = (record, status) => {
        return navigate("/tour-san-pham/" + status + "?id=" + record.id);
    };
    const saveData = () => {
        let data = {
            tourThongTinChungId: dvId,
            gioiThieuTour: gioiThieuTour,
            baoGom: baoGom,
            khongBaoGom: khongBaoGom,
            chinhSachHuy: chinhSachHuy,
            mangTheo: mangTheo,
            chinhSachHoanTien: chinhSachHoanTien,
            chinhSachXacNhan: chinhSachXacNhan,
        };
        console.log(JSON.stringify(data));
        TourService.saveThongTinTour(data)
            .then(function (response) {
                console.log("Response: ====" + response.data);
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
                if (response.status === 401) {
                }
            });
    };
    return (
        <TableContainer component={Paper}>
            {contextHolder}
            <Table aria-label="collapsible table">
                <TableBody>
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                        <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <RemoveCircleIcon /> : <AddCircleIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row" align="left">
                            {t("tour.gioiThieu")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                    <div
                                        style={{
                                            height: "50px",
                                            borderRadius: "6px",
                                            border: "1px solid #d9d9d9",
                                        }}
                                    >
                                        <SaveIcon onClick={saveData} />
                                    </div>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={gioiThieuTour}
                                        onReady={(editor) => {
                                            // You can store the "editor" and use when it is needed.
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setGioiThieuTour(data);
                                        }}
                                    />
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                        <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen1(!open1)}>
                                {open1 ? <RemoveCircleIcon /> : <AddCircleIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {t("tour.baoGom")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open1} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                    <div
                                        style={{
                                            height: "50px",
                                            borderRadius: "6px",
                                            border: "1px solid #d9d9d9",
                                        }}
                                    >
                                        <SaveIcon onClick={saveData} />
                                    </div>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={baoGom}
                                        onReady={(editor) => {
                                            // You can store the "editor" and use when it is needed.
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setBaoGom(data);
                                        }}
                                    />
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                        <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen2(!open2)}>
                                {open2 ? <RemoveCircleIcon /> : <AddCircleIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {t("tour.khongBaoGom")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open2} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                    <div
                                        style={{
                                            height: "50px",
                                            borderRadius: "6px",
                                            border: "1px solid #d9d9d9",
                                        }}
                                    >
                                        <SaveIcon onClick={saveData} />
                                    </div>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={khongBaoGom}
                                        onReady={(editor) => {
                                            // You can store the "editor" and use when it is needed.
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setKhongBaoGom(data);
                                        }}
                                    />
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                        <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen3(!open3)}>
                                {open3 ? <RemoveCircleIcon /> : <AddCircleIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {t("tour.chinhSachHuy")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open3} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                    <div
                                        style={{
                                            height: "50px",
                                            borderRadius: "6px",
                                            border: "1px solid #d9d9d9",
                                        }}
                                    >
                                        <SaveIcon onClick={saveData} />
                                    </div>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={chinhSachHuy}
                                        onReady={(editor) => {
                                            // You can store the "editor" and use when it is needed.
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setChinhSachHuy(data);
                                        }}
                                    />
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                        <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen4(!open4)}>
                                {open4 ? <RemoveCircleIcon /> : <AddCircleIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {t("tour.mangTheo")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open4} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                    <div
                                        style={{
                                            height: "50px",
                                            borderRadius: "6px",
                                            border: "1px solid #d9d9d9",
                                        }}
                                    >
                                        <SaveIcon onClick={saveData} />
                                    </div>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={mangTheo}
                                        onReady={(editor) => {
                                            // You can store the "editor" and use when it is needed.
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setMangTheo(data);
                                        }}
                                    />
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                        <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen5(!open5)}>
                                {open5 ? <RemoveCircleIcon /> : <AddCircleIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {t("tour.chinhSachHoanTien")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open5} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                    <div
                                        style={{
                                            height: "50px",
                                            borderRadius: "6px",
                                            border: "1px solid #d9d9d9",
                                        }}
                                    >
                                        <SaveIcon onClick={saveData} />
                                    </div>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={chinhSachHoanTien}
                                        onReady={(editor) => {
                                            // You can store the "editor" and use when it is needed.
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setChinhSachHoanTien(data);
                                        }}
                                    />
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                        <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen6(!open6)}>
                                {open6 ? <RemoveCircleIcon /> : <AddCircleIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {t("tour.chinhSachXacNhan")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open6} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                    <div
                                        style={{
                                            height: "50px",
                                            borderRadius: "6px",
                                            border: "1px solid #d9d9d9",
                                        }}
                                    >
                                        <SaveIcon onClick={saveData} />
                                    </div>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={chinhSachXacNhan}
                                        onReady={(editor) => {
                                            // You can store the "editor" and use when it is needed.
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setChinhSachXacNhan(data);
                                        }}
                                    />
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
