import React, {  } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

// const { Search } = Input;

const FormTourSp = ({handleSearch }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const onClickAdd = () => {
        return navigate("/tour-san-pham/new");
    };
    const onSearch = (value) => {
        handleSearch(value);
    };
    return (
        <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}></Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4}></Col>
            <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                {/*<Space size="large">*/}
                {/*<Button icon={<DownloadOutlined/>} type="default">*/}
                {/*    {t("exportFile")}*/}
                {/*</Button>*/}
                {/*<Button icon={<ImportOutlined/>} type="dashed">*/}
                {/*    {t("importFile")}*/}
                {/*</Button>*/}
                <Button sx={{marginTop:"5px"}} variant="outlined" onClick={onClickAdd} startIcon={<AddIcon />} color="warning">
                    {t("add")}
                </Button>
                {/*</Space>*/}
            </Col>
        </Row>
    );
};
export default FormTourSp;
