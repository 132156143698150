import { useTranslation } from "react-i18next";
import React from "react";
import { TextField } from "@mui/material";

const TextFieldNumber = ({ name, label, value, onChange }) => {
    const { t } = useTranslation();
    return (
        <TextField
            fullWidth
            label={t(label)}
            name={name}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        />
    );
};
export default TextFieldNumber;
