import {Affix, Space} from "antd";

import React from "react";
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";
import Save from "@mui/icons-material/Save";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Delete from "@mui/icons-material/Delete";
import { styled } from '@mui/system';

const ButtonSubmit = ({ onFinish, handleClear, handleCancels, isVisible = true }) => {
    const { t } = useTranslation();
    // Không render nếu isVisible là false
    if (!isVisible) return null;
    return (
        <Affix offsetTop={50} style={{right: 30, position: 'fixed'}}>
            {/*<div*/}
            {/*    style={{*/}
            {/*        textAlign: "center",*/}
            {/*        color: "#fff",*/}
            {/*        height: 60,*/}
            {/*        paddingInline: 50,*/}
            {/*        lineHeight: "60px",*/}
            {/*        backgroundColor: "rgba(150, 150, 150, 0.2)",*/}
            {/*        borderRadius: "4px",*/}
            {/*    }}*/}
            {/*>*/}
            {/*    */}
            {/*</div>*/}
            <Space>

                <Button
                    sx={{
                        backgroundColor: "#825c59",
                        color: "white",
                        fontSize:"13px"
                    }}
                    variant="outlined"
                    startIcon={<Delete />}
                    onClick={handleCancels}
                    key="cancel" // Unique key
                >
                    {t("action.cancel")}
                </Button>
                <Button
                    sx={{
                        backgroundColor: "#898989",
                        color: "white",
                        fontWeight:"600",
                        fontSize:"13px"
                    }}
                    key="reset" // Unique key
                    startIcon={<RestartAlt />}
                    onClick={handleClear}
                >
                    {t("action.reset")}
                </Button>
                <Button
                    sx={{
                        backgroundColor: "#007FFF",
                        fontSize:"13px"
                    }}
                    variant="contained"
                    type="submit"
                    startIcon={<Save />}
                    key="submit" // Unique key
                    onClick={onFinish}
                >
                    {t("action.ok")}
                </Button>
            </Space>
        </Affix>
    );
};
export default ButtonSubmit;
