import React, {useEffect} from "react";
import {Row} from "antd";
import NhaHang from "./dichVu/NhaHang";
import TongKet from "./TongKet";
import {useTranslation} from "react-i18next";
import VanChuyenNew from "./dichVu/VanChuyenNew";
import KhachSanNew from "./dichVu/KhachSanNew";
import DichVuCommon from "./dichVu/DichVuCommon";

const ChildrenTab = ({
                         dataSource,
                         changeTk,
                         tyGia,
                         onReset,
                         keyTab,
                         tourThongTinChungId,
                         tourThoiGianApDungId,
                         tourChietTinhId,
                         tabChietTinhId,
                         tongSoNgay,
                         loaiTong,
                         tourQuanLyKhoangKhachId
                     }) => {
    const {t} = useTranslation();
    const [tyGiaTab, setTyGiaTab] = React.useState(tyGia);
    const handleChangeTk = (value) => {
        changeTk(value);
    };
    useEffect(() => {
        setTyGiaTab(tyGia);
    }, [tyGia]);
    return (
        <div>
            <Row>
                <VanChuyenNew
                    dataSource={dataSource.vanChuyenValues}
                    tongSoNgay={tongSoNgay}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    tongVanChuyen={dataSource.tongVanChuyen}
                    tyGia={tyGiaTab}
                    onReset={onReset}
                    tourChuongTrinh={dataSource.tourChuongTrinh}
                    activeKey={keyTab}
                    tourThongTinChungId={tourThongTinChungId}
                    tourThoiGianApDungId={tourThoiGianApDungId}
                    tourChietTinhId={tourChietTinhId}
                    tabChietTinhId={tabChietTinhId}
                    loaiTong={loaiTong}
                    tourQLkcID={tourQuanLyKhoangKhachId}
                    thoiGianApDung={dataSource.thoiGianApDung}
                />
            </Row>
            <Row style={{marginTop: "2%"}}>
                <KhachSanNew
                    dataSource={dataSource.khachSanResponse}
                    tongSoNgay={tongSoNgay}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    tongChietTinhKhachSanResponse={
                        dataSource.tongChietTinhKhachSanResponse
                    }
                    tyGia={tyGiaTab}
                    onReset={onReset}
                    tourChuongTrinh={dataSource.tourChuongTrinh}
                    activeKey={keyTab}
                    tourThongTinChungId={tourThongTinChungId}
                    tourThoiGianApDungId={tourThoiGianApDungId}
                    tourChietTinhId={tourChietTinhId}
                    tabChietTinhId={tabChietTinhId}
                    loaiTong={loaiTong}
                    tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                    thoiGianApDung={dataSource.thoiGianApDung}
                />
            </Row>
            <Row style={{marginTop: "2%"}}>
                <NhaHang
                    dataSource={dataSource.nhaHangResponse}
                    tongSoNgay={tongSoNgay}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    tongChietTinhNhaHangResponse={dataSource.tongChietTinhNhaHangResponse}
                    tyGia={tyGiaTab}
                    onReset={onReset}
                    tourChuongTrinh={dataSource.tourChuongTrinh}
                    activeKey={keyTab}
                    tourThongTinChungId={tourThongTinChungId}
                    tourThoiGianApDungId={tourThoiGianApDungId}
                    tourChietTinhId={tourChietTinhId}
                    tabChietTinhId={tabChietTinhId}
                    loaiTong={loaiTong}
                    tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                    thoiGianApDung={dataSource.thoiGianApDung}
                />
            </Row>
            {/* <Row style={{marginTop: "2%"}}>
                <DichVuCommon
                    dataSource={dataSource.khachSanResponse}
                    tongSoNgay={tongSoNgay}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    tongChietTinhResponse={dataSource.tongChietTinhKhachSanResponse}
                    tyGia={tyGiaTab}
                    onReset={onReset}
                    tourChuongTrinh={dataSource.tourChuongTrinh}
                    activeKey={keyTab}
                    tourThongTinChungId={tourThongTinChungId}
                    tourThoiGianApDungId={tourThoiGianApDungId}
                    tourChietTinhId={tourChietTinhId}
                    tabChietTinhId={tabChietTinhId}
                    loaiTong={loaiTong}
                    tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                    thoiGianApDung={dataSource.thoiGianApDung}
                    tranxChietTinhId={"tranxKhachSanId"}
                    chietTinh={"khachSan"}
                />
            </Row> */}
            <Row style={{marginTop: "2%"}}>
                <TongKet
                    tongChietTinhResponses={dataSource.tongChietTinhResponses}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    changeTongKet={handleChangeTk}
                    tyGia={tyGiaTab}
                    activeKey={keyTab}
                />
            </Row>
        </div>
    );
};
export default ChildrenTab;
