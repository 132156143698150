import React from "react";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { useTranslation } from "react-i18next";

const CurrencyField = ({ name, label, value, onChange, currencySymbol, disabled, ...rest }) => {
    const { t } = useTranslation();
    return (
        <CurrencyTextField
            fullWidth
            label={t(label)}
            name={name}
            value={parseInt(value)}
            currencySymbol={currencySymbol ? currencySymbol : null}
            disabled={disabled}
            onChange={onChange}
            decimalPlaces={2}
            {...rest}
        />
    );
};
export default CurrencyField;
