import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalNccOto from "../chietTinhXeOto/ModalNccOto";
import ModalChietTinhCommon from "../modal/ModalChietTinhCommon";
import "../../../../../assets/css/chietTinh/vanChuyen/vanChuyen.css";
import { Col, Row, Typography, Tooltip, Popconfirm, notification } from "antd";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import { DV_XE_OTO, soChoPax, TIME_DURATION } from "../../../../../constants";
import otoService from "../../../../../api/tour/oto/oto.service";

const { Link } = Typography;

const VanChuyenNew = ({
    dataSource,
    tongSoNgay,
    danhSachKhoangKhach,
    tongVanChuyen,
    tyGia,
    onReset,
    tourChuongTrinh,
    tourThongTinChungId,
    tourThoiGianApDungId,
    tourChietTinhId,
    tabChietTinhId,
    loaiTong,
    tourQLkcID,
    thoiGianApDung,
}) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isOpenAll, setIsOpenAll] = useState(false);
    const [items, setItems] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [tyGiaTab, setTyGiaTab] = React.useState(0);
    // const [nhaCC, setNhaCC] = useState([]);
    const [tongGiaDichVu, setTongGiaDichVu] = useState({});
    const [tourMapChietTinhChuongTrinhId, setTourMapChietTinhChuongTrinhId] = useState(0);
    const [groupBy, setGroupBy] = useState(0);
    const [tranxChietTinhOtoId, setTranxChietTinhOtoId] = useState(0);
    const [tourQuanLyKhoangKhachId, setTourQuanLyKhoangKhachId] = useState(0);
    const [day, setDay] = useState();
    const [tourChuongTrinhId, setTourChuongTrinhId] = useState();
    const [tourQuanLyKhoangKhach, setTourQuanLyKhoangKhach] = useState([]);
    const [checkThemMoi, setcheckThemMoi] = useState(true);
    const [thoiGianAD, setThoiGianAD] = useState(null);
    const [sums, setSums] = useState({
        id: "SUBTOTAL",
        label: t("tour.vc.note"),
        taxRate: t("tour.tong"),
        soLuong: 1,
    });
    const [idDsKhoangKhach, setIdDsKhoangKhach] = useState([]);
    const columns = [
        {
            headerName: t("day"),
        },
        {
            headerName: t("supplier"),
        },
        {
            headerName: t("tour.vanChuyen.soXe"),
        },
        {
            headerName: t("tour.vanChuyen.soLuong"),
        },
    ];
    const [columnTb, setColumnTb] = useState(columns);
    const dvExists = (arr, tenDichVu) => {
        return arr.some(function (el) {
            return el.tenDichVu === tenDichVu;
        });
    };
    useEffect(() => {
        if (tourQLkcID !== 0 && tourThoiGianApDungId !== 0) {
            setcheckThemMoi(false);
        } else {
            setcheckThemMoi(true);
        }
        setTyGiaTab(tyGia);
        let days = [];
        let column = [];
        if (tongSoNgay !== undefined) {
            if (
                tourThoiGianApDungId !== 0 &&
                tourThoiGianApDungId !== undefined &&
                thoiGianApDung !== null &&
                thoiGianApDung.length > 0
            ) {
                let tg = thoiGianApDung.filter((item) => item.id === tourThoiGianApDungId);
                setThoiGianAD({
                    id: tg[0].id,
                    tourChietTinhId: tg[0].tourChietTinhId,
                    tuNgay: tg[0].tuNgay,
                    denNgay: tg[0].denNgay,
                });
            }
            for (let i = 1; i <= tongSoNgay; i++) {
                let tourCT = tourChuongTrinh.find((ct) => parseInt(ct.ngay) === i);
                if (dvExists(tourCT.dmDichVus, DV_XE_OTO)) {
                    let source = dataSource?.find((dt) => parseInt(dt.ngay) === i);
                    let item = [];
                    let id = days.length + i;
                    if (source && source.nhaCungCaps) {
                        for (let k = 0; k < source.nhaCungCaps.length; k++) {
                            let element = source.nhaCungCaps[k];
                            if (k === 0)
                                item.push({
                                    ...element,
                                    id: id,
                                    item: i,
                                    tourChuongTrinhId: tourCT.id,
                                    rowSpan: source.nhaCungCaps.length + 1,
                                });
                            else
                                item.push({
                                    ...element,
                                    id: id,
                                    item: i,
                                    tourChuongTrinhId: tourCT.id,
                                    rowSpan: 0,
                                });
                            id++;
                        }
                        item.push({
                            id: "ADD_NEW" + id,
                            item: i,
                            tourChuongTrinhId: tourCT.id,
                        });
                        id++;
                    } else {
                        item.push({
                            id: "ADD_NEW" + id,
                            item: i,
                            tourChuongTrinhId: tourCT.id,
                            rowSpan: 1,
                        });
                        id++;
                    }
                    days = [...days, ...item];
                }
            }
        }
        if (tongVanChuyen !== undefined) {
            setSums({
                id: "SUBTOTAL",
                label: t("tour.vc.note"),
                taxRate: t("tour.tong"),
                soLuong: tongVanChuyen && tongVanChuyen.soLuong !== 0 ? tongVanChuyen.soLuong : 1,
                tongGiaDichVu: tongVanChuyen?.tongGiaDichVu ? tongVanChuyen?.tongGiaDichVu : 0,
            });
        }
        if (danhSachKhoangKhach !== undefined && danhSachKhoangKhach.length > 0) {
            let idKhoangKhach = [];
            let tongGiaDichVuInit = {};
            for (let j = 0; j < danhSachKhoangKhach.length; j++) {
                let checkVal = column.findIndex((c) => c.field === "giaDichVu" + danhSachKhoangKhach[j].id);
                if (checkVal === -1)
                    column.push({
                        headerName:
                            danhSachKhoangKhach[j].dmLoaiKhachId === 1
                                ? danhSachKhoangKhach[j].soKhachToiThieu + "Pax" + "/" + danhSachKhoangKhach[j].dmSoCho
                                : danhSachKhoangKhach[j].soKhachToiThieu +
                                  "-" +
                                  danhSachKhoangKhach[j].soKhachToiDa +
                                  "Pax" +
                                  "/" +
                                  danhSachKhoangKhach[j].dmSoCho,
                    });
                idKhoangKhach.push(danhSachKhoangKhach[j].id);
                tongGiaDichVuInit[danhSachKhoangKhach[j].id] = 0;
            }
            setIdDsKhoangKhach(idKhoangKhach);
            setColumnTb([...columns, ...column]);
            setTongGiaDichVu(tongGiaDichVuInit);
        }
        setItems(days);
    }, [tyGiaTab, tourChuongTrinh, tourThoiGianApDungId, tourQLkcID]);
    const isNumeric = (str) => {
        if (typeof str != "string") return false; // we only process strings!
        return (
            !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        ); // ...and ensure strings of whitespace fail
    };
    const rows = [...items, sums];
    const handleData = (value) => {
        let number = 0;
        if (value !== undefined && value !== null) {
            number = parseFloat(value);
            if (isNumeric(tyGiaTab)) {
                number = parseFloat(value) * parseFloat(tyGiaTab);
            }
        }
        return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    };
    const handleEditAllService = (data) => {
        console.log(data);
        setTourMapChietTinhChuongTrinhId(data.tourMapChietTinhChuongTrinhId);
        setTourChuongTrinhId(data.tourChuongTrinhId);
        setGroupBy(data.groupBy ? data.groupBy : 0);
        setTourQuanLyKhoangKhach(data.values);
        setIsAdd(false);
        setIsOpenAll(true);
    };

    const handleEditServices = (data, tranxChietTinhOtoId, tourQuanLyKhoangKhachId) => {
        debugger;
        console.log(data);
        setIsOpen(true);
        setIsAdd(false);
        setTourMapChietTinhChuongTrinhId(data.tourMapChietTinhChuongTrinhId);
        setTranxChietTinhOtoId(tranxChietTinhOtoId);
        setTourQuanLyKhoangKhachId(tourQuanLyKhoangKhachId);
        setDay(data.item);
        setTourChuongTrinhId(data.tourChuongTrinhId);
        setGroupBy(data.groupBy ? data.groupBy : 0);
    };

    const onSave = () => {
        setIsOpen(false);
        onReset();
    };

    const onCancel = () => {
        setIsOpen(false);
    };
    const onSaveAll = () => {
        setIsOpenAll(false);
        onReset();
    };

    const onCancelAll = () => {
        setIsOpenAll(false);
    };

    const handleAddServices = (item) => {
        setIsAdd(true);
        setIsOpenAll(true);
        // setDay(item.item);
        setTourChuongTrinhId(item.tourChuongTrinhId);
    };

    const handleDeleteServices = (data) => {
        // console.log(data);
        let dataReq = {
            tourMapChietTinhChuongTrinhId: data.tourMapChietTinhChuongTrinhId,
        };
        otoService
            .xoaCauHinhOto(dataReq)
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    // let isCheck = true;
                    // let sum = rows.find((item) => item.id === "SUBTOTAL");
                    // let lstNcc = items.filter(
                    //   (item) =>
                    //     item.id !== data.id &&
                    //     item.item === data.item &&
                    //     item.tourMapChietTinhChuongTrinhId
                    // );
                    // console.log(lstNcc);
                    // let lstItems = items.filter((item) => item.id !== data.id);
                    // let tongGiaDV = { ...tongGiaDichVu };
                    // let donGia = 0;
                    // let chiPhiTreEm = 0;
                    // for (let i = 0; i < lstItems.length; i++) {
                    //   if (
                    //     isCheck &&
                    //     (lstItems[i].tourMapChietTinhChuongTrinhId ||
                    //       lstNcc.length === 0) &&
                    //     lstItems[i].item === data.item
                    //   ) {
                    //     lstItems[i].rowSpan = lstNcc.length + 1;
                    //     isCheck = false;
                    //   }
                    //   if (lstItems[i].donGia) donGia += lstItems[i].donGia;
                    //   if (lstItems[i].tongChiPhiTreEm)
                    //     chiPhiTreEm += lstItems[i].chiPhiTreEm;
                    //   if (lstItems[i]?.values?.length > 0 && idDsKhoangKhach.length > 0) {
                    //     idDsKhoangKhach.forEach((item) => {
                    //       lstItems[i].values.forEach((data) => {
                    //         if (data.tourQuanLyKhoangKhachId === item)
                    //           tongGiaDV[item] += data.giaDichVu;
                    //       });
                    //     });
                    //   }
                    // }
                    // sum.tongGiaDichVu = tongGiaDV;
                    // sum.donGia = donGia;
                    // sum.chiPhiTreEm = chiPhiTreEm;
                    // setSums(sum);
                    // setItems(lstItems);
                    onReset();
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(() => {
                api["error"]({
                    message: t("stour"),
                    description: t("delete") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });
    };

    return (
        <Box
            sx={{
                width: "100%",
                "& .bold": {
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.6)",
                },
                "& .header-name": {
                    backgroundColor: "#f7f7f7",
                    fontWeight: 600,
                },
            }}
        >
            {contextHolder}
            <table>
                <thead>
                    <th style={{ width: "50px", backgroundColor: "#d9d9d9" }}>1</th>
                    <th
                        colSpan="100%"
                        style={{
                            textAlign: "left",
                            textIndent: "20px",
                            backgroundColor: "#d9d9d9",
                        }}
                    >
                        {t("tour.vanChuyen")}
                    </th>
                </thead>
                <thead>
                    {columnTb.map((cl) => {
                        return <th>{cl.headerName}</th>;
                    })}
                </thead>
                <tbody>
                    {rows.map((it) => {
                        // console.log(it);
                        return (
                            <>
                                <tr>
                                    {it.id === "SUBTOTAL" ? (
                                        <>
                                            <td colSpan={2}>{it.label}</td>
                                            <td>{it.taxRate}</td>
                                            <td>{it.soLuong}</td>
                                            {idDsKhoangKhach.length > 0 ? (
                                                <>
                                                    {idDsKhoangKhach.map((item) => {
                                                        return it.tongGiaDichVu !== undefined ? (
                                                            <td>{handleData(it.tongGiaDichVu[item])}</td>
                                                        ) : (
                                                            <td>0.00</td>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {it.rowSpan ? <td rowSpan={it.rowSpan}>{it.item}</td> : <></>}
                                            {it.maNcc ? (
                                                <>
                                                    {it.values && it.values.length > 0 ? (
                                                        <>
                                                            <td>
                                                                <Row>
                                                                    <Tooltip placement="topLeft" title={it.tenNcc}>
                                                                        <Col xs={21} sm={21} md={21} lg={21} xl={21}>
                                                                            {it.maNcc}
                                                                        </Col>
                                                                    </Tooltip>
                                                                    <Col>
                                                                        <Edit
                                                                            fontSize="small"
                                                                            onClick={() => handleEditAllService(it)}
                                                                        />
                                                                        <Popconfirm
                                                                            title={t("deleteTheTask")}
                                                                            description={t("areYouConfirm")}
                                                                            okText={t("delete")}
                                                                            cancelText={t("huy")}
                                                                            onConfirm={() => handleDeleteServices(it)}
                                                                        >
                                                                            <Delete fontSize="small" />
                                                                        </Popconfirm>
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                            <td>{it.soXe}</td>
                                                            <td>{it.soLuong === 0 ? 1 : it.soLuong}</td>
                                                            {it.values.map((item) => {
                                                                return (
                                                                    <>
                                                                        <td>
                                                                            <Row>
                                                                                {item.parentTranxOtoId !== 0 ? (
                                                                                    <Col
                                                                                        xs={21}
                                                                                        sm={21}
                                                                                        md={21}
                                                                                        lg={20}
                                                                                        xl={21}
                                                                                    >
                                                                                        {item.parentMaNcc}
                                                                                    </Col>
                                                                                ) : (
                                                                                    <>
                                                                                        <Col
                                                                                            xs={21}
                                                                                            sm={21}
                                                                                            md={21}
                                                                                            lg={20}
                                                                                            xl={21}
                                                                                        >
                                                                                            {handleData(item.giaDichVu)}
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Edit
                                                                                                fontSize="small"
                                                                                                onClick={() =>
                                                                                                    handleEditServices(
                                                                                                        it,
                                                                                                        item.tranxChietTinhOtoId,
                                                                                                        item.tourQuanLyKhoangKhachId
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </Col>
                                                                                    </>
                                                                                )}
                                                                            </Row>
                                                                        </td>
                                                                    </>
                                                                );
                                                            })}
                                                        </>
                                                    ) : (
                                                        <td colSpan="100%">
                                                            <>
                                                                <Link
                                                                    onClick={() => handleAddServices(it)}
                                                                    disabled={checkThemMoi}
                                                                >
                                                                    {t("add") + " " + t("service").toLowerCase()}
                                                                </Link>
                                                            </>
                                                        </td>
                                                    )}
                                                </>
                                            ) : (
                                                <td colSpan="100%">
                                                    <>
                                                        <Link
                                                            onClick={() => handleAddServices(it)}
                                                            disabled={checkThemMoi}
                                                        >
                                                            {t("add") + " " + t("service").toLowerCase()}
                                                        </Link>
                                                    </>
                                                </td>
                                            )}
                                        </>
                                    )}
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
            {contextHolder}
            <ModalChietTinhCommon
                isAdd={isAdd}
                isOpen={isOpen}
                onSave={onSave}
                onCancel={onCancel}
                chietTinh="oto"
                tourMapChietTinh={tourMapChietTinhChuongTrinhId}
                tranxChietTinh={tranxChietTinhOtoId}
                tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                tourChuongTrinhId={tourChuongTrinhId}
                groupBy={groupBy}
                tourChuongTrinh={tourChuongTrinh}
                day={day}
                tourThongTinChungId={tourThongTinChungId}
                tourThoiGianApDungId={tourThoiGianApDungId}
                tourChietTinhId={tourChietTinhId}
                tabChietTinhId={tabChietTinhId}
                loaiTong={loaiTong}
                thoiGianAD={thoiGianAD}
            />
            <ModalNccOto
                isOpen={isOpenAll}
                isAdd={isAdd}
                onSave={onSaveAll}
                onCancel={onCancelAll}
                tourMapChietTinhId={tourMapChietTinhChuongTrinhId}
                danhSachKhoangKhach={danhSachKhoangKhach}
                tourChuongTrinhId={tourChuongTrinhId}
                groupBy={groupBy}
                tourQuanLyKhoangKhach={tourQuanLyKhoangKhach}
                thoiGianAD={thoiGianAD}
                tourThongTinChungId={tourThongTinChungId}
                tourThoiGianApDungId={tourThoiGianApDungId}
                tourChietTinhId={tourChietTinhId}
                tabChietTinhId={tabChietTinhId}
            />
        </Box>
    );
};
export default VanChuyenNew;
