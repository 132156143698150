import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"; 

const DatePickerMui = ({
    label,
    value,
    onChange,
    format = "DD/MM/YYYY",
    fullWidth = true,
    size = "small",
    sx = {},
    textFieldProps = {},
    ...rest
}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                format={format}
                sx={{
                    marginBottom: 2,
                    "& .MuiInputLabel-root": {
                        fontSize: "14px", 
                    },
                    "& .MuiInputBase-input": {
                        fontSize: "13px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                        fontSize: "13px",
                    },
                    ...sx,
                }}
                label={label}
                slotProps={{
                    textField: {
                        fullWidth: fullWidth,
                        size: size,
                        ...textFieldProps,
                    },
                }}
                value={value}
                onChange={onChange}
                {...rest}
            />
        </LocalizationProvider>
    );
};


export default DatePickerMui;
