import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";


const TableModalGP = ({ keyNum, setDataEdit, dataEdit, dataS }) => {
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState(
        dataS
            ? dataS
            : [
                  {
                      id: 0,
                      typePrice: "Fit",
                      fitNgayThuongNet: 0,
                      fitNgayThuongGiaBan: 0,
                      fitCuoiTuanNet: 0,
                      fitCuoiTuanGiaBan: 0,
                  },
                  {
                      id: 1,
                      typePrice: "Extra",
                      fitNgayThuongNet: 0,
                      fitNgayThuongGiaBan: 0,
                      fitCuoiTuanNet: 0,
                      fitCuoiTuanGiaBan: 0,
                  },
              ]
    );
    const defaultColumns = [
        {
            headerName: t("typePrice") + " " + t("action.apply").toLowerCase(),
            field: "typePrice",
            key: "typePrice",
            width: 200,
            align: "center",
            headerAlign: "center",
        },

        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "fitNgayThuongNet",
            key: "fitNgayThuongNet",
            type: "number",
            editable: true,
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "fitNgayThuongGiaBan",
            key: "fitNgayThuongGiaBan",
            type: "number",
            align: "center",
            flex: 1,
            headerAlign: "center",
            editable: true,
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "fitCuoiTuanNet",
            key: "fitCuoiTuanNet",
            type: "number",
            align: "center",
            flex: 1,
            headerAlign: "center",
            editable: true,
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "fitCuoiTuanGiaBan",
            key: "fitCuoiTuanGiaBan",
            type: "number",
            align: "center",
            flex: 1,
            headerAlign: "center",
            editable: true,
        },
    ];
    const columnGroupingModel = [
        {
            groupId: t("hotel.priceClass.weekdayPrice"),

            children: [{ field: "fitNgayThuongNet" }, { field: "fitNgayThuongGiaBan" }],
        },
        {
            groupId: t("hotel.priceClass.weekendPrice"),

            children: [{ field: "fitCuoiTuanNet" }, { field: "fitCuoiTuanGiaBan" }],
        },
    ];
    const handleSave = (row) => {
        let data =[];
        let objIndex = dataSource.findIndex(obj => obj.typePrice === row.typePrice);
        const newData = [...dataSource];
        newData.splice(objIndex, 1);
        newData.push(row);
        const newDataSort = newData.sort((a,b) => a.id - b.id).slice(0,10);
        setDataSource(newDataSort);
        for (let i =0; i <newDataSort.length; i++){
            if (newDataSort[i].typePrice==="Fit"){
                data.push({
                    fit: newDataSort[i]
                })
            }else {
                data.push({
                    extra: newDataSort[i]
                })
            }
        }
        data.push({
            key: keyNum
        })
        setDataEdit(data);
        // console.log("=====new=====", data);
    };
    const mapColumns = (col) => {
        if (!col.editable) {
            return col;
        }
        const newCol = {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                field: col.field,
                headerName: col.headerName,
                handleSave,
            }),
        };
        if (col.children) {
            newCol.children = col.children.map(mapColumns);
        }
        return newCol;
    };
    const processRowUpdate = (updatedRow, originalRow) => {
        handleSave(updatedRow);
    };
    return (
        <>
            <DataGrid
                editMode="row"
                rows={dataSource}
                processRowUpdate={processRowUpdate}
                columns={defaultColumns}
                experimentalFeatures={{ columnGrouping: true }}
                pageSizeOptions={[10]}
                autoHeight
                columnGroupingModel={columnGroupingModel}
            />
        </>
    );
};
export default TableModalGP;
