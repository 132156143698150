import React, { useEffect, useState } from "react";
import { Table, Button, Radio, Popconfirm, InputNumber, Select } from "antd";
import { MenuItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import Delete from "@mui/icons-material/Delete";

const TableTotalConfig = ({ dataTyGia, dataTotal, setDataTotal, isTreEm, dmDoTuoi, dmDoTuoiId, onDeleteLoaiTien }) => {
    const { t } = useTranslation();
    const [groups, setGroups] = useState(isTreEm ? dataTotal[0]?.loaiTien || [] : dataTotal);
    const [doTuoiId, setDoTuoiId] = useState(dmDoTuoiId);

    const generateId = () => {
        return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
    };
    useEffect(() => {
        console.log("OnChange: " + dataTotal);
        setGroups(isTreEm ? dataTotal[0]?.loaiTien || [] : dataTotal);
    }, [dataTotal]);
    const handleAddGroup = () => {
        let groupId = generateId();
        const newGroup = {
            id: groupId,
            bgCauHinhLaiId: groups[0]?.bgCauHinhLaiId ? groups[0]?.bgCauHinhLaiId : 0,
            bgCauHinhLoaiTienId: 0,
            dmLoaiTienId: 1,
            details: [
                { type: "Percentage", kieuTinh: 0, giaTri: 0, apDung: true },
                { type: "FixedAmount", kieuTinh: 1, giaTri: 0, apDung: false },
            ],
        };
        const newGroups = [...groups, newGroup];
        if (isTreEm) {
            const newDataTotal = [...dataTotal];
            let idxUpdate = newDataTotal.findIndex((item) => item.dmDoTuoiId === doTuoiId);
            if (idxUpdate > -1) {
                newDataTotal[idxUpdate] = { bgCauHinhDoTuoiId: 0, dmDoTuoiId: doTuoiId, loaiTien: newGroups };
            } else {
                newDataTotal.push({ bgCauHinhDoTuoiId: 0, dmDoTuoiId: doTuoiId, loaiTien: newGroups });
            }
            setDataTotal(newDataTotal);
        } else {
            setDataTotal(newGroups);
        }
        setGroups(newGroups);
    };

    const handleDeleteGroup = (id, item) => {
        console.log(item);
        const newGroups = groups.filter((group) => group.id !== id);
        if (isTreEm) {
            const newDataTotal = dataTotal.map((data) =>
                data.dmDoTuoiId === doTuoiId
                    ? { ...data, loaiTien: data.loaiTien.filter((group) => group.id !== id) }
                    : data
            );
            setDataTotal(newDataTotal);
        } else setDataTotal(newGroups);
        setGroups(newGroups);

        if (item.bgCauHinhLoaiTienId !== 0) {
            let bgCauHinhDoTuoiId = isTreEm
                ? dataTotal.find((data) => data.dmDoTuoiId === doTuoiId)?.bgCauHinhDoTuoiId || 0
                : 0;
            onDeleteLoaiTien(item.bgCauHinhLaiId, item.bgCauHinhLoaiTienId, bgCauHinhDoTuoiId);
        }
    };

    const handleCurrencyChange = (id, e) => {
        let value = e.target.value;
        const newGroups = groups.map((group) => (group.id === id ? { ...group, dmLoaiTienId: value } : group));
        if (isTreEm) {
            const newDataTotal = dataTotal.map((data) =>
                data.dmDoTuoiId === doTuoiId
                    ? {
                          ...data,
                          loaiTien: data.loaiTien.map((group) =>
                              group.id === id ? { ...group, dmLoaiTienId: value } : group
                          ),
                      }
                    : data
            );
            setDataTotal(newDataTotal);
        } else setDataTotal(newGroups);
        setGroups(newGroups);
    };

    const handleValueChange = (groupId, type, value) => {
        if (value !== null) {
            const newGroups = groups.map((group) =>
                group.id === groupId
                    ? {
                          ...group,
                          details: group.details.map((dt) => (dt.type === type ? { ...dt, giaTri: value } : dt)),
                      }
                    : group
            );
            if (isTreEm) {
                const newDataTotal = dataTotal.map((data) =>
                    data.dmDoTuoiId === doTuoiId
                        ? {
                              ...data,
                              loaiTien: data.loaiTien.map((group) =>
                                  group.id === groupId
                                      ? {
                                            ...group,
                                            details: group.details.map((dt) =>
                                                dt.type === type ? { ...dt, giaTri: value } : dt
                                            ),
                                        }
                                      : group
                              ),
                          }
                        : data
                );
                setDataTotal(newDataTotal);
            } else setDataTotal(newGroups);
            setGroups(newGroups);
        }
    };

    const handleActiveChange = (groupId, type) => {
        const newGroups = groups.map((group) =>
            group.id === groupId
                ? {
                      ...group,
                      details: group.details.map((dt) =>
                          dt.type === type ? { ...dt, apDung: true } : { ...dt, apDung: false }
                      ),
                  }
                : group
        );
        if (isTreEm) {
            const newDataTotal = dataTotal.map((data) =>
                data.dmDoTuoiId === doTuoiId
                    ? {
                          ...data,
                          loaiTien: data.loaiTien.map((group) =>
                              group.id === groupId
                                  ? {
                                        ...group,
                                        details: group.details.map((dt) =>
                                            dt.type === type ? { ...dt, apDung: true } : { ...dt, apDung: false }
                                        ),
                                    }
                                  : group
                          ),
                      }
                    : data
            );
            setDataTotal(newDataTotal);
        } else setDataTotal(newGroups);
        setGroups(newGroups);
    };

    const renderGroupTable = (group, index) => {
        const dataSource = group.details.map((dt, idx) => ({
            key: `${group.bgCauHinhLoaiTienId}-${dt.type}`,
            ...dt,
            bgCauHinhLaiId: group.bgCauHinhLaiId,
            bgCauHinhLoaiTienId: group.bgCauHinhLoaiTienId,
            dmLoaiTienId: idx === 0 ? group.dmLoaiTienId : null, // Chỉ hiển thị trên dòng đầu tiên
        }));

        const columns = [
            {
                title: index === 0 ? "Markup Type" : null, // Header chỉ hiển thị ở cụm đầu tiên
                dataIndex: "type",
                render: (text) => <span>{text}</span>,
                width: 150,
            },
            {
                title: index === 0 ? "Markup Value" : null,
                dataIndex: "giaTri",
                render: (text, record) => (
                    <InputNumber
                        value={record.giaTri}
                        onChange={(e) => handleValueChange(group.id, record.type, e)}
                        style={{ width: "100%" }}
                        formatter={(value) =>
                            value
                                ? `${parseFloat(value)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : ""
                        }
                        parser={(value) => (value ? value.replace(/\$\s?|,/g, "") : "")}
                    />
                ),
                width: 200,
            },
            {
                title: index === 0 ? "Loại tiền" : null,
                dataIndex: "dmLoaiTienId",
                render: (text, record, rowIndex) => {
                    if (rowIndex === 0) {
                        return {
                            children: (
                                <TextField
                                    select
                                    size="small"
                                    value={group.dmLoaiTienId}
                                    onChange={(value) => handleCurrencyChange(group.id, value)}
                                    style={{ width: "100%" }}
                                >
                                    {dataTyGia?.map((option) => (
                                        <MenuItem key={option.maNgoaiTeId} value={option.maNgoaiTeId}>
                                            {option.maNgoaiTe}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ),
                            props: { rowSpan: 2 },
                        };
                    } else {
                        return {
                            props: { rowSpan: 0 },
                        };
                    }
                },
                width: 150,
            },
            {
                title: index === 0 ? "Áp dụng" : null,
                dataIndex: "apDung",
                render: (_, record) => (
                    <Radio.Group
                        value={record.apDung ? "active" : ""}
                        onChange={() => handleActiveChange(group.id, record.type)}
                    >
                        <Radio value="active"></Radio>
                    </Radio.Group>
                ),
                width: 100,
            },
            {
                title: index === 0 ? "Thao tác" : null,
                dataIndex: "action",
                render: (_, record, rowIndex) => {
                    if (rowIndex === 0) {
                        return {
                            children: (
                                <Popconfirm
                                    title={t("deleteTheTask")}
                                    description={t("areYouConfirm")}
                                    okText={t("delete")}
                                    cancelText={t("huy")}
                                    onConfirm={() => handleDeleteGroup(group.id, record)}
                                >
                                    <Delete fontSize="small" />
                                </Popconfirm>
                            ),
                            props: { rowSpan: 2 },
                        };
                    } else {
                        return {
                            props: { rowSpan: 0 },
                        };
                    }
                },
                width: 100,
            },
        ];

        return (
            <Table
                key={group.id}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                showHeader={index === 0} // Chỉ hiển thị header ở cụm đầu tiên
                style={{ marginBottom: "20px" }}
                bordered={index === 0} // Có đường viền cho cụm đầu tiên
            />
        );
    };

    const handleChangeDoTuoi = (value) => {
        const groupNew = dataTotal?.find((item) => item.dmDoTuoiId === value)?.loaiTien || [];
        setDoTuoiId(value);
        setGroups(groupNew);
    };

    return (
        <>
            {isTreEm ? (
                <Select
                    value={doTuoiId}
                    onChange={(e) => handleChangeDoTuoi(e)}
                    style={{ width: "30%", display: "block", marginBottom: "10px" }}
                >
                    {dmDoTuoi.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                            {item.do_tuoi}
                        </Select.Option>
                    ))}
                </Select>
            ) : null}
            {groups.map((group, index) => renderGroupTable(group, index))}
            <Button type="dashed" onClick={handleAddGroup} style={{ marginTop: "10px" }}>
                + Thêm loại tiền
            </Button>
        </>
    );
};

export default TableTotalConfig;
