import { CloseCircleFilled } from "@ant-design/icons";
import BorderColorOutlined from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import { Checkbox, FormControl, FormControlLabel, Link, MenuItem, TextField, Box } from "@mui/material";
import Buttons from "@mui/material/Button";
import { Col, Divider, Modal, Row, Space, Table, Tooltip, notification } from "antd";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import dm_gia_ap_dung from "../../../../api/category/dm_gia_ap_dung";
import ModalThongTinNccOto from "./modalNcc/ModalThongTinNccOto";
import otoService from "../../../../api/bao_gia/sale_rate/oto/otoService";
import { TIME_DURATION } from "../../../../constants";
import "./ModalCapNhat.css";

const ModalCapNhatOto = ({
    isOpen,
    onReset,
    handleCancel,
    dataNhomNgLon,
    dataNhomTreEm,
    baoGiaHanhTrinhId,
    baoGiaTongQuanId,
    bgChietTinhSaleId,
    dmDichVuId,
    bgMapCTSaleHtId,
    soLuongTreEmBG,
    soLuongNgLonBG,
    hanhDong,
    soNgay,
    ngay,
    bgHanhTrinhs,
    tuNgay,
    denNgay,
}) => {
    const [dmGia, setDmGia] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const { t, i18n } = useTranslation();
    const [dmLoaiApDung, setDmLoaiApDung] = useState([]);
    const [isOpenTT, setIsOpenTT] = useState(false);
    const [dataSource, setDataSource] = useState({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        moTa: "",
        ngayApDungs: [],
    });
    const [dataSourceInit, setDataSourceInit] = useState({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        moTa: "",
        ngayApDungs: [],
    });
    const [dichVus, setDichVus] = useState([{ id: "NULL" }]);
    const [dichVusInit, setDichVusInit] = useState([{ id: "NULL" }]);
    const [dichVuTreEms, setDichVuTreEms] = useState([]);
    const [dichVuTreEmsInit, setDichVuTreEmsInit] = useState([]);
    const [action, setAction] = useState("");
    const [idxEdit, setIdxEdit] = useState(0);
    const [dmVat, setDmVat] = useState([]);
    const [dmDoTuoi, setDmDoTuoi] = useState([]);
    const [countDichVu, setCountDichVu] = useState(0);
    const [reset, setReset] = useState(false);
    const [maNcc, setMaNcc] = useState("");
    const prevCountDichVu = useRef();
    // const soLuongTreEmBG = 5;
    // const soLuongNgLonBG = 8;
    // const dataNhomTreEm = [
    //   {
    //     id: 4,
    //     nhomNguoiLonId: 4,
    //     maNhom: "",
    //     tenNhom: "tre 1",
    //     details: [
    //       {
    //         soLuong: 1,
    //         dmDoTuoiId: 1,
    //       },
    //       {
    //         soLuong: 1,
    //         dmDoTuoiId: 2,
    //       },
    //     ],
    //   },
    //   {
    //     id: 5,
    //     nhomNguoiLonId: 5,
    //     maNhom: "",
    //     tenNhom: "tre 2",
    //     details: [
    //       {
    //         soLuong: 1,
    //         dmDoTuoiId: 1,
    //       },
    //     ],
    //   },
    //   // {
    //   //   id: 6,
    //   //   nhomNguoiLonId: 5,
    //   //   maNhom: "",
    //   //   tenNhom: "tre 3",
    //   //   details: [
    //   //     {
    //   //       soLuong: 1,
    //   //       dmDoTuoiId: 1,
    //   //     },
    //   //     {
    //   //       soLuong: 1,
    //   //       dmDoTuoiId: 2,
    //   //     },
    //   //   ],
    //   // },
    // ];

    // const dataNhomNgLon = [
    //   {
    //     id: 4,
    //     maNhomTreEm: [],
    //     tenNhom: "ngl 1",
    //     foc: 3,
    //     soLuong: 3,
    //   },
    //   {
    //     id: 5,
    //     maNhomTreEm: [],
    //     tenNhom: "ngl 2",
    //     foc: 3,
    //     soLuong: 3,
    //   },
    // ];
    useEffect(() => {
        getDmGiaApDung();
        getDmLoaiApDung();
        getDmVat();
        getDmDoTuoi();
    }, []);

    useEffect(() => {
        prevCountDichVu.current = countDichVu;
    }, [countDichVu]);

    useEffect(() => {
        if (hanhDong === "EDIT" && isOpen) {
            getChietTinhSaleOto({ bgMapCTSaleHtId: bgMapCTSaleHtId });
        }
    }, [hanhDong, bgMapCTSaleHtId, isOpen]);

    useEffect(() => {
        if (hanhDong === "ADD") {
            setDichVus([{ id: "NULL" }]);
            setDichVusInit([{ id: "NULL" }]);
            setDataSource({
                tenNcc: "",
                dmGiaApDungId: 2,
                dmVatId: 1,
                moTa: "",
                ngayApDungs: [],
            });
            setDataSourceInit({
                tenNcc: "",
                dmGiaApDungId: 2,
                dmVatId: 1,
                moTa: "",
                ngayApDungs: [],
            });
            setDichVuTreEms([]);
            setDichVuTreEmsInit([]);
        }
    }, [isOpen]);

    const chiPhiTreEm = useMemo(() => {
        return dichVuTreEms.reduce((sum, item) => {
            if (!item.id.includes("addNew")) sum += parseFloat(item.thanhTien);
            return sum;
        }, 0);
    }, [dichVuTreEms, dichVus]);

    const chiPhiNguoiLon = useMemo(() => {
        let groupedData = dichVuTreEms.reduce((acc, item) => {
            const key = `${item.nccDichVuOtoId}-${item.tranxOtoId}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            if (!item.id.includes("addNew")) acc[key].push(item);
            return acc;
        }, {});
        return Object.keys(groupedData).reduce((sum, key) => {
            var giaDv = groupedData[key].length > 0 ? groupedData[key][0].giaNet : 0;
            sum += parseFloat(giaDv);
            return sum;
        }, 0);
        // return dichVuTreEms.reduce((sum, item) => {
        //   if (!item.id.includes("addNew")) sum += parseFloat(item.giaNet);
        //   return sum;
        // }, 0);
    }, [dichVuTreEms, dichVus]);

    let tongChiPhiNguoiLon = useMemo(() => {
        return chiPhiNguoiLon * soLuongNgLonBG;
    }, [chiPhiNguoiLon]);

    const tongChiPhi = chiPhiTreEm + tongChiPhiNguoiLon;

    let formatter = new Intl.NumberFormat("en-US", {});

    const compareNumber = (a, b) => {
        return a - b;
    };

    const generateId = () => {
        return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
    };

    const getChietTinhSaleOto = (id) => {
        debugger;
        otoService
            .getChietTinhSaleOto(id)
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let ds = {
                        nccThongTinChungId: data[0].nccThongTinChungId,
                        tenNcc: data[0].tenNhaCungCap,
                        ngayApDungs: data[0].ngayApDungs,
                        dmGiaApDungId: data[0].dmGiaApDungId,
                        ngayApDungs: data[0].ngayApDungs,
                        dmLoaiApDung: data[0].dmLoaiApDungId,
                        dmVatId: data[0].dmVatId, // đang null
                        dmTyGiaId: data[0].dmTyGiaId, // đang null
                        moTa: data[0].moTa,
                    };

                    let lstDichVu = data[0]?.dichVuNguoiLons.map((it, idx) => {
                        it.id = idx + 1;
                        it.tenDichVu = it.dichVu;
                        it.giaNet = it.donGia;
                        it.soCho = it.soChoStr;
                        it.loaiXe = it.tenLoaiXe;
                        it.bgNhomNguoiLons = it.bgNhomNguoiLons?.map((dt) => dt.bgNhomNguoiLonId);
                        it.hinhThucGiaId = it.dmHinhThucGiaId;
                        it.tong =
                            it.hinhThucGiaId === 2 ? it.giaNet * it.soLuong * it.tongSoKm : it.giaNet * it.soLuong;
                        return it;
                    });
                    lstDichVu.push({ id: "NULL" });

                    let dichVuTEs =
                        data[0]?.dichVuNguoiLons.flatMap((dv) => {
                            let giaDV = dv.donGia;
                            let numDV = 0;
                            const dichVuTreEms =
                                data[1]?.dichVuTreEms?.filter(
                                    (te) => te.tranxBgChietTinhOtoId === dv.tranxBgChietTinhOtoId
                                ) || [];
                            let rowSpanDV = dichVuTreEms.reduce((num, item) => (num += item.details.length), 0);
                            let dichVuTEs =
                                dichVuTreEms.flatMap(
                                    (te) =>
                                        te.details.map((dt, idx) => {
                                            giaDV = dt.giaDichVu;
                                            return {
                                                id: generateId(),
                                                tenDichVu: te.tenDichVu,
                                                tenNhomTE: dt.tenNhomTreEm,
                                                giaNet: dt.giaDichVu,
                                                soLuong: dt.soLuong,
                                                phanTram: dt.phanTram,
                                                dmVatId: dt.dmVatId,
                                                apDung: dt.apDung,
                                                thanhTien: (dt.phanTram / 100) * dt.soLuong * dt.giaDichVu,
                                                bgNhomTreEmId: dt.bgNhomTreEmId,
                                                tranxOtoId: dv.tranxOtoId,
                                                nccDichVuOtoId: dv.nccDichVuOtoId,
                                                bgNhomNguoiLons: dv.bgNhomNguoiLons,
                                                tranxBgCtOtoSaleTreEmId: dt.tranxBgCtOtoSaleTreEmId,
                                                dmDoTuoiId: dt.dmDoTuoiId,
                                                rowSpanDV:
                                                    numDV++ === 0
                                                        ? dataNhomTreEm.length === 0
                                                            ? rowSpanDV + 1
                                                            : rowSpanDV
                                                        : 0,
                                                rowSpanTE: idx === 0 ? te.details.length : 0,
                                            };
                                        }) || []
                                ) || [];
                            if (dataNhomTreEm.length === 0) {
                                dichVuTEs.push({
                                    id: "addNew" + generateId(),
                                    tenDichVu: dv.dichVu,
                                    giaNet: giaDV,
                                    tranxOtoId: dv.tranxOtoId,
                                    nccDichVuOtoId: dv.nccDichVuOtoId,
                                    rowSpanDV: dichVuTEs.length === 0 ? 1 : 0,
                                });
                            }
                            return dichVuTEs;
                        }) || [];

                    setCountDichVu(lstDichVu.length);
                    setDichVus(lstDichVu);
                    setDichVusInit(JSON.parse(JSON.stringify(lstDichVu)));
                    setDataSource(ds);
                    setDataSourceInit({ ...ds });
                    setDichVuTreEms(dichVuTEs);
                    setDichVuTreEmsInit(JSON.parse(JSON.stringify(dichVuTEs)));
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmGiaApDung = () => {
        dm_gia_ap_dung
            .getDmGiaApDung()
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    setDmGia(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmLoaiApDung = () => {
        dm_gia_ap_dung
            .getDmLoaiApDung()
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    setDmLoaiApDung(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmVat = () => {
        dm_gia_ap_dung
            .getDmVat()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmVat(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmDoTuoi = () => {
        dm_gia_ap_dung
            .getDmDoTuoi()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmDoTuoi(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const handleAddServiceTreEm = (row) => {
        debugger;
        let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
        let index = lstDichVuTreEm.findIndex((it) => it.id === row.id);
        const newTreEm = {
            id: generateId(),
            tenDichVu: row.tenDichVu,
            giaNet: row.giaNet,
            nccDichVuOtoId: row.nccDichVuOtoId,
            tranxOtoId: row.tranxOtoId,
            thanhTien: 0,
            phanTram: 0,
            soLuong: 0,
            bgNhomTreEmId: 0,
            dmDoTuoiId: 1,
            apDung: false,
            rowSpanDV: !row.rowSpanDV || row.rowSpanDV === 0 ? 0 : row.rowSpanDV,
            rowSpanTE: 0,
        };
        lstDichVuTreEm[index].rowSpanDV = 0;
        lstDichVuTreEm.splice(index, 0, newTreEm);
        let idxFirst = lstDichVuTreEm.findIndex(
            (item) => item.nccDichVuOtoId === row.nccDichVuOtoId && item.tranxOtoId === row.tranxOtoId
        );
        lstDichVuTreEm[idxFirst].rowSpanDV += 1;
        setDichVuTreEms(lstDichVuTreEm);
    };

    const handleDeleteServiceTreEm = (row) => {
        let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
        let index = lstDichVuTreEm.findIndex((it) => it.id === row.id);
        lstDichVuTreEm.splice(index, 1);
        let idxFirst = lstDichVuTreEm.findIndex(
            (item) => item.nccDichVuOtoId === row.nccDichVuOtoId && item.tranxOtoId === row.tranxOtoId
        );
        if (row.rowSpanDV === 0) lstDichVuTreEm[idxFirst].rowSpanDV -= 1;
        else {
            lstDichVuTreEm[idxFirst].rowSpanDV = row.rowSpanDV - 1;
        }
        debugger;
        handleGiaTEWhenChangeDvTreEm(lstDichVuTreEm, index);
        setDichVuTreEms(lstDichVuTreEm);
    };

    const handleChangeValueDataSoure = (e) => {
        dataSource[e.target.name] = e.target.value;
        if (e.target.name === "dmGiaApDungId") dataSource.ngayApDungs = [];
        setReset(!reset);
    };

    const handleUpdateService = (record) => {
        let index = dichVus.findIndex((item) => item.id === record.id);
        setIsOpenTT(true);
        setAction("UPDATE_SERVICE");
        setIdxEdit(index);
    };

    const handleUpdateNCC = () => {
        setMaNcc("");
        setIsOpenTT(true);
        setAction("UPDATE_NCC");
    };

    const handleAddService = () => {
        if (dichVus.length === 1) setMaNcc("");
        setIsOpenTT(true);
        setAction("ADD_SERVICE");
    };

    const handleDeleteService = (record) => {
        let index = dichVus.findIndex((item) => item.id === record.id);
        let lstDichVu = JSON.parse(JSON.stringify(dichVus));
        lstDichVu.splice(index, 1);
        let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
        if (dataNhomNgLon.length === 0) {
            if (
                !lstDichVu.find(
                    (it) => it.nccDichVuOtoId === record.nccDichVuOtoId && it.tranxOtoId === record.tranxOtoId
                )
            )
                lstDichVuTreEm = lstDichVuTreEm.filter(
                    (it) => !(it.nccDichVuOtoId === record.nccDichVuOtoId && it.tranxOtoId === record.tranxOtoId)
                );
        } else {
            lstDichVuTreEm = dichVuTreEms.filter(
                (item) =>
                    !(
                        item.nccDichVuOtoId === record.nccDichVuOtoId &&
                        item.tranxOtoId === record.tranxOtoId &&
                        item.bgNhomNguoiLons?.toString() === record.bgNhomNguoiLons?.toString()
                    )
            );
        }
        setDichVuTreEms(lstDichVuTreEm);
        setDichVus(lstDichVu);
        setCountDichVu(lstDichVu.length);
        // setReset(!reset);
    };

    const handleChangeDataServiceOto = (dataSelected) => {
        console.log(dataSelected);
        let lstDichVu = [];
        let dvUpdate = {};
        if (action === "UPDATE_NCC") {
            dataSource.tenNcc = dataSelected[0].tenNcc;
            dataSource.dmGiaApDungId = 1;
            dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
            dataSource.dmVatId = dataSelected[0].dmVatId ? dataSelected[0].dmVatId : 1;
            dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
            dataSource.moTa = "";
            lstDichVu = dataSelected?.map((item, idx) => {
                item.id = idx + 1;
                item.soLuong = 0;
                item.tong = 0;
                item.bgNhomNguoiLons = [];
                item.tongSoKm = 0;
                return item;
            });
            lstDichVu.push({ id: "NULL" });
        } else if (action === "ADD_SERVICE") {
            if (
                dataNhomNgLon.length === 0 &&
                dichVus.find(
                    (it) =>
                        it.nccDichVuOtoId === dataSelected[0].nccDichVuOtoId &&
                        it.tranxOtoId === dataSelected[0].tranxOtoId
                )
            ) {
                return showNoti("error", t("noti.errorSelectedService"));
            }
            dataSource.tenNcc = dataSelected[0].tenNcc;
            dataSource.dmGiaApDungId = 1;
            dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
            dataSource.dmVatId = dataSelected[0].dmVatId ? dataSelected[0].dmVatId : 1;
            dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
            dataSource.moTa = "";
            dataSource.tenNcc = dataSelected[0].tenNcc;
            lstDichVu = JSON.parse(JSON.stringify(dichVus));
            lstDichVu.splice(dichVus.length - 1, 1);
            lstDichVu.push({
                ...dataSelected[0],
                id: dichVus.length,
                bgNhomNguoiLons: [],
                soLuong: 0,
                tong: 0,
                tongSoKm: 0,
            });
            lstDichVu.push({ id: "NULL" });
        } else {
            if (
                dataNhomNgLon.length === 0 &&
                dichVus.find(
                    (it) =>
                        it.nccDichVuOtoId === dataSelected[0].nccDichVuOtoId &&
                        it.tranxOtoId === dataSelected[0].tranxOtoId
                )
            ) {
                return showNoti("error", t("noti.errorSelectedService"));
            }
            let dichVusFix = dichVus.filter((d) => d.id !== "NULL");
            if (dichVusFix.length === 1) {
                dataSource.tenNcc = dataSelected[0].tenNcc;
                dataSource.dmGiaApDungId = 1;
                dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
                dataSource.dmVatId = dataSelected[0].dmVatId ? dataSelected[0].dmVatId : 1;
                dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
                dataSource.moTa = dataSelected[0].moTa;
            }
            lstDichVu = JSON.parse(JSON.stringify(dichVus));
            dvUpdate = lstDichVu[idxEdit];
            lstDichVu[idxEdit] = {
                ...dataSelected[0],
                id: idxEdit + 1,
                bgNhomNguoiLons: [],
                soLuong: 0,
                tong: 0,
                tongSoKm: 0,
            };
        }

        let nhomTreEms = JSON.parse(JSON.stringify(dataNhomTreEm));
        let lenDataTE = nhomTreEms.reduce((num, item) => {
            item.details.forEach(() => {
                num++;
            });
            return num;
        }, 0);
        let dichVusByNgLon = lstDichVu;
        if (dataNhomNgLon.length > 0) dichVusByNgLon = lstDichVu.filter((it) => it.bgNhomNguoiLons?.length > 0);

        let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
        let idx = 0;
        let idxCheckRowSpanTE = 1;
        if (action === "UPDATE_NCC") {
            lstDichVuTreEm = [];
            let idxCheckRowSpanDV = 1;
            for (let i = 0; i < dichVusByNgLon.length - 1; i++) {
                if (dataNhomTreEm.length === 0) {
                    if (
                        lstDichVuTreEm.length === 0 ||
                        !lstDichVuTreEm.find(
                            (it) =>
                                it.nccDichVuOtoId === dichVusByNgLon[i].nccDichVuOtoId &&
                                it.tranxOtoId === dichVusByNgLon[i].tranxOtoId
                        )
                    )
                        lstDichVuTreEm = [
                            ...lstDichVuTreEm,
                            {
                                id: "addNew" + generateId(),
                                tenDichVu: dichVusByNgLon[i].tenDichVu,
                                giaNet: 0,
                                nccDichVuOtoId: dichVusByNgLon[i].nccDichVuOtoId,
                                tranxOtoId: dichVusByNgLon[i].tranxOtoId,
                                rowSpanDV: 1,
                            },
                        ];
                } else if (
                    dataNhomNgLon.length === 0 &&
                    !lstDichVuTreEm.find(
                        (it) =>
                            it.nccDichVuOtoId === dichVusByNgLon[i].nccDichVuOtoId &&
                            it.tranxOtoId === dichVusByNgLon[i].tranxOtoId
                    )
                ) {
                    for (let index = 0; index < nhomTreEms.length; index++) {
                        let element = nhomTreEms[index];
                        let dichVuTEs = element.details?.map((item) => {
                            idx++;
                            return {
                                id: idx,
                                tenDichVu: dichVusByNgLon[i].tenDichVu,
                                tenNhomTE: element.tenNhom,
                                phanTram: 0,
                                giaNet: 0,
                                soLuong: item.soLuong,
                                thanhTien: 0,
                                nccDichVuOtoId: dichVusByNgLon[i].nccDichVuOtoId,
                                tranxOtoId: dichVusByNgLon[i].tranxOtoId,
                                bgNhomTreEmId: element.id,
                                bgNhomNguoiLons: [],
                                dmDoTuoiId: item.dmDoTuoiId,
                                phanTram: 0,
                                rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                                rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
                            };
                        });
                        idxCheckRowSpanTE += element.details.length;
                        lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEs];
                    }
                    idxCheckRowSpanDV += lenDataTE;
                }
            }
        } else if (action === "ADD_SERVICE") {
            if (dataNhomTreEm.length === 0) {
                if (
                    lstDichVuTreEm.length === 0 ||
                    !lstDichVuTreEm.find(
                        (it) =>
                            it.nccDichVuOtoId === dataSelected[0].nccDichVuOtoId &&
                            it.tranxOtoId === dataSelected[0].tranxOtoId
                    )
                )
                    lstDichVuTreEm = [
                        ...lstDichVuTreEm,
                        {
                            id: "addNew" + generateId(),
                            tenDichVu: dataSelected[0].tenDichVu,
                            giaNet: 0,
                            nccDichVuOtoId: dataSelected[0].nccDichVuOtoId,
                            tranxOtoId: dataSelected[0].tranxOtoId,
                            rowSpanDV: 1,
                        },
                    ];
            } else if (
                dataNhomNgLon.length === 0 &&
                !lstDichVuTreEm.find(
                    (it) =>
                        it.nccDichVuOtoId === dataSelected[0].nccDichVuOtoId &&
                        it.tranxOtoId === dataSelected[0].tranxOtoId
                )
            ) {
                idx = lenDataTE * (prevCountDichVu.current - 1);
                idxCheckRowSpanTE = lenDataTE * (prevCountDichVu.current - 1) + 1;
                for (let index = 0; index < nhomTreEms.length; index++) {
                    let element = nhomTreEms[index];
                    let dichVuTEByGroup = element.details?.map((item) => {
                        idx++;
                        return {
                            id: idx,
                            tenDichVu: dataSelected[0].tenDichVu,
                            tenNhomTE: element.tenNhom,
                            phanTram: 0,
                            giaNet: dataSelected[0].giaNet,
                            soLuong: item.soLuong,
                            thanhTien: 0,
                            nccDichVuOtoId: dataSelected[0].nccDichVuOtoId,
                            tranxOtoId: dataSelected[0].tranxOtoId,
                            bgNhomTreEmId: element.id,
                            bgNhomNguoiLons: [],
                            dmDoTuoiId: item.dmDoTuoiId,
                            phanTram: 0,
                            rowSpanDV: idx === lenDataTE * (prevCountDichVu.current - 1) + 1 ? lenDataTE : 0,
                            rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
                        };
                    });
                    idxCheckRowSpanTE += element.details.length;
                    lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEByGroup];
                }
            }
        } else {
            if (dataNhomTreEm.length === 0) {
                lstDichVuTreEm = lstDichVuTreEm.filter(
                    (it) => !(it.nccDichVuOtoId === dvUpdate.nccDichVuOtoId && it.tranxOtoId === dvUpdate.tranxOtoId)
                );
                lstDichVuTreEm = [
                    ...lstDichVuTreEm,
                    {
                        id: "addNew" + generateId(),
                        tenDichVu: dataSelected[0].tenDichVu,
                        giaNet: 0,
                        nccDichVuOtoId: dataSelected[0].nccDichVuOtoId,
                        tranxOtoId: dataSelected[0].tranxOtoId,
                        rowSpanDV: 1,
                    },
                ];
            } else if (dataNhomNgLon.length === 0) {
                lstDichVuTreEm = [];
                let idxCheckRowSpanDV = 1;
                for (let i = 0; i < lstDichVu.length - 1; i++) {
                    if (
                        !lstDichVuTreEm.find(
                            (it) =>
                                it.nccDichVuOtoId === lstDichVu[i].nccDichVuOtoId &&
                                it.tranxOtoId === lstDichVu[i].tranxOtoId
                        )
                    ) {
                        for (let index = 0; index < nhomTreEms.length; index++) {
                            let element = nhomTreEms[index];
                            let dichVuTEs = element.details?.map((item) => {
                                idx++;
                                return {
                                    id: idx,
                                    tenDichVu: lstDichVu[i].tenDichVu,
                                    tenNhomTE: element.tenNhom,
                                    phanTram: 0,
                                    giaNet: 0,
                                    soLuong: item.soLuong,
                                    thanhTien: 0,
                                    nccDichVuOtoId: lstDichVu[i].nccDichVuOtoId,
                                    tranxOtoId: lstDichVu[i].tranxOtoId,
                                    bgNhomTreEmId: element.id,
                                    bgNhomNguoiLons: [],
                                    dmDoTuoiId: item.dmDoTuoiId,
                                    phanTram: 0,
                                    rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                                    rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
                                };
                            });
                            idxCheckRowSpanTE += element.details.length;
                            lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEs];
                        }
                    }
                    idxCheckRowSpanDV += lenDataTE;
                }
            }
        }

        // if (dataSelected.length > 0) setMaNcc(dataSelected[0].maNcc);
        setDichVuTreEms(lstDichVuTreEm);
        setDichVus(lstDichVu);
        setCountDichVu(lstDichVu.length);
        setDataSource(dataSource);
        console.log(dichVuTreEms);
        if (action === "ADD_SERVICE") showNoti("success", t("add") + " " + t("noti.success").toLowerCase());
        else showNoti("success", t("edit") + " " + t("noti.success").toLowerCase());
    };

    const handleChangeEleDichVu = (id, event) => {
        debugger;
        let index = dichVus.findIndex((item) => item.id === id);
        let name = event.target.name;
        dichVus[index][name] = event.target.value;
        if (name === "soLuong" || name === "tongSoKm") {
            if (event.target.value < 0) dichVus[index][name] = 0;
            dichVus[index].tong =
                dichVus[index].hinhThucGiaId === 2
                    ? dichVus[index].giaNet * dichVus[index].soLuong * dichVus[index].tongSoKm
                    : dichVus[index].giaNet * dichVus[index].soLuong;
            if (dataNhomNgLon.length === 0) {
                let dichVuTreEmByNgLon = dichVuTreEms.filter(
                    (it) =>
                        it.nccDichVuOtoId === dichVus[index].nccDichVuOtoId &&
                        it.tranxOtoId === dichVus[index].tranxOtoId &&
                        !it.id.includes("addNew")
                );

                let giaNet =
                    parseFloat(dichVus[index].tong) /
                    (parseInt(soLuongNgLonBG) +
                        parseFloat(
                            dichVuTreEmByNgLon.reduce((sum, item) => sum + item.soLuong * (item.phanTram / 100), 0)
                        ));

                for (let i = 0; i < dichVuTreEms.length; i++) {
                    let it = dichVuTreEms[i];
                    if (
                        it &&
                        it.nccDichVuOtoId === dichVus[index].nccDichVuOtoId &&
                        it.tranxOtoId === dichVus[index].tranxOtoId &&
                        !it.id.includes("addNew")
                    ) {
                        it.giaNet = giaNet ? giaNet : 0;
                        it.thanhTien = it.giaNet * it.soLuong * (it.phanTram / 100);
                    }
                }
            } else {
                let soLuongNgLon = dataNhomNgLon.reduce((sum1, dt) => {
                    if (dichVus[index].bgNhomNguoiLons.includes(dt.id)) return sum1 + parseInt(dt.soLuong);
                    else return sum1;
                }, 0);

                let dichVuTreEmByDichVu = dichVuTreEms.filter(
                    (it) =>
                        it.nccDichVuOtoId === dichVus[index].nccDichVuOtoId &&
                        it.tranxOtoId === dichVus[index].tranxOtoId &&
                        it.bgNhomNguoiLons.toString() === dichVus[index].bgNhomNguoiLons?.toString()
                );

                let giaNet =
                    parseFloat(dichVus[index].tong) /
                    (parseInt(soLuongNgLon) +
                        parseFloat(
                            dichVuTreEmByDichVu && dichVuTreEmByDichVu.length > 0
                                ? dichVuTreEmByDichVu.reduce(
                                      (sum, item) => sum + item.soLuong * (item.phanTram / 100),
                                      0
                                  )
                                : 0
                        ));
                for (let i = 0; i < dichVuTreEms.length; i++) {
                    let it = dichVuTreEms[i];
                    if (
                        it.nccDichVuOtoId === dichVus[index].nccDichVuOtoId &&
                        it.tranxOtoId === dichVus[index].tranxOtoId &&
                        dichVus[index]?.bgNhomNguoiLons?.toString() === it.bgNhomNguoiLons?.toString()
                    ) {
                        it.giaNet = giaNet ? giaNet : 0;
                        it.thanhTien = it.giaNet * it.soLuong * (it.phanTram / 100);
                    }
                }
            }
        }
        setDichVus([...dichVus]);
    };

    const handleChangeEleDichVuTreEm = (id, event) => {
        let dvTreEms = [...dichVuTreEms];
        let index = dvTreEms.findIndex((item) => item.id === id);
        dvTreEms[index][event.target.name] = event.target.value;
        if (event.target.name === "soLuong" || event.target.name === "phanTram") {
            if (event.target.value < 0) {
                dvTreEms[index][event.target.name] = 0;
            }
            handleGiaTEWhenChangeDvTreEm(dvTreEms, index);
        }

        setDichVuTreEms(dvTreEms);
    };

    const handleGiaTEWhenChangeDvTreEm = (dvTreEms, index) => {
        if (dataNhomNgLon.length === 0) {
            let dichVu = dichVus.find(
                (it) =>
                    it.nccDichVuOtoId === dvTreEms[index].nccDichVuOtoId && it.tranxOtoId === dvTreEms[index].tranxOtoId
            );

            let dichVuTreEmByNgLon = dvTreEms.filter(
                (it) =>
                    it.nccDichVuOtoId === dichVu.nccDichVuOtoId &&
                    it.tranxOtoId === dichVu.tranxOtoId &&
                    !it.id.includes("addNew")
            );

            let giaNet = calculateGiaNet(dichVu, soLuongNgLonBG, dichVuTreEmByNgLon);

            updateDichVuTreEms(dvTreEms, giaNet);
        } else {
            let dichVu = dichVus.find(
                (dv) =>
                    dv.nccDichVuOtoId === dvTreEms[index].nccDichVuOtoId &&
                    dv.tranxOtoId === dvTreEms[index].tranxOtoId &&
                    dv.bgNhomNguoiLons.toString() === dvTreEms[index].bgNhomNguoiLons?.toString()
            );

            let soLuongNgLon = dataNhomNgLon.reduce(
                (sum, dt) => (dichVu?.bgNhomNguoiLons.includes(dt.id) ? sum + parseInt(dt.soLuong) : sum),
                0
            );

            let dichVuTreEmByDichVu = dvTreEms.filter(
                (it) =>
                    it.nccDichVuOtoId === dichVu?.nccDichVuOtoId &&
                    it.tranxOtoId === dichVu?.tranxOtoId &&
                    it.bgNhomNguoiLons?.toString() === dichVu.bgNhomNguoiLons?.toString()
            );

            let giaNet = calculateGiaNet(dichVu, soLuongNgLon, dichVuTreEmByDichVu);
            let filteredDichVuTreEms = dvTreEms.filter(
                (it) =>
                    it.nccDichVuOtoId === dichVu?.nccDichVuOtoId &&
                    it.tranxOtoId === dichVu?.tranxOtoId &&
                    dichVu.bgNhomNguoiLons?.toString() === it.bgNhomNguoiLons?.toString()
            );

            updateDichVuTreEms(filteredDichVuTreEms, giaNet);
        }
        return dvTreEms;
    };

    const calculateGiaNet = (dichVu, soLuongNgLon, dichVuTreEmList) => {
        let totalTreEm = dichVuTreEmList.reduce((sum, item) => sum + item.soLuong * (item.phanTram / 100), 0);
        return parseFloat(dichVu.tong) / (parseInt(soLuongNgLon) + parseFloat(totalTreEm));
    };

    const updateDichVuTreEms = (dichVuTreEms, giaNet) => {
        dichVuTreEms.forEach((it) => {
            it.giaNet = giaNet || 0;
            it.thanhTien = it.giaNet * it.soLuong * (it.phanTram / 100);
        });
        // return dichVuTreEms;
    };

    const handleChangeNhomNgLon = (event, row) => {
        debugger;
        let index = dichVus.findIndex((item) => item.id === row.id);
        // let lstDichVu = JSON.parse(JSON.stringify(dichVus));
        let name = event.target.name;
        let value = event.target.value;
        if (
            !dichVus.find(
                (it) =>
                    it.bgNhomNguoiLons?.toString() === value?.sort(compareNumber).toString() &&
                    it.nccDichVuOtoId === dichVus[index].nccDichVuOtoId &&
                    it.tranxOtoId === dichVus[index].tranxOtoId
            )
        ) {
            if (dataNhomTreEm.length > 0) {
                dichVus[index][name] = value?.sort(compareNumber);
                let dichVusByNgLon = dichVus.filter((it) => it.bgNhomNguoiLons?.length > 0);
                let idx = 0;
                let idxCheckRowSpanTE = 1;
                let lstDichVuTreEm = [];
                let idxCheckRowSpanDV = 1;
                for (let i = 0; i < dichVusByNgLon.length; i++) {
                    let nhomTreEms = dataNhomTreEm.filter((item) =>
                        dichVusByNgLon[i].bgNhomNguoiLons?.includes(item.nhomNguoiLonId)
                    );
                    let lenDataTE = nhomTreEms.reduce((num, item) => {
                        item.details.forEach(() => {
                            num++;
                        });
                        return num;
                    }, 0);
                    for (let index = 0; index < nhomTreEms.length; index++) {
                        let element = nhomTreEms[index];
                        let dichVuTEs = element.details?.map((item) => {
                            idx++;
                            return {
                                id: generateId(),
                                tenDichVu: dichVusByNgLon[i].tenDichVu,
                                tenNhomTE: element.tenNhom,
                                phanTram: 0,
                                giaNet: 0,
                                soLuong: item.soLuong,
                                thanhTien: 0,
                                nccDichVuOtoId: dichVusByNgLon[i].nccDichVuOtoId,
                                tranxOtoId: dichVusByNgLon[i].tranxOtoId,
                                bgNhomTreEmId: element.id,
                                bgNhomNguoiLons: dichVusByNgLon[i].bgNhomNguoiLons?.sort(compareNumber),
                                dmDoTuoiId: item.dmDoTuoiId,
                                phanTram: 0,
                                rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                                rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
                            };
                        });
                        idxCheckRowSpanTE += element.details.length;
                        lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEs];
                    }
                    idxCheckRowSpanDV += lenDataTE;
                }
                setDichVuTreEms(lstDichVuTreEm);
                setDichVus([...dichVus]);
            }
        } else {
            showNoti("error", t("noti.errorNhomNgLonSelected"));
        }
    };

    const handleSave = (e) => {
        debugger;
        let rsCheck = handleValidateData();
        if (rsCheck.check) {
            let dataReq = getDataReq();
            console.log(dataReq);
            console.log(JSON.stringify(dataReq));
            otoService
                .saveChietTinhSaleOto(dataReq)
                .then((response) => {
                    let action = "add";
                    if (bgMapCTSaleHtId !== 0) action = "edit";
                    if (response.status === 200 && response.data.code === "00") {
                        showNoti("success", t(action) + " " + t("noti.success").toLowerCase());
                        setDataSourceInit({ ...dataSource });
                        setDichVusInit(JSON.parse(JSON.stringify(dichVus)));
                        setDichVuTreEmsInit(JSON.parse(JSON.stringify(dichVuTreEms)));
                        handleCancel();
                        onReset();
                    } else {
                        showNoti("error", response.data.message);
                    }
                })
                .catch(() => {
                    showNoti("error", t("system.error"));
                });
        } else {
            showNoti("error", rsCheck.message);
        }
    };

    const showNoti = (type, message) => {
        return api[type]({
            message: t("stour"),
            description: t(message),
            duration: TIME_DURATION,
        });
    };

    const getDataReq = () => {
        let ngayApDungs = [];
        if (ngay !== 0) {
            ngayApDungs =
                bgHanhTrinhs
                    ?.filter((item) => {
                        if (dataSource.dmGiaApDungId === 1) return item.ngay_order >= ngay;
                        else return item.ngay_order === ngay;
                    })
                    .map((item) => item.ngay_order) || [];
        }
        let dataReq = {
            ngay: ngay,
            baoGiaHanhTrinhId: baoGiaHanhTrinhId,
            baoGiaTongQuanId: baoGiaTongQuanId,
            ngayApDungs: ngay === 0 ? dataSource.ngayApDungs : ngayApDungs,
            dmDichVuId: dmDichVuId,
            bgCTSaleId: bgChietTinhSaleId,
            bgMapCTSaleHtId: bgMapCTSaleHtId ? bgMapCTSaleHtId : 0, //thêm mới, >0 chỉnh sửa
            nccThongTinChungId: dataSource.nccThongTinChungId,
            tenNcc: dataSource.tenNcc,
            dmGiaApDungId: dataSource.dmGiaApDungId,
            dmVatId: dataSource.dmVatId,
            dmTyGiaId: dataSource.dmTyGiaId,
            moTa: dataSource.moTa,
            dichVus: dichVus
                .filter((dt) => dt.id !== "NULL")
                .map((it) => {
                    return {
                        nccDichVuOtoId: it.nccDichVuOtoId,
                        tenDichVu: it.tenDichVu,
                        dmSoChoId: it.dmSoChoId,
                        dmHinhThucGiaId: it.hinhThucGiaId,
                        soLuong: it.soLuong,
                        // dmVatId: dataSource.dmVatId,
                        dmLoaiXeId: it.dmLoaiXeId,
                        tongSoKm: it.tongSoKm,
                        soXe: "",
                        giaOtoNhapTay: 0,
                        giaNguoiLon: 0,
                        tranxOtoId: it.tranxOtoId,
                        bgNhomNguoiLons: it.bgNhomNguoiLons?.map((id) => {
                            return { bgNhomNguoiLonId: id };
                        }),
                        dichVuTreEms: handleGroupDichVuTreEm(it.nccDichVuOtoId, it.tranxOtoId, it.bgNhomNguoiLons),
                    };
                }),
        };
        return dataReq;
    };

    const handleGroupDichVuTreEm = (nccDichVuOtoId, tranxOtoId, bgNhomNguoiLons) => {
        let lstDichVuTreEm =
            dataNhomNgLon.length === 0
                ? dichVuTreEms.filter(
                      (it) =>
                          !it.id.includes("addNew") &&
                          it.nccDichVuOtoId === nccDichVuOtoId &&
                          it.tranxOtoId === tranxOtoId
                  )
                : dichVuTreEms.filter(
                      (it) =>
                          !it.id.includes("addNew") &&
                          it.nccDichVuOtoId === nccDichVuOtoId &&
                          it.tranxOtoId === tranxOtoId &&
                          it.bgNhomNguoiLons?.toString() === bgNhomNguoiLons?.toString()
                  );
        let groupDichVuTreEm = Object.groupBy(lstDichVuTreEm, ({ bgNhomTreEmId }) => bgNhomTreEmId);
        let keyGroupDichVuTreEm = Object.keys(groupDichVuTreEm);
        let dichVuTEs = keyGroupDichVuTreEm.map((key) => {
            return {
                bgNhomTreEmId: key,
                details: groupDichVuTreEm[key]?.map((it) => {
                    return {
                        dmDoTuoiId: it.dmDoTuoiId,
                        phanTram: it.phanTram,
                        tranxOtoId: it.tranxOtoId,
                        donGiaNhapTay: 0,
                        soLuong: it.soLuong,
                        dmVatId: dataSource.dmVatId,
                        apDung: true,
                    };
                }),
            };
        });
        return dichVuTEs;
    };

    const handleValidateData = () => {
        let result = { check: true, message: "" };
        // let soLuongTE = dichVuTreEms.reduce((sum, item) => {
        //   if (!item.id.includes("addNew")) sum += parseInt(item.soLuong);
        //   return sum;
        // }, 0);
        // if (soLuongTE > soLuongTreEmBG) {
        //   result.check = false;
        //   result.message = t("noti.errorSoLuongTE");
        // } else
        if (ngay === 0 && dataSource.ngayApDungs.length === 0) {
            result.check = false;
            result.message = t("noti.errorNgayApDungs");
        } else if (!dichVus || dichVus.length <= 1) {
            result.check = false;
            result.message = t("noti.saveWarning");
        } else if (dichVus.some((item) => item.hinhThucGiaId === 2 && (!item.tongSoKm || item.tongSoKm <= 0))) {
            result.check = false;
            result.message = t("noti.errorTongSoKm");
        }
        return result;
    };

    const handleChangNgayApDung = (e, ngayOrder) => {
        // let name = parseInt(e.target.name);
        let value = e.target.checked;
        if (dataSource.dmGiaApDungId === 2) {
            if (value) dataSource.ngayApDungs.push(ngayOrder);
            else dataSource.ngayApDungs = dataSource.ngayApDungs.filter((it) => it !== ngayOrder);
        } else {
            if (value) {
                for (let i = ngayOrder; i < bgHanhTrinhs.length + 1; i++) {
                    dataSource.ngayApDungs.push(i);
                }
            } else {
                dataSource.ngayApDungs = [];
            }
        }
        console.log(dataSource.ngayApDungs);
        setDataSource({ ...dataSource });
    };

    const columnDichVus = [
        {
            title: t("service") + "/" + t("trip"),
            dataIndex: "tenDichVu",
            key: "tenDichVu",
            width: "15%",
            align: "center",
            visible: true,
            render: (_, record) => {
                if (record.id === "NULL") {
                    return {
                        children: (
                            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                <Link onClick={() => handleAddService()} underline="none">
                                    {t("addService")}
                                </Link>
                            </Box>
                        ),
                        props: {
                            colSpan: 8,
                        },
                    };
                }
                return (
                    <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={record.tenDichVu}
                        InputProps={{
                            endAdornment: (
                                <>
                                    <BorderColorOutlined
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        onClick={() => handleUpdateService(record)}
                                    />
                                    <CloseCircleFilled
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        onClick={() => handleDeleteService(record)}
                                    />
                                </>
                            ),
                        }}
                    />
                );
            },
        },
        {
            title: t("number"),
            dataIndex: "soCho",
            key: "soCho",
            width: "8%",
            align: "center",
            visible: true,
            render: (_, record) => {
                if (record.id === "NULL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return (
                    <TextField
                        value={record.soCho}
                        name="soCho"
                        variant="outlined"
                        size="small"
                        fullWidth
                        // onChange={(e) => onChangeEleDichVu(record.id, e)}
                        disabled
                    ></TextField>
                );
            },
        },
        {
            title: t("formOfPricing"),
            dataIndex: "hinhThucGia",
            key: "hinhThucGia",
            width: "12%",
            align: "center",
            visible: true,
            render: (text, record) => {
                if (record.id === "NULL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return {
                    children: text,
                    props: {
                        rowSpan: 1,
                    },
                };
            },
        },
        {
            title: t("vehicleType"),
            dataIndex: "loaiXe",
            key: "loaiXe",
            width: "8%",
            align: "center",
            visible: true,
            render: (text, record) => {
                if (record.id === "NULL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return {
                    children: text,
                    props: {
                        rowSpan: 1,
                    },
                };
            },
        },
        {
            title: t("numberOfKm"),
            dataIndex: "tongSoKm",
            key: "tongSoKm",
            width: "10%",
            align: "center",
            visible: true,
            render: (_, record) => {
                if (record.id === "NULL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return (
                    <TextField
                        value={record.tongSoKm}
                        name="tongSoKm"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="number"
                        onChange={(e) => handleChangeEleDichVu(record.id, e)}
                    ></TextField>
                );
            },
        },
        {
            title: t("tour.donGia"),
            children: [
                {
                    title: t("hotel.priceClass.giaNet"),
                    dataIndex: "giaNet",
                    key: "giaNet",
                    width: "10%",
                    align: "center",
                    render: (text, record) => {
                        if (record.id === "NULL") {
                            return {
                                props: {
                                    colSpan: 0,
                                },
                            };
                        }
                        return {
                            children: formatter.format(text),
                            props: {
                                rowSpan: 1,
                            },
                        };
                        // return handleData(record.fitCuoiTuanNet);
                    },
                },
                {
                    title: t("tour.vanChuyen.soLuong"),
                    dataIndex: "soLuong",
                    key: "soLuong",
                    width: "10%",
                    align: "center",
                    render: (text, record) => {
                        if (record.id === "NULL") {
                            return {
                                props: {
                                    colSpan: 0,
                                },
                            };
                        }
                        return (
                            <TextField
                                value={text}
                                name="soLuong"
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={(e) => handleChangeEleDichVu(record.id, e)}
                                fullWidth
                            ></TextField>
                        );
                    },
                },
            ],
            // width: "25%",
            visible: true,
            align: "center",
        },
        {
            title: t("tour.tongChiPhi"),
            dataIndex: "tong",
            key: "tong",
            width: "12%",
            align: "center",
            visible: true,
            render: (text, record) => {
                if (record.id === "NULL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return {
                    children: formatter.format(text),
                    props: {
                        rowSpan: 1,
                    },
                };
            },
        },
        {
            title: t("adultGroup"),
            dataIndex: "bgNhomNguoiLons",
            key: "bgNhomNguoiLons",
            width: "15%",
            align: "center",
            visible: dataNhomNgLon.length > 0,
            render: (_, record) => {
                if (record.id === "NULL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                } else {
                    return {
                        children: (
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="bgNhomNguoiLons"
                                select
                                SelectProps={{
                                    multiple: true,
                                    value: record.bgNhomNguoiLons,
                                    onChange: (e) => handleChangeNhomNgLon(e, record),
                                }}
                            >
                                {dataNhomNgLon.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.tenNhom}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ),
                        props: {
                            colSpan: 1,
                            // rowSpan: dichVus.length - 1,
                        },
                    };
                }
                // else {
                //   return {
                //     props: {
                //       rowSpan: 0,
                //     },
                //   };
                // }
            },
        },
    ];
    const columnsTE = [
        {
            title: t("service"),
            dataIndex: "tenDichVu",
            align: "center",
            width: "15%",
            visible: true,
            render: (text, row) => {
                return {
                    children: text,
                    props: {
                        rowSpan: row.rowSpanDV,
                    },
                };
            },
        },
        {
            title: t("childrenGroup"),
            dataIndex: "tenNhomTE",
            align: "center",
            width: "15%",
            visible: dataNhomTreEm.length > 0,
            render: (text, row) => {
                return {
                    children: (
                        <TextField variant="outlined" value={text} size="small" fullWidth disabled={true}></TextField>
                    ),
                    props: {
                        rowSpan: row.rowSpanTE,
                    },
                };
            },
        },
        {
            title: t("age") + " " + t("children"),
            dataIndex: "dmDoTuoiId",
            align: "center",
            width: "15%",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        children: (
                            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                <Link onClick={() => handleAddServiceTreEm(row)} underline="none">
                                    {t("addService")}
                                </Link>
                            </Box>
                        ),
                        props: {
                            colSpan: 6,
                        },
                    };
                return (
                    <>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={22}>
                                <TextField
                                    variant="outlined"
                                    value={text}
                                    size="small"
                                    name="dmDoTuoiId"
                                    fullWidth
                                    select
                                    disabled={dataNhomTreEm.length > 0}
                                    onChange={(e) => handleChangeEleDichVuTreEm(row.id, e)}
                                >
                                    {dmDoTuoi.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.do_tuoi}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={2}>
                                {dataNhomNgLon.length === 0 && (
                                    <CloseCircleFilled
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleDeleteServiceTreEm(row)}
                                        fontSize="small"
                                    />
                                )}
                            </Col>
                        </Row>
                    </>
                );
            },
        },
        {
            title: t("tour.percentage"),
            dataIndex: "phanTram",
            align: "center",
            width: "10%",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                // if (text)
                return {
                    children: (
                        <TextField
                            variant="outlined"
                            value={text}
                            size="small"
                            name="phanTram"
                            fullWidth
                            type="number"
                            onChange={(e) => handleChangeEleDichVuTreEm(row.id, e)}
                        />
                    ),
                    props: {},
                };
            },
        },
        {
            title: t("hotel.priceClass.giaNet"),
            dataIndex: "giaNet",
            align: "center",
            width: "12%",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                // if (text)
                return {
                    children: formatter.format(text),
                    props: {},
                };
            },
        },
        {
            title: t("tour.vanChuyen.soLuong"),
            dataIndex: "soLuong",
            align: "center",
            width: "10%",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };

                return {
                    children: (
                        <TextField
                            value={text}
                            variant="outlined"
                            name="soLuong"
                            size="small"
                            type="number"
                            fullWidth
                            onChange={(e) => handleChangeEleDichVuTreEm(row.id, e)}
                        ></TextField>
                    ),
                    props: {},
                };
            },
        },
        {
            title: t("hotel.roomClass.intoMoney"),
            dataIndex: "thanhTien",
            width: "15%",
            align: "center",
            visible: true,
            render: (text, row) => {
                if (row.id.includes("addNew") && dataNhomTreEm.length === 0)
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                // if (text)
                return {
                    children: formatter.format(text),
                    props: {},
                };
            },
        },
    ];

    const handleClear = () => {
        setMaNcc("");
        setDataSource({ ...dataSourceInit });
        setDichVus(JSON.parse(JSON.stringify(dichVusInit)));
        setDichVuTreEms(JSON.parse(JSON.stringify(dichVuTreEmsInit)));
    };

    const handleCloseModal = () => {
        handleCancel();
        handleClear();
    };

    const handleTinhChiPhiMotPax = (idNhomNgLon) => {
        // debugger;
        let dichVuTreEmByGroup = Object.groupBy(dichVuTreEms, ({ bgNhomNguoiLons }) => bgNhomNguoiLons);
        let keyGroupDichVuTE = Object.keys(dichVuTreEmByGroup);

        let chiPhiMotPaxNL = keyGroupDichVuTE?.reduce((sum, key) => {
            if (key.indexOf(idNhomNgLon) !== -1) {
                let dichVuTreEmByDichVu = dichVuTreEmByGroup[key];
                let dichVu = dichVus.find((dv) => dv.bgNhomNguoiLons?.toString() === key);
                let soLuongNgLon = dataNhomNgLon.reduce((sum1, dt) => {
                    if (dichVu?.bgNhomNguoiLons.includes(dt.id)) return sum1 + parseInt(dt.soLuong);
                    else return sum1;
                }, 0);

                let chiPhiMotNguoiLon =
                    parseFloat(dichVu?.tong || 0) /
                    (parseInt(soLuongNgLon) +
                        parseFloat(
                            dichVuTreEmByDichVu && dichVuTreEmByDichVu.length > 0
                                ? dichVuTreEmByDichVu.reduce(
                                      (sum, item) => sum + item.soLuong * (item.phanTram / 100),
                                      0
                                  )
                                : 0
                        ));
                return sum + parseFloat(chiPhiMotNguoiLon);
            } else {
                return sum;
            }
        }, 0);
        // chiPhiMotPax.current = chiPhiMotPaxNL;
        return chiPhiMotPaxNL;
    };

    const handleTinhChiPhiNhomTE = (idTreEm) => {
        let chiPhiTE = dichVuTreEms
            ?.filter((it) => it.bgNhomTreEmId === idTreEm)
            ?.reduce((sum, te) => sum + te.thanhTien, 0);
        chiPhiTE = dataSource.dmGiaApDungId === 1 ? chiPhiTE * soNgay : chiPhiTE;
        return chiPhiTE;
    };

    const handleTinhTongChiPhiTheoTungNhomNgLon = (idNhomNguoiLon, soLuong) => {
        let chiPhiNhomNgLon =
            dataSource.dmGiaApDungId === 1
                ? handleTinhChiPhiMotPax(idNhomNguoiLon) * soLuong * soNgay
                : handleTinhChiPhiMotPax(idNhomNguoiLon) * soLuong;
        let chiPhiTreEm = dataNhomTreEm.reduce((sum, item) => {
            if (item.nhomNguoiLonId === idNhomNguoiLon) {
                return sum + handleTinhChiPhiNhomTE(item.id);
            } else {
                return sum;
            }
        }, 0);
        return chiPhiNhomNgLon + chiPhiTreEm;
    };

    const tongThanhTien = () => {
        return dataNhomNgLon.reduce(
            (sum, item) => sum + handleTinhTongChiPhiTheoTungNhomNgLon(item.id, item.soLuong),
            0
        );
    };

    return (
        <>
            <Modal
                title={t("tour.oto.capNhatDichVu")}
                footer={null}
                open={isOpen}
                onCancel={handleCloseModal}
                className="custom-modal"
            >
                {contextHolder}
                <ModalThongTinNccOto
                    isOpen={isOpenTT}
                    onIsShow={setIsOpenTT}
                    onSaves={handleChangeDataServiceOto}
                    action={action}
                    maNcc={maNcc}
                    tuNgay={tuNgay}
                    denNgay={denNgay}
                />
                <Row style={{ marginTop: "20px" }}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label={t("supplier")}
                        value={dataSource.tenNcc}
                        required
                        InputProps={{
                            style: { height: "50px" },
                            endAdornment: (
                                <>
                                    <BorderColorOutlined
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        onClick={() => handleUpdateNCC()}
                                    />
                                    <CloseCircleFilled
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        onClick={() => handleClear()}
                                    />
                                </>
                            ),
                        }}
                    ></TextField>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col span={24}>
                        <Table
                            dataSource={dichVus}
                            columns={columnDichVus.filter((item) => item.visible)}
                            bordered
                            pagination={false}
                            summary={(pageData) => {
                                // console.log(pageData);
                                let totalDonGia = 0;
                                let totalChiPhi = 0;

                                pageData.forEach(({ soLuong, tong, id }) => {
                                    if (id !== "NULL") {
                                        totalDonGia += parseInt(soLuong);
                                        totalChiPhi += parseFloat(tong);
                                    }
                                });
                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={5}>
                                                {t("tour.tongKet")}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={1} colSpan={2} align="center">
                                                <Tooltip title={t("totalVehicles")}>{totalDonGia}</Tooltip>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={2} colSpan={1} align="center">
                                                <Tooltip title={t("totalCost")}>
                                                    {formatter.format(totalChiPhi)}
                                                </Tooltip>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <TextField
                            id="dmGiaApDungId"
                            select
                            sx={{
                                marginBottom: 2,
                            }}
                            fullWidth
                            label={t("priceApply")}
                            value={dataSource.dmGiaApDungId}
                            name="dmGiaApDungId"
                            onChange={(e) => handleChangeValueDataSoure(e)}
                        >
                            {dmGia?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.gia_ap_dung}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <FormControl disabled={ngay !== 0} style={{ width: "100%" }}>
                            <Row>
                                {bgHanhTrinhs?.map((element) => {
                                    return (
                                        <FormControlLabel
                                            key={element.id}
                                            control={
                                                <Checkbox
                                                    name={element.id}
                                                    onChange={(e) => handleChangNgayApDung(e, element.ngay_order)}
                                                    checked={
                                                        ngay !== 0
                                                            ? (dataSource.dmGiaApDungId === 1 &&
                                                                  parseInt(element.ngay_order) >= ngay) ||
                                                              (dataSource.dmGiaApDungId === 2 &&
                                                                  parseInt(element.ngay_order) === ngay)
                                                            : dataSource.ngayApDungs?.includes(element.ngay_order)
                                                    }
                                                />
                                            }
                                            label={"Ngày " + element.ngay_order}
                                        />
                                    );
                                })}
                            </Row>
                        </FormControl>
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <TextField
                            select
                            sx={{
                                marginBottom: 2,
                            }}
                            fullWidth
                            label={t("typeOfApply")}
                            value={1}
                            disabled
                        >
                            {dmLoaiApDung?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.loai_ap_dung}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <TextField
                            select
                            sx={{
                                marginBottom: 2,
                            }}
                            fullWidth
                            label={t("action.apply") + " " + t("thuevat")}
                            value={dataSource.dmVatId}
                            disabled
                            InputProps={{
                                style: { minHeight: "50px" },
                            }}
                        >
                            {dmVat?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.vat_str}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <TextField
                            sx={{
                                marginBottom: 2,
                            }}
                            fullWidth
                            label={t("typeTour.description")}
                            value={dataSource.moTa}
                            name="moTa"
                            onChange={(e) => handleChangeValueDataSoure(e)}
                            //   disabled
                        ></TextField>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <h3>{t("hotel.table.title")}</h3>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Table
                            dataSource={dichVuTreEms}
                            columns={columnsTE.filter((it) => it.visible)}
                            pagination={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <h3>{t("tour.tongKet")}</h3>
                </Row>
                <Row>
                    <Col xs={16} sm={16} md={19} lg={16} xl={16}></Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ fontSize: "18px" }}>
                        {dataNhomNgLon.length > 0 ? (
                            dataNhomNgLon.map((it, idx) => {
                                return (
                                    <Row key={idx}>
                                        <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {t("tour.chiPhi") + "/ 1 Pax " + it.tenNhom + ":"}
                                        </Col>
                                        <Col span={3}></Col>
                                        <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {dataSource.dmGiaApDungId === 1
                                                ? formatter.format(handleTinhChiPhiMotPax(it.id) * soNgay)
                                                : formatter.format(handleTinhChiPhiMotPax(it.id))}
                                        </Col>
                                    </Row>
                                );
                            })
                        ) : (
                            <>
                                <Row>
                                    <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {t("tour.chiPhi") + " " + t("adult")}
                                    </Col>

                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                                    <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {formatter.format(chiPhiNguoiLon)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {t("tour.tongChiPhi") + " " + t("adult")}
                                    </Col>

                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                                    <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {formatter.format(tongChiPhiNguoiLon)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {t("tour.tongChiPhi") + " " + t("children")}
                                    </Col>

                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                                    <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {formatter.format(chiPhiTreEm)}
                                    </Col>
                                </Row>
                            </>
                        )}

                        {dataNhomNgLon.length > 0 &&
                            dataNhomNgLon.map((it, idx) => {
                                return (
                                    <Row key={idx}>
                                        <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {t("tour.chiPhi") + " " + it.tenNhom + ":"}
                                        </Col>
                                        <Col span={3}></Col>
                                        <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {dataSource.dmGiaApDungId === 1
                                                ? formatter.format(handleTinhChiPhiMotPax(it.id) * it.soLuong * soNgay)
                                                : formatter.format(handleTinhChiPhiMotPax(it.id) * it.soLuong)}
                                        </Col>
                                    </Row>
                                );
                            })}
                        {dataNhomNgLon.length > 0 &&
                            dataNhomTreEm.map((it, idx) => {
                                return (
                                    <Row key={idx}>
                                        <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {t("tour.chiPhi") + " " + it.tenNhom + ":"}
                                        </Col>
                                        <Col span={3}></Col>
                                        <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {formatter.format(handleTinhChiPhiNhomTE(it.id))}
                                        </Col>
                                    </Row>
                                );
                            })}
                        {dataNhomNgLon.length > 0 &&
                            dataNhomNgLon.map((it, idx) => {
                                return (
                                    <Row key={idx}>
                                        <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {t("tour.tongChiPhi") + " " + it.tenNhom + ":"}
                                        </Col>
                                        <Col span={3}></Col>
                                        <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {formatter.format(handleTinhTongChiPhiTheoTungNhomNgLon(it.id, it.soLuong))}
                                        </Col>
                                    </Row>
                                );
                            })}
                        <Row>
                            <Col
                                span={11}
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    fontWeight: "bold",
                                }}
                            >
                                {t("tour.tong") + " " + t("hotel.roomClass.intoMoney")}
                            </Col>

                            <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                            <Col
                                span={5}
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    fontWeight: "bold",
                                }}
                            >
                                {dataNhomNgLon.length === 0
                                    ? formatter.format(tongChiPhi)
                                    : formatter.format(tongThanhTien())}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <Row style={{ marginTop: 6 }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
                    <Col xs={24} sm={24} md={24} lg={18} xl={10}>
                        <Space>
                            <Buttons
                                variant="contained"
                                // type="submit"
                                startIcon={<SaveIcon />}
                                onClick={(e) => handleSave(e)}
                            >
                                {t("action.ok")}
                            </Buttons>
                            <Buttons
                                style={{
                                    backgroundColor: "#898989",
                                    color: "white",
                                }}
                                key="reset"
                                startIcon={<RestartAltIcon />}
                                onClick={(e) => handleClear(e)}
                            >
                                {t("action.reset")}
                            </Buttons>
                            <Buttons
                                variant="outlined"
                                startIcon={<DeleteIcon />}
                                onClick={handleCloseModal}
                                key="clear"
                            >
                                {t("action.cancel")}
                            </Buttons>
                        </Space>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
export default ModalCapNhatOto;
